import projectId from "../project_id";

const TOKEN_KEY = `${projectId}_auth-token`;
const UID_KEY = `${projectId}_uid-token`;

const _window = window as any;

export function getToken(){
    const authUid = getAuthUid();
    if(authUid){
        if(!_window._currentUid){
            _window._currentUid = authUid;
        }
        else if(authUid !== _window._currentUid){
            deleteToken();
            _window._dispatchLogout();
            throw new Error('Usuário desconectado.');
        }
    }
    return localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
}

export function getAuthUid(){
    return localStorage.getItem(UID_KEY) || sessionStorage.getItem(UID_KEY);
}

export function deleteToken(){
    _window._currentUid = undefined;
    [localStorage, sessionStorage].forEach(storage => {
        storage.removeItem(TOKEN_KEY);
        storage.removeItem(UID_KEY);
    });
}

export function setToken(token: string, uid: string, session?: boolean){
    const storage = session ? sessionStorage : localStorage;    
    storage.setItem(TOKEN_KEY, token);
    storage.setItem(UID_KEY, uid);
}

export function updateToken(req: Response){
    const tokenStr = req.headers.get('x-auth-token');
    if(tokenStr == undefined) return;
    const tokenObj = parseTokenString(tokenStr);
    if(tokenObj.token){
        setToken(tokenObj.token!, tokenObj.uid!, Boolean(tokenObj.session));
    }
    else{
        deleteToken();
        _window._dispatchLogout();
    }
}

function parseTokenString(str: string): {token?: string, session?: string, uid?: string}{
    return Object.assign({}, ...str.split("; ").map(item => {
        const [key,value] = item.split('=');
        return {[key]: value};
    }));
}