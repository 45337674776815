import { Stack, TextField } from "@mui/material";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { ClientProps } from "../../client/model";
import { InstallmentProps } from "../../installment/model";
import InfoItem from "../../../shared/components/info_item";
import FixedTextField from "../../../shared/components/fixed_text_field";
import parseBRL from "../../../shared/utils/parse_brl";

export interface MiniClientInfoProps{
    client: CRUDItem<ClientProps>;
    installment: CRUDItem<InstallmentProps>;
}

export default function MiniClientInfo(props: MiniClientInfoProps){
    const items = [
        {label: 'Cliente', value: props.client.data.name},
        {label: 'CPF/CNPJ', value: props.client.data.identifier},
        {label: 'Total Devido', value: parseBRL(props.installment.data.totalDebt)},
        {label: 'Total Pago', value: parseBRL(props.installment.data.totalPaid)},
        {label: 'Média Parcelas', value: parseBRL(props.installment.data.avgParcel)},
        {label: 'Intervalo Pagamento Dias', value: String(props.installment.data.payInterval||0)},
    ];

    return (
        <Stack>
            {items.map((item, i) => (
                <InfoItem
                    key={i}
                    label={item.label}
                    value={item.value!}
                    align="flex-start"
                />
            ))}
        </Stack>
    )
}