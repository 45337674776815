import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Splash from "../../modules/splash";
import useAuth from "../../shared/hooks/use_auth";
import useAuthService from "../../shared/services/auth_service";
import AuthenticatedRoute from "../authenticated_route";
import UnauthenticatedRoute from "../unauthenticated_route";
import { useProject } from "../../shared/stores/project_slice";
import { loadProject } from "../../shared/stores/project_slice/load_project";
import projectId from "../../shared/utils/project_id";

export default function RootRoute(){
    const auth = useAuth();
    const { loadAuth } = useAuthService();
    const [loaded, setLoaded] = useState(false);
    const project = useProject();

    useEffect(() => {
        Promise.all([
            loadProject(),
            loadAuth().catch(console.log),
            new Promise(r => setTimeout(r, process.env.NODE_ENV !== "production" ? 0 : 2000))
        ])
        .then(() => {
            setLoaded(true);
        });
    }, []);

    if(!loaded || !project.project) return (
        <Splash/>
    );

    return (
        <BrowserRouter basename={projectId}>
            {auth.logged ? <AuthenticatedRoute/> : <UnauthenticatedRoute/>}
        </BrowserRouter>
    );
}