import { TextField } from "@mui/material";
import { DatePicker, DatePickerProps, DateTimePicker, DateTimePickerProps, LocalizationProvider, TimePicker, TimePickerProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/pt-BR';
import { forwardRef, useState } from "react";

export interface DateInputProps{
    label?: string;
    defaultValue?: number | Date | null | any;
    value?: number | Date | null | any;
    onChange?: (e: {target: {name?: string, value: any}}) => void;
    name?: string;
    fullWidth?: boolean;
    type?: 'datetime' | 'time' | 'date';
    pickerProps?: Partial<DateTimePickerProps<any, any> | TimePickerProps<any, any> | DatePickerProps<any, any>>;
    onChangeValue?: (val: Date | number | any) => void;
    error?: boolean;
    helperText?: string;
    readOnly?: boolean;
    disabled?: boolean;
}

const masks = {
    date: "__/__/____",
    time: "__:__",
    datetime: "__/__/____ __:__",
};

const DateInput = forwardRef((props: DateInputProps, ref:any) => {
    const type = props.type || 'datetime';
    const [value, setValue] = useState(props.value || props.defaultValue || null);

    const change = (newValue: any) => {
        if(!newValue || newValue.getTime()){
            props.onChange?.({
                target: {
                    name: props.name,
                    value: newValue,
                }
            });
            setValue(newValue);
            props.onChangeValue?.(newValue);
        }
    }

    let _Component;

    switch(type){
        case "date": _Component = DatePicker; break;
        case "time": _Component = TimePicker; break;
        default: _Component = DateTimePicker;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
            <_Component
                {...props.pickerProps as any}
                readOnly={props.readOnly}
                disabled={props.disabled}
                renderInput={(_props: any) => (
                    <TextField 
                        autoComplete="off"
                        {..._props}     
                        error={_props.error || props.error} helperText={_props.helperText || props.helperText} 
                        label={props.label}
                        fullWidth={props.fullWidth} ref={ref}
                    />
                )}
                label={props.label}
                
                onChange={change}
                value={props.value === undefined ? value : props.value}
                mask={masks[type]}
            />
        </LocalizationProvider>
    )
});

export default DateInput;