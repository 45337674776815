import { Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { routeConfig as stockRouteConfig } from ".";
import { routeConfig as warrantyStockRouteConfig } from "../warranty_stock";
import { routeConfig as supplierStockRouteConfig } from "../supplier_stock";
import useAuth from "../../shared/hooks/use_auth";

export default function StockSubmodules(){
    const auth = useAuth();
    const submoduleList = useMemo(() => [
        stockRouteConfig,
        warrantyStockRouteConfig,
        supplierStockRouteConfig,
    ].filter(r => auth.user?.perms[r.key]), [auth.user?.perms]);
    const location = useLocation();
    const navigate = useNavigate();
    

    const index = useMemo(() => {
        const path = location.pathname.split('/')[1];
        return submoduleList.findIndex(s => s.key === path);
    }, [location.pathname]);

    return (
        <Tabs value={index} onChange={(e, i) => navigate(`/${submoduleList[i].key}`)}>
            {submoduleList.map(submodule => (
                <Tab key={submodule.key} label={submodule.label}/>
            ))}
        </Tabs>
    )
}