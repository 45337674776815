import EasyFormContext from "./easy_form_context";
import {  useState } from "react";
import { useForm } from "react-hook-form";
import { EasyFormProps } from "./types";
import deepGet from "../../utils/deep_get";
import styled from "@emotion/styled";

const deepSet = (obj: any, path: string, value: any) => {
    const splited = path.split(".");
    const attr = splited.pop()!;
    if(splited.length){
        const subpath = splited.join(".");
        const parent = deepGet(subpath, obj, {recursive: true});
        parent[attr] = value;
    }
    else{
        obj[attr] = value;
    }
}

const FlexForm = styled.form(({flex1}:any) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: flex1 ? '1' : undefined,
}))

export default function EasyForm(props: EasyFormProps){
    const [defaults, setDefaults] = useState();
    const loadDefaults = () => {
        if(defaults) return defaults;
        const otherDefaults = props.defaultValues;
        const _defaults: any = {};
        for(let key in props.fields){
            let defaultValue = props.fields[key].defaultValue;
            if(otherDefaults && defaultValue == undefined) defaultValue = deepGet(key, otherDefaults);
            deepSet(_defaults, key, defaultValue);
        }
        
        setDefaults(_defaults);
        return _defaults;
    }
    const formConfig = useForm({
        ...props.useFormConfig,
        defaultValues: loadDefaults(),
    });

    const submit = formConfig.handleSubmit(props.onSubmit);

    return (
        <EasyFormContext.Provider value={{ 
            formConfig, 
            fields: props.fields, 
            submit,
            easyFormProps: props,
        }}>
            <FlexForm {...props} onSubmit={submit}>
                {props.children}
            </FlexForm>
        </EasyFormContext.Provider>
    )
}


