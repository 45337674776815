function checkDT(dt: string){
    return typeof dt === "string" && dt.length === 24 && dt[10] === 'T' && dt[23] === 'Z';
}

export default function BodyParser(body?: any){
    if(!body) return undefined;
    const result: string = JSON.stringify(body, (key, val) => {
        if(key[0] === "_") return undefined;
        if(val instanceof Date) return val.getTime();
        if(checkDT(val)) return new Date(val).getTime();
        if(val === body) return val;
        return val;
    });

    return result;
}