import { Component, PropsWithChildren } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ScreenError from "../../shared/components/screen_error";

export default function ErrorBoundary(props: any){
    const navigate = useNavigate();
    return (
        <ErrorBoundaryComponent {...props} navigate={navigate}/>
    )
}

class ErrorBoundaryComponent extends Component<PropsWithChildren & { navigate: NavigateFunction }> {
    state: {
        error: Error | null
    } = { 
        error: null
    }
    
    componentDidCatch(error: Error) {
      this.setState({error: error});
    }

    dismiss = () => {
        this.props.navigate('/');
        setTimeout(() => {
            this.setState({error: null});
        }, 500);
    }
    
    render() {
      if (this.state.error) {
        return (
          <ScreenError onDismiss={this.dismiss} error={this.state.error}/>
        );
      }
      return this.props.children;
    }  
}