import { Button, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react"
import NumberInput from "../../shared/components/number_input";
import { CreditTaxItem, GeneralConfigsProps } from "./model";
import dialogHelper from "../../shared/components/easy_dialog";
import { Add, Close, Delete } from "@mui/icons-material";

function CreditTaxesItem({item}: {item: CreditTaxItem}){
    const [tax, setTax] = useState(item.tax);

    return (
        <Stack direction="row" spacing={0.5}>
            <NumberInput variant="filled" readOnly label="Parcela" fullWidth value={item.times} modeInt/>
            <NumberInput label="Taxa" fullWidth value={tax} onChange={e => {
                setTax(e.target.value!);
                item.tax = e.target.value!;
            }}/>
        </Stack>
    )
}


export default function CreditTaxes({generalConfigs}: {generalConfigs: GeneralConfigsProps}){
    const [taxes, setTaxes] = useState<CreditTaxItem[]>(Object.values(generalConfigs.taxes?.credit||[]));
    const filtered = taxes.filter(t => t);
    const add = () => {
        generalConfigs!.taxes!.credit = [...filtered, {tax: null!, times: taxes.length+1}];
        setTaxes(generalConfigs!.taxes!.credit);
    }
    const remove = () => {
        taxes[filtered.length - 1] = null!;
        generalConfigs!.taxes!.credit = taxes;
        setTaxes([...taxes]);
    }

    return (
        <Paper variant="outlined">
            <Stack spacing={1} padding={1}>
                <Typography>
                    CRÉDITO
                </Typography>
                {filtered.map((t, i) => (
                    <CreditTaxesItem item={t} key={i}/>
                ))}
                <Stack direction="row" spacing={1}>
                    <Button onClick={add} fullWidth>
                        <Add/>
                    </Button>
                    <Button color="error" onClick={remove} fullWidth>
                        <Close/>
                    </Button>
                </Stack>
            </Stack>
        </Paper>
    )
}