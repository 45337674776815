import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { MultiStockTransferResult } from "../stock/multi_stock_transfer";
import { SellProps } from "./model";

export default class SellService extends CRUDService<SellProps>{
    constructor(){
        super('sell');
    }

    cancel(uid: string){
        return fetcher(`${this.prefix}/cancel/${uid}`, 'PATCH')
        .then(this.parseMiddlewareItem);
    }

    generateWarranty(sellUid: string, config: MultiStockTransferResult){
        return fetcher(this.prefix + '/generateWarranty', 'PATCH', {
            sellUid,
            items: config.stocks.map(i => ({
                product: i.stock.product,
                amount: i.amount,
                description: i.description,
            }))
        })
        .then(this.parseMiddlewareItem);
    }
}