export default function DeepAssign(a: any, b: any = {}){
    for(let [key, value] of Object.entries(b)){
        try{
            switch(typeof value){
                case "object":
                    if(!a[key] || !value){
                        a[key] = value;
                    }
                    else{
                        DeepAssign(a[key], value);
                    }
                break;
                default:
                    a[key] = value;
            }
        }
        catch(err){
            console.log(key, value, a[key]);
            throw err;
        }
    }
    
    return a;
}