import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import dialogHelper from "../../../shared/components/easy_dialog";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";
import PhoneSOService from "../service";

export function CancelSO(){

    const location = useLocation();
    const [phoneSo] = useState<CRUDItem<PhoneSOProps>>(() => location.state?.phoneSo);
    const navigate = useNavigate();
    
    const [fields] = useState(() => ModelFieldsFilter(PhoneSOFields({
        defaults: phoneSo.data,
    }), ['cancelReason']));

    useEffect(() => {
        if(!phoneSo){
            navigate(-1);
        }
    }, []);

    const submit = (data: any) => {
        Object.assign(phoneSo.data, data);
        const service = new PhoneSOService();
        dialogHelper.showLoading();
        service.update(phoneSo)
        .then(() => service.cancel(phoneSo.uid!))
        .then(() => {
            dialogHelper.showDialog({
                title: 'OS cancelada com sucesso!',
                buttons: [dialogHelper.okButton()]
            });
            navigate('/phone_so/edit/'+phoneSo.uid, {replace: true});
        })
        .catch(dialogHelper.showError);
    }

    return (
        <ScreenPaper>
            <Stack spacing={1}>
                <Typography>
                    CANCELAR OS
                </Typography>
                <Typography>
                    Código: {phoneSo?.uid}
                </Typography>
                <Typography>
                    Informe o motivo do cancelamento.
                </Typography>
                <EasyForm fields={fields} onSubmit={submit}>
                    <Stack spacing={1}>
                        <EasyFormInput field="cancelReason"/>
                        <EasyFormAction>
                            <Button color="error">
                                CANCELAR OS
                            </Button>
                        </EasyFormAction>
                    </Stack>
                </EasyForm>
            </Stack>
        </ScreenPaper>
        
    )
}