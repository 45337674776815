import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { StockProps } from "./model";

export default class StockService extends CRUDService<StockProps>{
    constructor(){
        super('stock');
    }

    protected async _parseMiddlewareItem(resp: Response): Promise<CRUDItem<StockProps>> {
        const parsed = await super._parseMiddlewareItem(resp);
        parsed.data._originalAmount = parsed.data.amount;
        return parsed;
    }

    update(item: CRUDItem<Partial<StockProps>>): Promise<CRUDItem<StockProps>> {
        item.data.lastOpAmountDiff = item.data.amount! - item.data._originalAmount!;
        if(!item.data.lastOpAmountDiff && item.data.lastOpAmountDiff !== 0){
            return Promise.reject(new Error('Quantidade inválida.'));
        }
        return super.update(item);
    }

    transferStock(productUid: string, sourceStoreUid: string, targetStoreUid: string, amount: number){
        return fetcher(this.prefix +  '/transferStock', 'POST', {
            productUid, sourceStoreUid, targetStoreUid, amount,
        });
    }

    cleanBase(): Promise<{amountDeleted: number}>{
        return fetcher(this.prefix + '/cleanBase', 'PATCH')
        .then(resp => resp.json())
    }
}