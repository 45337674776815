import { forwardRef, useState } from "react";
import DBRefInput, { DBRefExtendedInputProps } from "../../shared/components/dbref_input";
import { CRUDItem } from "../../shared/crud/crud_service";
import { PhoneSOProps } from "./model";
import PhoneSOService from "./service";

interface PhoneSoFilteredUserDBRefProps extends DBRefExtendedInputProps{
    mode: 'technicians' | 'sellers';
    phoneSo: CRUDItem<PhoneSOProps>;
}

const PhoneSoFilteredUserDBRef = forwardRef(function _PhoneSoFilteredUserDBRef(props: PhoneSoFilteredUserDBRefProps, ref){
    const [service] = useState(() => new PhoneSOService());

    return (
        <DBRefInput
            {...props}
            options={() => props.mode === 'technicians' ? service.listTechnicians(props.phoneSo) : service.listSellers(props.phoneSo)}
            ref={ref}
        />
    )
});

export default PhoneSoFilteredUserDBRef;