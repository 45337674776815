import { Button, Stack, Typography } from '@mui/material';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import StockMicroEdit from './micro_edit';
import StockService from './service';
import {CleaningServices, Receipt, Report, SwapHoriz } from '@mui/icons-material';
import useAuth from '../../shared/hooks/use_auth';
import StockSubmodules from './stock_submodules';
import MinPerm from '../../shared/utils/min_perm';
import dialogHelper from '../../shared/components/easy_dialog';
import StockReportPrint from '../print/stock_report_print';
import { DBRef } from '../../shared/models/model';
import { StoreProps } from '../store/model';
import StoreDBRef from '../store/dbref';

export default function StockList(props: any){
		const auth = useAuth();
		const list = useCRUDList({
			CRUDService: StockService,
			props,
			columns: [
                {field: 'product.label', headerName: 'Produto', type: 'multiline', minWidth: 250},
				{field: 'store.label', headerName: 'Loja', type: 'multiline'},
				{field: 'amount', headerName: 'Qntd', type: "number", minWidth: 40},
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime'},
			]
		});

		const printReport = () => {
			let storeref: DBRef<StoreProps, 'name'>;
			dialogHelper.showDialog({
				title: 'Selecione uma loja para imprimir o relatório',
				content: (
					<StoreDBRef label='Loja' onChange={e => storeref = e.target.value}/>
				),
				buttons: [
					dialogHelper.okButton({content: 'CANCELAR'}), 
					dialogHelper.okButton({content: 'IMPRIMIR', callback: () => storeref ? StockReportPrint(storeref) : dialogHelper.closeDialog()})
				]
			})
		}

		const cleanStock = () => {
			dialogHelper.showConfirmation({
				callback: () => {
					dialogHelper.showLoading();
					new StockService().cleanBase()
					.then(({amountDeleted}) => list.updateList().then(() => dialogHelper.showDialog({
						title: 'Sucesso!',
						content: amountDeleted ? `Foram excluídos ${amountDeleted} itens com sucesso.` : 'Nenhum item precisou ser excluído.',
						buttons: [dialogHelper.okButton()],
					})))
					.catch(dialogHelper.showError);
				},
				content: (
					<>
						<Typography>
							Esta ação removerá todos os <b>ESTOQUES</b> e <b>GARANTIAS</b> nas seguintes condições:
						</Typography>
						<ul>
							<Typography component="li">Estoques cuja loja foi excluída</Typography>
							<Typography component="li">Estoques cujo produto foi excluído</Typography>
							<Typography component="li">Estoques vazios</Typography>
						</ul>
						<Typography color="error">
							Lembre-se de anotar todas as quantidades antes de realizar esta ação, pois a mesma não pode ser desfeita!
						</Typography>
					</>
				)
			})
		}

		return (
			<ScreenPaper flex1>
                {props.edit ? <StockMicroEdit onSave={list.addItemToList} updateList={list.updateList} readOnly={props.readOnly}/> : undefined}
				<StockSubmodules/>
				<Stack spacing={1} direction="row">
					{auth.user?.perms.stock_transfer &&
					<Button href="/stock/transfer" startIcon={<SwapHoriz/>} fullWidth>
						TRANSFERIR ESTOQUE
					</Button>}
					{MinPerm(auth.user?.perms.stock, 'w') &&
					<Button onClick={printReport} startIcon={<Receipt/>} color="warning" fullWidth>
						RELATÓRIO DE ESTOQUE
					</Button>}
					{MinPerm(auth.user?.perms.mass_mod, 'w') &&
					<Button onClick={cleanStock} startIcon={<CleaningServices/>} color="info" fullWidth>
						LIMPAR ESTOQUE
					</Button>}
				</Stack>
				<List
					{...list.listComponentProps}
					updateList={list.updateList}
					defaultSort={[{ field: 'product.label', sort: 'desc' }]}						
				/>
			</ScreenPaper>
		)
}