import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { AddressFields, AddressProps } from "../address/model";
import GroupDBRef from "../group/dbref";
import { GroupProps } from "../group/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import ZoneDBRef from "../zone/dbref";
import { ZoneProps } from "../zone/model";


export interface UserProps extends DatedProps{
    name: string;
    group: DBRef<GroupProps, 'name'>;
    configs: {
        zone?: DBRef<ZoneProps, 'name'>;
        store?: DBRef<StoreProps, 'name'>;
    }
    login: string;
    cpf: string;
    rg: string;
    disabled?: boolean;
    address: AddressProps;
    phone: string;
    email: string;
}

export const UserFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        login: {
            field: 'login',
            type: 'text',
            label: 'Login',
            required: true,
        },
        cpf: {
            field: 'cpf',
            type: 'maskedtext',
            mask: '000.000.000-00',
            label: 'CPF',
        },
        phone: {
            field: 'phone',
            type: 'maskedtext',
            mask: '(00) 9 0000-0000',
            label: 'Celular',
        },
        email: {
            field: 'email',
            type: 'text',
            label: 'E-mail',
        },
        group: {
            field: 'group',
            type: 'dbref',
            required: true,
            label: 'Grupo',
            component: GroupDBRef,
        },
        "configs.zone": {
            field: 'configs.zone',
            type: 'dbref',
            required: true,
            label: 'Zona',
            component: ZoneDBRef,
        },
        "configs.store": {
            field: 'configs.store',
            type: 'dbref',
            required: true,
            label: 'Loja',
            component: StoreDBRef,
        },
        ...AddressFields({prefix: 'address', defaults: options?.defaults?.address})
    }, options);
}