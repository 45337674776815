import { format } from "date-fns";

export interface DateToStringOptions{
    showInvalid?: boolean;
}

export default function DateToString(
    date: Date | number, 
    mode: 'datetime' | 'date' | 'time' = 'datetime', 
    options?: DateToStringOptions
){
    date = new Date(date);
    if(!date.getTime()){
        return options?.showInvalid ? "Data Inválida" : '';
    }
    switch(mode){
        case 'date': return format(date, 'dd/MM/yyyy');
        case 'time': return format(date, 'HH:mm');
        default: return format(date, 'dd/MM/yyyy HH:mm');
    }
}