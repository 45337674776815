import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import parseBRL from "../../../shared/utils/parse_brl";
import { hashDate } from "../../billing2/controller";
import { DailySummaryHistoryService, DailySummaryService } from "../../billing2/services";
import { ProductProps } from "../../product/model";
import { Report } from "../model";

export interface FinancialReportValue{
    id: string;
    store: string;
    invoice: number;
    cost: number;
    profit: number;
}

const FinancialReport: Report = {
    name: 'Financeiro',
    checkPerm: async u => MinPerm(u.perms.report, 'w'),
    options: {
        startDate: {
            field: 'startDate',
            type: 'date',
            required: true,
            label: 'Data Início',
        },
        endDate: {
            field: 'endDate',
            type: 'date',
            required: true,
            label: 'Data Fim',
        }
    },
    validateOptions: async o => {
        if(o.startDate > o.endDate) throw new Error('A data de início não pode ser maior que a data fim.');
        o.startDate.setHours(0,0,0);
        o.endDate.setHours(23,59,0);
        return o;
    },
    columns: [
        {field: 'store', headerName: 'Loja'},
        {field: 'invoice', headerName: 'Faturamento', type: 'money'},
        {field: 'cost', headerName: 'Custo', type: 'money'},
        {field: 'profit', headerName: 'Lucro', type: 'money'},
    ],
    defaultSort: [{field: 'profit', sort: 'desc'}],
    process: async o => {
        const hashedStart = hashDate(o.startDate), hashedEnd = hashDate(o.endDate);
        const list: FinancialReportValue[] = [];
        const dsh = Object.values(await new DailySummaryHistoryService().list());
        if(hashedEnd === hashDate(new Date())){
            const ds = await new DailySummaryService().list();
            for(let d of Object.values(ds)){
                const index = dsh.findIndex(i => i.data.currentDate === d.data.currentDate);
                if(index >= 0){
                    dsh[index] = d as any;
                }
                else{
                    dsh.push(d as any);
                }
            }
        }
        for(let d of dsh){
            const dHash = hashDate(d.data.currentDate);
            if(dHash < hashedStart || dHash > hashedEnd) continue;
            const values = d.data.closedValues || d.data.currentValues || {};
            let item = list.find(i => i.id === d.data.store.uid);
            if(!item){
                item = {
                    id: d.data.store.uid,
                    store: d.data.store.label,
                    cost: 0,
                    invoice: 0,
                    profit: 0,
                }
                list.push(item);
            }
            item.cost += values.cost || 0;
            item.invoice += values.invoice || 0;
            item.profit += values.profit || 0;
        }
        return list;
    },
    summaries: async (options: any, values: FinancialReportValue[]) => {
        let cost = 0, invoice = 0, profit = 0;
        values.forEach(p => {
            cost += p.cost;
            invoice += p.invoice;
            profit += p.profit;
        })
        return [
            [['Início', DateToString(options.startDate, 'date')], ['Fim', DateToString(options.endDate, 'date')]],
            [['Faturamento', parseBRL(invoice)], ['Custo', parseBRL(cost)], ['Lucro', parseBRL(profit)]],
        ]
    },
    print: async (options: any, values: FinancialReportValue[]) => {
        return {
            name: 'Relatório Financeiro',
            options: {},
            summaries: await FinancialReport.summaries(options, values),
            columns: [
                ...FinancialReport.columns as any,
            ],
            values,
        }
    }
}

export default FinancialReport;