import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { AddressFields, AddressProps } from "../address/model";
import { ZoneProps } from "../zone/model";
import ZoneDBRef from "../zone/dbref";


export interface StoreProps extends DatedProps{
    name: string;
    description: string;
    zone: DBRef<ZoneProps, 'name'>;
    mainStore?: boolean;
    address?: AddressProps;
    phone?: string;
    social?: string;
}

export const StoreFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        description: {
            field: "description",
            type: "text",
            label: 'Descrição'
        },
        social: {
            field: "social",
            type: "text",
            label: 'Rede Social'
        },
        phone: {
            field: "phone",
            type: "maskedtext",
            label: 'Telefone',
            mask: '(00) 00000-0000'
        },
        zone: {
            field: "zone",
            type: "dbref",
            label: "Zona",
            component: ZoneDBRef,
        },
        mainStore: {
            field: 'mainStore',
            type: 'checkbox',
            label: 'Loja Principal',
        },
        ...AddressFields({defaults: options?.defaults?.address, prefix: 'address'})
    }, options);
}