import { CheckCircleOutline } from "@mui/icons-material";
import { Stack, Typography, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Centered from "../../../shared/components/centered";
import { useWizard } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import SoCheckoutPrint from "../../print/so_checkout_print";
import sell from "../../sell";
import { PhoneSOProps } from "../model";

export default function FinishEnd(props: any){
    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
    });

    const navigate = useNavigate();

    return (
        <Centered>
            <Stack alignItems="center" spacing={1}>
                <CheckCircleOutline color="success" sx={{fontSize: '100px'}}/>
                <Typography color="success" variant="h4" align='center'>
                    Ordem de serviço finalizada com sucesso!
                </Typography>
                <Button onClick={() => SoCheckoutPrint(wizard.data.uid!, wizard.data.data)}>
                    IMPRIMIR RECIBO
                </Button>
                <Button href={`/phone_so/edit/${wizard.data.uid}`} color='secondary'>
                    Detalhes da OS
                </Button>
                <Link href="/" color='secondary'>
                    Retornar ao menu principal
                </Link>
            </Stack>
        </Centered>
    )

}