import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import InstallmentService from "../installment/service";
import { ClientProps } from "./model";

export default class ClientService extends CRUDService<ClientProps>{
    installmentService = new InstallmentService();
    constructor(){
        super('client');
    }
    async update(item: CRUDItem<Partial<ClientProps>>): Promise<CRUDItem<ClientProps>> {
        const newItem = await super.update(item);
        if(item.data._installment){
            const newInstallment = await this.installmentService.update({uid: item.uid, data: item.data._installment});
            newItem.data._installment = newInstallment.data;
        }
        return newItem;
    }

    async delete(uid: string): Promise<void> {
        await super.delete(uid);
        await this.installmentService.delete(uid).catch(console.error);    
    }

    async create(item: CRUDItem<ClientProps>): Promise<CRUDItem<ClientProps>> {
        const newItem = await super.create(item);
        if(item.data._installment){
            const newInstallment = await this.installmentService.update({uid: newItem.uid, data: item.data._installment});
            newItem.data._installment = newInstallment.data;
        }
        return newItem;
    }
}