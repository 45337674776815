import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DBRef, DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { PaymentValues } from "../payment/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import { UserProps } from "../user/model";

export enum CashRemittanceOpReason{
    'SANGRIA' = 1,
    'OPERACIONAL' = 2,
    'REMESSA' = 3,
    'TROCO' = 4,
    'DEVOLUÇÃO' = 5,
    'ENTRADA VENDA' = 6,
    'ENTRADA OS' = 7,
    'CORREÇÃO' = 8,
    'ENTRADA' = 9,
}

export interface CashRemittanceProps extends DatedProps{
    store: DBRef<StoreProps, 'name'>;
    values: PaymentValues;
    lastOpReason: CashRemittanceOpReason;
    lastOpObs?: string;
    modifiedBy: DBRef<UserProps, 'name'>;
    approvedBy?: DBRef<UserProps, 'name'>;
    reference?: DBRef<any, any>;
    approved?: boolean | null;
    withoutSum?: boolean;
    manual?: boolean;
    _createMode: 'create' | 'request';
    _operationMode: 'sub' | 'add';
}

export interface CashRemittanceFieldsOptions extends ModelFieldsGenOptions{
    _createMode: 'create' | 'request';
}

export const CashRemittanceFields = (options?: CashRemittanceFieldsOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        _operationMode: {
            label: "Operação",
            type: 'select',
            field: "_operationMode",
            options: [
                {label: 'ADICIONAR', value: 'add'},
                {label: 'SUBTRAIR', value: 'sub'},
            ]
        },
        lastOpReason: {
            label: "Motivo",
            type: 'select',
            field: "lastOpReason",
            options: Object.entries(CashRemittanceOpReason).filter(([label]) => isNaN(parseInt(label))).map(([label, value]) => ({label, value})),
            defaultValue: null,
        },
        lastOpObs: {
            field: "lastOpObs",
            type: "text",
            label: "Observação",
        },
        'values.money': {
            field: "values.money",
            type: "number",
            label: "Dinheiro",
        },
        'values.pix': {
            field: "values.pix",
            type: "number",
            label: "PIX",
        },
        'values.credit': {
            field: "values.credit",
            type: "number",
            label: "Crédito",
        },
        'values.debt': {
            field: "values.debt",
            type: "number",
            label: "Débito",
        },
        _originStore: options?._createMode === 'create' ? undefined : {
            field: '_originStore',
            type: 'dbref',
            label: 'Loja Origem',
            required: true,
            component: StoreDBRef,
        }
    }, options);
}