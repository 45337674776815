import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DBRef, DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { AddressFields, AddressProps } from "../address/model";
import { GroupProps } from "../group/model";
import { InstallmentFields, InstallmentProps } from "../installment/model";
import ClientDBRef from "./dbref";

export interface ClientProps extends DatedProps{
    name: string;
    group?: DBRef<GroupProps, 'name'>;
    identifier?: string;
    login?: string;
    address?: AddressProps;
    email?: string;
    phone?: string;
    reference?: DBRef<ClientProps, 'name'>;
    disabled?: boolean;
    _installment?: InstallmentProps;
}

export const ClientFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        identifier: {
            field: 'identifier',
            type: 'maskedtext',
            mask: '00000000000000',
            minLength: 11,
            maxLength: 14,
            label: 'CPF/CNPJ',
        },
        phone: {
            field: 'phone',
            type: 'maskedtext',
            mask: '(00) 9 0000-0000',
            label: 'Celular',
        },
        email: {
            field: 'email',
            type: 'text',
            label: 'E-mail',
        },
        reference: {
            field: 'reference',
            type: 'text',
            label: 'Referência'
        },
        ...AddressFields({defaults: options?.defaults?.address, prefix: 'address', optional: true}),
        ...InstallmentFields({defaults: options?.defaults?._installment, prefix: '_installment'}),
    }, options);
}