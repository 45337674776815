import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Divider, Stack } from '@mui/material';
import { PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import EasyFormContext from '../../../shared/components/easy_form/easy_form_context';
import useNextAction, { UseNextActionProps } from '../use_next_action';

export function SellBottomButtonPrevirous(props: {beforeRun?: () => Promise<any> | void}){
    const navigate = useNavigate();
    return (
        <BottomNavigationAction label="Anterior" icon={<ArrowBack/>} showLabel onClick={async () => {
            if(props.beforeRun) await props.beforeRun();
            navigate(-1);
        }}/>
    )
}
export function SellBottomButtonNext(props: UseNextActionProps){
    const goNext = useNextAction(props);
    return (
        <BottomNavigationAction 
            label="Próximo" 
            icon={<ArrowForward/>} 
            showLabel 
            onClick={goNext}
        />
    )
}

export function SellBottomButtonNextForm(props: {name: string}){
    const context = useContext(EasyFormContext);

    const submit = context.formConfig.handleSubmit(context.easyFormProps.onSubmit, () => {throw new Error()});

    return (
        <SellBottomButtonNext name={props.name} beforeRun={submit} />
    )
}

export default function SellBottomNavigation(props: PropsWithChildren){
    return (
        <Stack>
            <Divider/>
            <BottomNavigation>
                {props.children}
            </BottomNavigation>
        </Stack>
    )
}