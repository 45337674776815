
const r = ['r', 'w', 's'];
const w = ['w', 's'];
const s = ['s'];

export default function MinPerm(perm: string = '', min: 'r' | 'w' | 's'){
    const p = perm[0];
    let list: string[];
    switch(min){
        case 'r': list = r; break;
        case 'w': list = w; break;
        case 's': list = s; break;
        default:
            return false;
    }

    return list.includes(p);
}