import { forwardRef, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import StoreService from "./service";


const StoreDBRef = forwardRef(function _StoreDBRef(props: Omit<DBRefInputProps, 'options'>, ref){
    const [service] = useState(() => new StoreService());

    return (
        <DBRefInput
            {...props}
            options={() => service.DBRefList(props.itemLabel || 'name')}
            ref={ref}
        />
    )
});

export default StoreDBRef;