import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Delete } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import ControlInfo from "../../shared/components/control_info";
import { SupplierFields } from "./model";
import SupplierService from "./service";

export default function SupplierEdit(props: any){
    const create = useCRUDEdit({
        props,
        fieldsGen: SupplierFields,
        CRUDService: SupplierService,
    });

    if(create.initializing){
        return <ScreenLoading/>
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps}>
                <Stack spacing={1}>
                    <Typography>
                        {!create.uid && 'CRIAR'} FORNECEDOR
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <EasyFormInput field="name"/>
                    <EasyFormInput field="description"/>
                    <EasyFormWriteOnly>
                        <Divider><Chip label="AÇÕES"/></Divider>
                        <EasyFormAction>
                            <Button fullWidth color="success">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                        {create.uid ? 
                        <Button startIcon={<Delete/>} color="error" onClick={create.deleteAction}>
                            DELETAR
                        </Button>
                        : undefined}
                    </EasyFormWriteOnly>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}