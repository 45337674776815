import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Delete } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import ControlInfo from "../../shared/components/control_info";
import ClientService from "./service";
import { ClientFields, ClientProps } from "./model";
import AddressFragment from "../address/address_fragment";
import { Fragment, useMemo } from "react";
import InstallmentService from "../installment/service";
import useDomain from "../../shared/hooks/use_domain";
import If from "../../shared/components/if";

export default function ClientEdit(props: any){
    const installmentService = useMemo(() => new InstallmentService(), []);
    const haveInstallment = useDomain('installment');
    const create = useCRUDEdit<ClientProps, ClientService>({
        props,
        fieldsGen: ClientFields,
        CRUDService: ClientService,
        async onLoadData(d){
            await installmentService.read(d.uid!)
            .then(i => d.data._installment = i.data)
            .catch(e => {
                d.data._installment = {} as any;
                console.error(e);
            });
        },
        goBackOnSave: true,
    });

    if(create.initializing){
        return <ScreenLoading/>
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps}>
                <Stack spacing={1}>
                    <Typography>
                        {!create.uid && 'CRIAR'} CLIENTE
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <EasyFormInput field="name"/>
                    <EasyFormInput field="identifier"/>
                    <EasyFormInput field="phone"/>
                    <EasyFormInput field="email"/>
                    <EasyFormInput field="reference"/>
                    <Divider><Chip label="ENDEREÇO"/></Divider>
                    <AddressFragment defaultReadMode={Boolean(create.uid)}/>
                    <If cond={haveInstallment}>
                        <Divider><Chip label="PARCELAMENTO"/></Divider>
                        <EasyFormInput field="_installment.totalPaid"/>
                        <EasyFormInput field="_installment.totalDebt"/>
                        <EasyFormInput field="_installment.valParcel"/>
                        <EasyFormInput field="_installment.payInterval"/>
                        <EasyFormInput field="_installment.negotiatedAt"/>
                        <EasyFormInput field="_installment.startAt"/>
                        <EasyFormInput field="_installment.avgParcel"/>
                    </If>
                    <EasyFormWriteOnly>
                        <Divider><Chip label="AÇÕES"/></Divider>
                        <EasyFormAction>
                            <Button fullWidth color="success">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                        {create.uid ? 
                        <Button startIcon={<Delete/>} color="error" onClick={create.deleteAction}>
                            DELETAR
                        </Button>
                        : undefined}
                    </EasyFormWriteOnly>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}