import { Close } from "@mui/icons-material";
import { Button, Chip, Divider, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenLoading from "../../../shared/components/screen_loading";
import ScreenPaper from "../../../shared/components/screen_paper";
import parseBRL from "../../../shared/utils/parse_brl";
import SellBottomNavigation, { SellBottomButtonPrevirous, SellBottomButtonNext, SellBottomButtonNextForm } from "../sell_bottom_navigation";
import ItemDetailsController from "./controller";
import { Fragment } from "react";

export default function ItemDetails(props: {name: string}){
    const controller = ItemDetailsController();
    if(controller.loading) return (
        <ScreenLoading/>
    )
    return (
        <Stack flex={1}>
            <EasyForm fields={controller.allFields} onSubmit={controller.submit} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Stack flex={1} marginTop={1}>
                    <Stack spacing={1} padding={1}>
                        <Typography align='center'>
                            DETALHES DOS PRODUTOS
                        </Typography>
                        {Object.entries(controller.sell.items).map(([key, item]) => {
                            const stocks = controller.productStocks[key];
                            const overflow = item.amount > stocks.userStore.amount;
                            return !controller.removed.includes(key) && (
                                <Paper>
                                    <Stack spacing={1} padding={1}>
                                        <Button size="small" color="error" startIcon={<Close/>} style={{alignSelf: 'center'}} onClick={() => controller.deleteItem(key)}>
                                            REMOVER PRODUTO
                                        </Button>
                                        <Typography align="center" color="primary">
                                            <b>{item.product.label}</b>
                                        </Typography>
                                        <Typography color="error">
                                            Valor Mínimo: <b>{parseBRL(item.minSellValue)}</b>
                                        </Typography>
                                        <Typography sx={{color: 'success.main'}}>
                                            Valor Sugerido: <b>{parseBRL(item.idealSellValue)}</b>
                                        </Typography>
                                        <EasyFormInput field={`items.${key}.sellValue`}/>
                                        <EasyFormInput 
                                            field={`items.${key}.amount`}
                                            helperText={
                                                <Typography variant="caption" color={overflow ? 'error' : 'inherit'}>
                                                    {stocks.userStore.amount} itens disponíveis na sua loja.
                                                </Typography>
                                            }
                                        />
                                        {overflow && stocks.otherStores.length ?
                                        <Fragment>
                                            <Typography>
                                                Este item está disponível nas seguintes lojas:
                                            </Typography>
                                            <ul>
                                                {stocks.otherStores.map(store => (
                                                    <Typography component="li" key={store.uid}>
                                                        {store.label}
                                                    </Typography>
                                                ))}
                                            </ul>
                                        </Fragment> : overflow && (
                                            <Typography color="error">
                                                Este item está indisponível no momento.
                                            </Typography>
                                        )}
                                    </Stack>
                                </Paper>
                            )
                        })}
                    </Stack>
                </Stack>
                <SellBottomNavigation>
                    <SellBottomButtonPrevirous/>
                    <SellBottomButtonNextForm name={props.name}/>
                </SellBottomNavigation>
            </EasyForm>
        </Stack>
    )
}