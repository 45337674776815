import { Button, Stack } from '@mui/material';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import StockSubmodules from '../stock/stock_submodules';
import StockMicroEdit from './micro_edit';
import StockService from './service';
import { ArrowBackIos, Inventory, SwapHoriz } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import dialogHelper from '../../shared/components/easy_dialog';
import MultiStockTransfer from '../stock/multi_stock_transfer';
import SupplierStockService from './service';
import { MultiStockTransferResult } from '../stock/multi_stock_transfer';
import useAuth from '../../shared/hooks/use_auth';
import MinPerm from '../../shared/utils/min_perm';

export default function SupplierStockList(props: any){
	const auth = useAuth();
	const list = useCRUDList({
		CRUDService: SupplierStockService,
		props,
		columns: [
			{field: 'product.label', headerName: 'Produto'},
			{field: 'store.label', headerName: 'Loja'},
			{field: 'amount', headerName: 'Quantidade', type: "number"},
			{field: 'date', headerName: 'Data', type: 'date'},
			{field: 'label', headerName: 'Descrição', type: 'string'},
		]
	});
	const filtered = useMemo(() => list.list.filter(i => i.amount), [list.list]);
	const service = useMemo(() => new SupplierStockService(), []);

	const [selected, setSelected] = useState<string[]>([]);

	const generateStock = () => {
		if(!selected.length) {
			dialogHelper.showError({message: 'Selecione ao menos um item!'});
			return;
		}
		const confirm = (r: MultiStockTransferResult) => {
			dialogHelper.showLoading();
			service.stockFromSupplier(r)
			.then(() => list.updateList())
			.then(dialogHelper.closeDialog)
			.catch(dialogHelper.showError)
		}
		dialogHelper.showDialog({
			content: (
				<MultiStockTransfer 
					onCancel={dialogHelper.closeDialog}
					stocks={selected.map(id => list.list.find(i => i.id === id)) as any}
					onConfirm={confirm}
					withTargetStore
				/>
			)
		})
	}
	
	return (
		<ScreenPaper flex1>
			{props.edit ? <StockMicroEdit onSave={list.addItemToList} readOnly={props.readOnly}/> : undefined}
			<StockSubmodules/>
			{MinPerm(auth.user?.perms.supplier_stock, 'w') &&
			<Stack flexWrap="wrap" direction="row" gap="5px">
				<Button color="success" onClick={generateStock} startIcon={<Inventory/>}>
					GERAR ESTOQUE
				</Button>
			</Stack>}
			<List
				{...list.listComponentProps}
				rows={filtered}
				updateList={list.updateList}
				defaultSort={[{ field: 'product.label', sort: 'desc' }]}	
				checkboxSelection
				onSelectionModelChange={(m,d) => setSelected(m as string[])}
			/>
		</ScreenPaper>
	)
}