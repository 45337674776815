import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import { ProductProps } from "../../product/model";
import { TransferStatus } from "../../transfer/model";
import TransferService from "../../transfer/service";
import UserDBRef from "../../user/dbref";
import { Report } from "../model";

export interface TechnicianReportValue{
    id: string;
    accepted: boolean;
    soUid: string;
    store: string;
    date: EpochTimeStamp;
}

const TechnicianReport: Report = {
    name: 'Produtividade Técnicos',
    checkPerm: async u => MinPerm(u.perms.report, 'r'),
    options: {
        startDate: {
            field: 'startDate',
            type: 'date',
            required: true,
            label: 'Data Início',
        },
        endDate: {
            field: 'endDate',
            type: 'date',
            required: true,
            label: 'Data Fim',
        },
        user: {
            field: 'user',
            type: 'dbref',
            component: UserDBRef,
            label: 'Usuário',
            required: true,
        }
    },
    validateOptions: async o => {
        if(o.startDate > o.endDate) throw new Error('A data de início não pode ser maior que a data fim.');
        o.startDate.setHours(0,0,0);
        o.endDate.setHours(23,59,0);
        return o;
    },
    columns: [
        {field: 'accepted', headerName: 'Aceita', type: 'boolean'},
        {field: 'soUid', headerName: 'OS'},
        {field: 'store', headerName: 'Loja', type: 'string'},
        {field: 'date', headerName: 'Data', type: 'dateTime'},
    ],
    defaultSort: [{field: 'date', sort: 'desc'}],
    process: async o => {
        const transfers = await new TransferService().list();
        const values: TechnicianReportValue[] = [];
        for(let t of Object.values(transfers)){
            if(
                t.data.status !== TransferStatus.ABERTO && 
                t.data.typeCode === 'so_transfer' &&
                t.data.crtAt >= o.startDate && 
                t.data.crtAt <= o.endDate &&
                t.data.targetUser.uid === o.user.uid
            ){
                values.push({
                    accepted: t.data.status === TransferStatus.APROVADO,
                    date: t.data.crtAt,
                    id: t.uid!,
                    soUid: t.data.props?.phoneSo?.uid || '',
                    store: t.data.sourceStore.label,
                })
            }
        }
        return values;
    },
    summaries: async (options: any, values: TechnicianReportValue[]) => {
        let soDone = 0, soRejected = 0;
        values.forEach(p => {
            if(p.accepted) soDone++;
            else soRejected++;
        })
        return [
            [['Técnico', options.user.label, 'colspan="2"']],
            [['Início', DateToString(options.startDate, 'date')], ['Fim', DateToString(options.endDate, 'date')]],
            [['O.S. Concluídas', String(soDone)], ['O.S. Rejeitadas', String(soRejected)]],
        ]
    },
    print: async (options: any, values: TechnicianReportValue[]) => {
        return {
            name: 'Relatório de Produtividade Técnicos',
            options: {},
            summaries: await TechnicianReport.summaries(options, values),
            columns: [
                ...TechnicianReport.columns as any,
            ],
            values,
        }
    }
}

export default TechnicianReport;