import { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { WizardRoot } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { PhoneSOProps } from "../model";
import CheckoutDetails from "./details";
import CheckoutEnd from "./end";
import CheckoutPhotos from "./photos";
import SimpleChecklist from "./simple_checklist";

export default function Checkout(){
    const location = useLocation();
    const [phoneSo] = useState<CRUDItem<PhoneSOProps>>(() => location.state?.phoneSo);
    

    return !phoneSo ? (
        <Navigate to={'/'}/>
    ) :
    (
        <WizardRoot
            data={phoneSo}
            screens={[
                {Component: SimpleChecklist, name: 'checklist'},
                {Component: CheckoutDetails, name: 'details'},
                {Component: CheckoutPhotos, name: 'photos'},
                {Component: CheckoutEnd, name: 'end'},
            ]}
        />
    )
}