import { Button, ButtonProps, Stack } from "@mui/material";
import { DataGrid, DataGridProps, GridFilterPanel, GridToolbarFilterButton, GridToolbarQuickFilter, GridValueGetterParams, GridColumns, GridEnrichedColDef, GridValidRowModel, GridCell, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Add, Download, Update } from "@mui/icons-material";
import deepGet from "../../utils/deep_get";
import parseBRL from "../../utils/parse_brl";
import useAuth from "../../hooks/use_auth";
import GenerateListExcel from "./generate_list_excel";
import DataGridFilteredList from "./datagrid_filtered_list";

const MultilineContent = styled.div({
    whiteSpace: 'normal',
})

const MultilineCell = (p: GridRenderCellParams<any, any, any>) => (
    <MultilineContent>{p.formattedValue}</MultilineContent>
)

interface _ListColumn {
    type?: 'string' | 'number' | 'date' | 'dateTime' | 'boolean' | 'singleSelect' | 'actions' | 'money' | 'multiline';
}

export type ListColumn = _ListColumn & GridEnrichedColDef;

export type ListColumns = ListColumn[];

export interface ListProps extends DataGridProps{
    defaultSort?: { field: string, sort: 'desc' | 'asc' }[];
    addProps?: ButtonProps;
    columns: ListColumns;
    updateList?: () => void;
    disableExport?: boolean;
    onChangeFilter?: (list: any[]) => void;
}


const _GridToolbarQuickFilter = styled(GridToolbarQuickFilter)({
    width: '100%'
});

const _GridFilterPanel = styled(GridFilterPanel)({
    '& .MuiDataGrid-filterForm': {
        flexDirection: 'column',
        '& .MuiDataGrid-filterFormDeleteIcon':{
            alignSelf: 'center'
        }
    },
    '.MuiDataGrid-paper': {
        minWidth: '50px',
    }
})

const deepValueGetter = ({row, field}: GridValueGetterParams) => deepGet(field, row);

const CustomToolbar = (p: any) => {

    const auth = useAuth();
    const canExport = Boolean(auth.user?.perms.report);

    return (
        <Stack>
            <_GridToolbarQuickFilter variant="outlined" fullWidth />
            <Stack direction="row" gap={1} flexWrap={'wrap'} sx={{'> *': {flex: '1 150px'}}} padding={'3px'}>
                {p.addProps && (
                    <Button color="success" {...p.addProps}>
                        <Add/> CRIAR
                    </Button>
                )}
                {p.updateList && (
                    <Button variant="outlined" onClick={()=>p.updateList()}>
                        <Update/> ATUALIZAR
                    </Button>
                )}
                <GridToolbarFilterButton {...p}/>
                { !p.listProps.disableExport && canExport &&
                <Button startIcon={<Download/>} onClick={() => GenerateListExcel(p.curFilter, p.listProps.columns)}>
                    EXPORTAR
                </Button>}
            </Stack>
        </Stack>
    )
}

const transformDate = (val: any) => val ? new Date(val) : null;
const dateValueGetter = ({row, field}: GridValueGetterParams) => transformDate(deepGet(field, row));
const dateNextValueGetter = (oldGetter: Function) => {
    return (p: any) => {
        transformDate(oldGetter(p));
    }
}
const moneyFormatter = ({value}: {value: number}) => parseBRL(value);

const treatColumns = (cols: ListColumns) => {
    const newCols = cols.map(c => {
        c = {...c};
        if(!c.minWidth) c.minWidth = 200;
        if(c.field.includes('.')) c.valueGetter = deepValueGetter;
        switch(c.type){
            case 'date': case 'dateTime':
                c.valueGetter = dateValueGetter;
            break;
            case "money":
                c.type = 'number';
                c.valueFormatter = moneyFormatter;
                break;
            case "multiline": case "string": case undefined:
                if(!c.renderCell) c.renderCell = MultilineCell;
        }
        return c;
    });

    return newCols;
}

export default function List(props: ListProps){

    const [columns, setColumns] = useState(props.columns);
    const [curFilter, setCurFilter] = DataGridFilteredList();

    useEffect(() => {
        const _columns = treatColumns(props.columns);
        setColumns(_columns);
    }, [props.columns]);

    useEffect(() => {
        props.onChangeFilter?.(curFilter);
    }, [curFilter]);

    return (
        <DataGrid
            disableColumnMenu
            {...props}
            components={{ 
                Toolbar: CustomToolbar,
                FilterPanel: _GridFilterPanel,
                ...props.components,
            }}
            componentsProps={{
                toolbar: {
                    addProps: props.addProps,
                    updateList: props.updateList,
                    curFilter: curFilter,
                    listProps: props,
                }
            }}
            columns={columns}
            initialState={{
                sorting: props.defaultSort && {
                    sortModel: props.defaultSort,
                },
                ...props.initialState,
            }}											
            //autoHeight
            rows={props.rows}
            onStateChange={(...params) => {
                const [s] = params;
                setCurFilter(s, props.rows);
                props.onStateChange?.(...params);
            }}
        />
    )
}