import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { DailySummaryHistoryProps, DailySummaryProps } from "./models";

export class DailySummaryService extends CRUDService<DailySummaryProps>{
    constructor(){
        super('daily_summary');
    }

    open(ds: CRUDItem<DailySummaryProps>){
        return fetcher(this.prefix + '/open/' + ds.uid)
        .then(this.parseMiddlewareItem);
    }

    close(ds: CRUDItem<DailySummaryProps>, send: boolean){
        return fetcher(`${this.prefix}/close/${ds.uid}/${send ? 1 : 0}`)
        .then(this.parseMiddlewareItem);
    }
}

export class DailySummaryHistoryService extends CRUDService<DailySummaryHistoryProps>{
    constructor(){
        super('daily_summary_history');
    }
}