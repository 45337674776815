import { Button, Stack } from "@mui/material";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../shared/components/easy_form";
import { EasyFormFieldList } from "../../shared/components/easy_form/types";

const fields: EasyFormFieldList = {
    cost_value: {
        field: "cost_value",
        type: "number",
        label: "Custo Real Un. R$",
        required: true
    },
    sell_value: {
        field: "sell_value",
        type: "number",
        label: "Valor de Venda Un. R$",
        required: true,
    },
}

export interface MassChangeCostValueProps{
    cancel: () => void;
    onSubmit: (values: {cost_value: number, sell_value: number}) => void;
}

export default function MassChangeCostValue(props: MassChangeCostValueProps){
    return (
        <EasyForm fields={fields} onSubmit={props.onSubmit}>
            <Stack spacing={1}>
                <EasyFormInput field="cost_value"/>
                <EasyFormInput field="sell_value"/>
                <Button color="inherit" onClick={props.cancel} variant="text">
                    CANCELAR
                </Button>
                <EasyFormAction>
                    <Button fullWidth color="success" type="submit">
                        SALVAR
                    </Button>
                </EasyFormAction>
            </Stack>
        </EasyForm>
    )
}