import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { forwardRef, useState } from "react";
import { EasyFormSelectValues } from "../easy_form/types";

type AutocompleteRealProps = Omit<AutocompleteProps<any, any, any, any, any>, "renderInput" | "onChange">;

export interface AutocompleteInputProps extends AutocompleteRealProps{
    label?: string;
    options: EasyFormSelectValues;
    name?: string;
    error?: boolean;
    helperText?: string;
    onChange?: (event: {target: {value: any, name: any}}) => void;
}

const AutocompleteInput = forwardRef((props: AutocompleteInputProps, ref: any) => {
    const { label, onChange, error, helperText, ...other } = props;
    const inputProps = {label, error, helperText};
    const [value, setValue] = useState(props.value || props.defaultValue);
    const realValue = props.value || value || null;
    const valueOption = realValue === null ? null : props.options.find(op => op.value === realValue);
    return (
        <Autocomplete
            renderInput={(p, ) => <TextField {...inputProps} {...p}/>}
            {...other}
            ref={ref}
            onChange={(e, newValue) => {
                const val = newValue?.value || null;
                onChange?.({
                    target: {
                        name: props.name!,
                        value: val,
                    }
                });
                setValue(val);
            }}
            value={valueOption}
        />
    )
});

export default AutocompleteInput;