import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { PhoneSOProps } from "../phone_so/model";
import { SellProps } from "../sell/model";

export interface CreditTaxItem{
    times: number;
    tax: number;
}
export interface GeneralConfigsProps extends DatedProps{
    taxes?: {
        money: number;
        credit?: CreditTaxItem[];
        debt: number;
        pix: number;
    },
    texts?: {
        terms?: {
            sell?: string;
            so_checkout?: string;
            so_checkin?: string;
        }
    }
}

export const GeneralConfigsFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        ...GeneralConfigsTaxesFields({...options, prefix: 'taxes'}),
        ...GeneralConfigsTermsFields({...options, prefix: 'texts.terms'}),
    }, options);
}

export const GeneralConfigsTaxesFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        money: {
            field: "money",
            type: "number",
            label: "Dinheiro",
        },
        debt: {
            field: "debt",
            type: "number",
            label: "Débito",
        },
        pix: {
            field: "pix",
            type: "number",
            label: "PIX",
        },
    }, options);
}

export const GeneralConfigsTermsFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        sell: {
            field: 'sell',
            type: 'multiline',
            label: 'Venda',
        },
        so_checkin: {
            field: 'so_checkin',
            type: 'multiline',
            label: 'Entrada de OS',
        },
        so_checkout: {
            field: 'so_checkout',
            type: 'multiline',
            label: 'Saída de OS',
        },
    }, options)
}

export function calcTax(item: PhoneSOProps | SellProps){
    if(!item.payment || !item.tax) return 0;
    let v = 0;
    for(let [type, tax] of Object.entries(item.tax)){
        const cb = item.payment[type];
        if(!cb || !tax) continue;
        v += cb * (tax/100);
    }
    return v;
}