import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import WizardScreenProps from "../../../shared/components/wizard/wizard_screen_props";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";

export default function PayInfo(props: WizardScreenProps){
    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
    });

    const [fields] = useState(() => ModelFieldsFilter(PhoneSOFields({
        defaults: wizard.data.data,
    }), ['sellerObs', 'serviceObs', 'suggestedValue']));

    return (
        <Stack flex={1}>
            <EasyForm onSubmit={(data: Partial<PhoneSOProps>) => {
                DeepAssign(wizard.data.data, data);
                wizard.submit();
            }} fields={fields} flex1>
                <ScreenPaper>
                    <Typography align="center">
                        ORDEM DE SERVIÇO
                    </Typography>
                    <Typography align="center">
                        Observações
                    </Typography>
                    <EasyFormInput field="serviceObs" required label="Serviço a ser executado"/>
                    <EasyFormInput field="sellerObs" required/>
                    <EasyFormInput field="suggestedValue" required />
                </ScreenPaper>
                <div style={{flex: 1}}></div>
                <WizardNav wizard={wizard} useFormSubmit/>
            </EasyForm>
        </Stack>
    )
}