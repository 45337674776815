import { ShoppingBasket } from "@mui/icons-material";
import { Route, Routes, useNavigate } from "react-router-dom";
import SelectItems from "./select_items";
import SellWizardContext, { SellWizardContextValue } from "./sell_wizard_context";
import { useState, useEffect, useMemo } from "react";
import ItemDetails from "./item_details";
import SellDetails from "./sell_details";
import Payment from "./payment";
import Summary from "./summary";
import useAuth from "../../shared/hooks/use_auth";
import Success from "./success";
import SafeList from "../../shared/utils/safe_list";
import StrictSelectClient from "./strict_select_client";
import useDomain from "../../shared/hooks/use_domain";

export const routeConfig: RouteConfig = {
    key: 'sell_wizard',
    label: 'Vender',
    Icon: ShoppingBasket,
    buttonProps: {
        color: 'success'
    }
}

const routes = {
    select_items: SelectItems,
    item_details: ItemDetails,
    sell_details: SellDetails,
    payment: Payment,
    summary: Summary,
    success: Success,
    strict_select_client: StrictSelectClient,
}

export default function SellWizardModule(){
    const navigate = useNavigate();
    const auth = useAuth();
    const clientSellMode = useDomain<string>('client', 'sell_mode');

    const steps = useMemo(() => SafeList([
        clientSellMode === 'strict' ? {name: 'strict_select_client'} : undefined,
        {name: 'select_items' },
        {name: 'item_details' },
        {name: 'sell_details' },
        {name: 'payment' },
        {name: 'summary' },
        {name: 'success' },
    ]), []);
    
    const [sellWizardContextValue] = useState<SellWizardContextValue>(() => ({
        sell: {
            items: {}, 
            payment: {}, 
            zone: {...auth.user?.user.configs.zone},
            store: {...auth.user?.user.configs.store},
            seller: {uid: auth.user?.user.uid, label: auth.user?.user.name},
        } as any,
        steps,
    }));
    
    useEffect(() => {
        navigate(steps[0].name, {replace: true});
    },[]);

    return (
        <SellWizardContext.Provider value={sellWizardContextValue}>
            <Routes>
                {steps.map((item) => {
                    const RouteItem = routes[item.name];
                    return (
                        <Route 
                            element={<RouteItem name={item.name}/>} 
                            path={item.name} 
                            key={item.name}
                        />
                    )
                })}
            </Routes>
        </SellWizardContext.Provider>
    )
}