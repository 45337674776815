import { CircularProgress, Stack } from "@mui/material";
import Centered from "../centered";

export default function ScreenLoading(){
    return (
        <Centered>
            <Stack spacing={1} alignItems="center">
                <CircularProgress />
            </Stack>
        </Centered>
    )
}