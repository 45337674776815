import { Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dialogHelper from "../../../shared/components/easy_dialog";
import ScreenPaper from "../../../shared/components/screen_paper";
import { CRUDItem } from "../../../shared/crud/crud_service";
import useAuth from "../../../shared/hooks/use_auth";
import { DBRef } from "../../../shared/models/model";
import UserDBRef from "../../user/dbref";
import { UserProps } from "../../user/model";
import { PhoneSOProps, status_list } from "../model";
import PhoneSoFilteredUserDBRef from "../phone_so_filtered_user_dbref";
import PhoneSOService from "../service";
import TransferService from "../../transfer/service";

export function SendTech(){

    const location = useLocation();
    const params = useParams();
    const modeTech = params.mode === 'tech';
    const [phoneSo] = useState<CRUDItem<PhoneSOProps>>(() => location.state?.phoneSo);
    const navigate = useNavigate();
    const [user, setUser] = useState<DBRef<UserProps, 'name'>>();
    const auth = useAuth();

    useEffect(() => {
        if(!phoneSo){
            navigate(-1);
        }
    }, []);

    const submit = () => {
        if(!user){
            dialogHelper.showError({message: "Selecione um usuário!"});
            return;
        }
        const transferService = new TransferService();


        dialogHelper.showLoading();
        transferService.createSoTransfer({
            sourceStore: auth.user?.user.configs.store!,
            sourceZone: auth.user?.user.configs.zone!,
            sourceUser: {label: auth.user?.user.name!, uid: auth.user?.user.uid!},
            targetUser: user,
            targetStore: user._ref?.data.configs.store!,
            targetZone: user._ref?.data.configs.zone!,
            targetStatus: modeTech ? status_list['MANUTENÇÃO'].code : status_list['RETIRADA'].code,
            phoneSo: {label: null!, uid: phoneSo.uid!, deviceName: `${phoneSo.data.deviceBrand} - ${phoneSo.data.deviceModel}`},

        })
        .then(() => {
            dialogHelper.showDialog({
                title: 'Aviso!',
                content: `Foi gerada a solicitação de envio para o ${modeTech ? 'técnico' : 'responsável selecionado'}! Após a aprovação da solicitação por parte do mesmo, o status da OS será alterado automaticamente.`,
                buttons: [dialogHelper.okButton()]
            })
            navigate('../edit/'+phoneSo.uid);
        })
        .catch(dialogHelper.showError);
    }

    return (
        <ScreenPaper>
            <Stack spacing={1}>
                <Typography>
                    ENVIAR OS
                </Typography>
                <Typography>
                    Código: {phoneSo?.uid}
                </Typography>
                <Typography>
                    Selecione o {modeTech ? 'técnico' : 'vendedor'} para o qual a OS será enviada.
                </Typography>
                <PhoneSoFilteredUserDBRef
                    mode={modeTech ? 'technicians' : 'sellers'}
                    label={modeTech ? 'Técnico' : 'Vendedor'}
                    phoneSo={phoneSo}
                    value={user}
                    onChange={({target}) => setUser(target.value)}
                />
                <Button onClick={submit}>
                    ENVIAR OS
                </Button>
            </Stack>
        </ScreenPaper>
        
    )
}