import Gallery, { GalleryPhoto } from "../../shared/components/gallery";
import useCRUDEditContext from "../../shared/crud/use_crud_edit_context";
import { ProductProps } from "./model";
import ProductService from "./service";
import useEffectState from "../../shared/hooks/use_effect_state";
import dialogHelper from "../../shared/components/easy_dialog";
import { CRUDItem } from "../../shared/crud/crud_service";

export interface ProductGalleryProps{
    savePhotosRef: {current: any};
}

export default function ProductGallery(props: ProductGalleryProps){
    const create = useCRUDEditContext<ProductProps, ProductService>();
    const [photos, setPhotos] = useEffectState<GalleryPhoto[]>(() => ([...Object.values(create.data.photos||{})]), [create.uid]);
    
    const deletePhoto = (photo: GalleryPhoto) => {
        if(photo.isNew){
            setPhotos(photos.filter(p => p !== photo));
        }
        else{
            let curPhotos = create.data.photos || {};
            
            const [key] = Object.entries(curPhotos).find(([k, p]) => p.url === photo.url)||[];
            if(!key) return;
            setPhotos(photos.filter(p => p !== photo));

            create.crudService.deletePhoto(create.uid!, key)
            .then((newData) => {
                const newPhotos = newData.data.photos || {};
                curPhotos = newPhotos;
                create.setData({ ...create.data, photos: newPhotos});
            })
            .catch((err) => {
                dialogHelper.showError(err);
            })
            .finally(() => {
                setPhotos([...Object.values(curPhotos), ...photos.filter(p => p.file)]);
            })
        }
    }

    const savePhotos = props.savePhotosRef.current = async (p: CRUDItem<ProductProps>) => {
        const newPhotos = photos.filter(p => p.file);
        if(!newPhotos.length) return;
        const newItem = await create.crudService.sendPhotos(p, newPhotos.map(p => p.file!));
        Object.assign(p.data, newItem.data);
        setPhotos(Object.values(p.data.photos||{}));
    }

    return (
        <Gallery
            imageList={photos}
            onNewPhotos={newPhotos => setPhotos([...photos, ...newPhotos])}
            onDeletePhoto={deletePhoto}
        />
    )
}