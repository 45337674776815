const deepGet = (fields: string, value: any, options: {recursive?: boolean} = {}) => {
    const fieldSep = fields.split('.');
    for(let i = 0; i < fieldSep.length && value !== undefined; i++){
        const key = fieldSep[i];
        const parent = value;
        value = parent[key];
        if(options.recursive && value === undefined){
            parent[key] = value = {};
        }
    }
    return value;
}

export default deepGet;