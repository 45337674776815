import { useNavigate } from "react-router-dom";
import { useSellWizardContext } from "../sell_wizard_context";
import dialogHelper from "../../../shared/components/easy_dialog";
import { routeConfig } from '..';

export interface UseNextActionProps{
    beforeRun?: () => Promise<any>; 
    name: string;
    route?: string;
}

export default function useNextAction(props: UseNextActionProps){
    const navigate = useNavigate();
    const ctx = useSellWizardContext();
    const goNext = async () => {
        const beforeRun = props.beforeRun || (async () => {});
        await beforeRun()
        .then(() => {
            let nextRoute: string;
            if(props.route){
                nextRoute = props.route;
            }
            else{
                const i = ctx.steps.findIndex(item => item.name === props.name);
                nextRoute = ctx.steps[i+1].name;
            }
            navigate(`/${routeConfig.key}/${nextRoute}`);
        })
        .catch((e) =>{
            if(e.message) dialogHelper.showError(e);
        });
    }

    return goNext;
}