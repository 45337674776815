import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import { StockHistoryProps } from "./model";
import fetcher from "../../shared/utils/fetcher";

export default class StockHistoryService extends CRUDService<StockHistoryProps>{
    constructor(){
        super('stock_history');
    }
    setApproval(history: CRUDItem<StockHistoryProps>, approved: boolean){
        return fetcher(`${this.prefix}/setApproval/${history.uid}/${String(approved)}`, 'PATCH')
        .then(this.parseMiddlewareItem)
        .then(v => {
            history.data.approved = v.data.approved;
            return v;
        })
    }
    getByList(list: string[]): Promise<CRUDItem<StockHistoryProps>[]> {
        return fetcher(this.prefix + '/getByList', 'POST', list)
        .then(r => r.json())
        .then(l => l?.map(this.parseCRUDItem))
    }
}