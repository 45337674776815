import { DBRefEasyFormSelectValues } from "../../shared/components/dbref_input";
import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import FileService from "../../shared/services/file_service";
import fetcher from "../../shared/utils/fetcher";
import { UserProps } from "../user/model";
import { PhoneSOProps } from "./model";

export default class PhoneSOService extends CRUDService<PhoneSOProps>{
    constructor(){
        super('phone_so');
    }
    
    listTechnicians(item: CRUDItem<PhoneSOProps>): Promise<DBRefEasyFormSelectValues> {
        return fetcher(`${this.prefix}/listTechnicians/${item.uid}`, 'GET')
        .then(r => r.json())
        .then(b => 
            Object.values(b||[])
            .map(this.parseCRUDItem)
            .map((item) => this.crudItemToDBRefSelect('name', item))
        );
    }

    listSellers(item: CRUDItem<PhoneSOProps>): Promise<DBRefEasyFormSelectValues> {
        return fetcher(`${this.prefix}/listSellers/${item.uid}`, 'GET')
        .then(r => r.json())
        .then(b => 
            Object.values(b||[])
            .map(this.parseCRUDItem)
            .map((item) => this.crudItemToDBRefSelect('name', item))
        );
    }
    
    sendPhotos(item: CRUDItem<PhoneSOProps>, newPhotos: File[], tag: 'checkin' | 'manutencao'){
        const fileService = new FileService();
        const form = fileService.generateFormData(newPhotos, 'photos');
        return fetcher(`${this.prefix}/sendPhotos/${item.uid}/${tag}`, 'POST', form)
        .then(this.parseMiddlewareItem)
    }

    finish(item: CRUDItem<PhoneSOProps>){
        return fetcher(`${this.prefix}/finish/${item.uid}`, 'GET')
        .then(this.parseMiddlewareItem)
    }
    cancel(uid: string){
        return fetcher(`${this.prefix}/cancel/${uid}`, 'PATCH')
        .then(this.parseMiddlewareItem);
    }
}