import { useState } from "react";
import { WizardRoot } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import useAuth from "../../../shared/hooks/use_auth";
import getUserReferentialInfo from "../../../shared/utils/get_user_referential_info";
import { PhoneSOProps } from "../model";
import BasicInfo from "./basic_info";
import End from "./end";
import PayInfo from "./payinfo";
import CreatePhotos from "./photos";
import SimpleChecklist from "./simple_checklist";
import Summary from "./summary";

export default function PhoneSOCreate(){

    const auth = useAuth();
    const refInfo = getUserReferentialInfo(auth.user!);
    const [data] = useState<CRUDItem<PhoneSOProps>>({
        data: {
            seller: refInfo.user,
            store: refInfo.store!,
            zone: refInfo.zone!,
            photos: {}
        }
    } as any);

    return (
        <WizardRoot
            data={data}
            screens={[
                {name: 'start', Component: BasicInfo},
                {name: 'checklist', Component: SimpleChecklist},
                {name: 'payinfo', Component: PayInfo},
                {name: 'summary', Component: Summary},
                {name: 'photos', Component: CreatePhotos},
                {name: 'end', Component: End},
            ]}
        />
    )
}