import { useState } from "react";

export default function useIncrementState<TValue>(value: TValue): [state: TValue, setState: (newValue: Partial<TValue>) => void] {
    const [state, _setState] = useState(value);

    const setState = (newValue: Partial<TValue>) => {
        _setState((prevState) => ({...prevState, ...newValue}));
    }

    return [state, setState];
}