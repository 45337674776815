import { useMemo } from "react";
import AutocompleteInput from "../../shared/components/autocomplete_input";
import DBRefInput, { DBRefsToSelectValues } from "../../shared/components/dbref_input";
import { useSummariesController } from "./controller"
import { EasyFormSelectValues } from "../../shared/components/easy_form/types";
import { DBRef } from "../../shared/models/model";
import { StoreProps } from "../store/model";
import { DailySummaryProps, DailySummaryStatus, DailySummaryStatusColor } from "./models";
import { CRUDItem } from "../../shared/crud/crud_service";
import AlphabeticalSort from "../../shared/utils/alphabetical_sort";

export default function SummariesStoreSelect(){
    const controller = useSummariesController();

    const options = useMemo(() => {
        const { isSingleDate } = controller.dtHashes;
        const newStoreList: DBRef<StoreProps, 'name', {_ds?: CRUDItem<DailySummaryProps>}>[] = [];
        controller.dsList.forEach(ds => {
            if(!newStoreList.find(s => s.uid === ds.data.store.uid)){
                newStoreList.push({...ds.data.store, _ds: ds});
            } 
        });
        if(controller.selectedStore){
            const i = newStoreList.findIndex(st => st.uid === controller.selectedStore?.uid)
            if(i>= 0) controller.setSelectedStore(newStoreList[i]);
        }
        return newStoreList.sort((a,b) => AlphabeticalSort(a.label, b.label)).map(ns => ({
            label: `${ns.label}${isSingleDate ? ' - ' + DailySummaryStatus[ns._ds?.data.status!] : ''}`,
            value: ns,
            _color: !isSingleDate ? undefined : DailySummaryStatusColor[DailySummaryStatus[ns._ds?.data.status!]],
        }))
    }, [controller.dsList]);

    return (
        <AutocompleteInput
            readOnly={!controller.canChangeStore}
            label="Loja"
            onChange={e => controller.setSelectedStore(e.target.value)}
            options={options}
            value={controller.selectedStore}
            renderOption={(p, o) => <li {...p} style={{color: o._color}}><b>{o.label}</b></li>}
        />
    )
}