import { Fragment, useMemo, useState } from "react";
import { hashDate, useSummariesController } from "./controller";
import FixedTextField from "../../shared/components/fixed_text_field";
import { CalendarMonth } from "@mui/icons-material";
import dialogHelper from "../../shared/components/easy_dialog";
import DateInput from "../../shared/components/date_input";
import { Button, Chip, Dialog, DialogContent, DialogTitle, Divider, Paper, Stack } from "@mui/material";
import BetterDate from "../../shared/utils/better_date";
import DateToString from "../../shared/utils/date_to_string";

const today = new Date();
const momments: [label: string, msd: Date, med: Date][] = [
    ['HOJE', new Date(), new Date()],
    ['ONTEM', BetterDate({Date: '-1'}), BetterDate({Date: '-1'})],
    ['ESTA SEMANA', BetterDate({Date: `-${today.getDay()}`}), new Date()],
    ['SEMANA PASSADA', BetterDate({Date: `-${today.getDay() + 7}`}), BetterDate({Date: `-${today.getDay()+1}`})],
    ['ESTE MÊS', BetterDate({Date: '1'}), new Date()],
    ['MÊS PASSADO', BetterDate({Date: '1', Month: '-1'}), BetterDate({Date: 0})],
    ['ESTE ANO', BetterDate({Month: 0, Date: 1}), new Date()],
]

function SummariesDatesComponent(props: {sd: any, ed: any, change: (sd: Date, ed: Date) => void, close: () => void}){
    const [sd, setSD] = useState<Date>(props.sd);
    const [ed, setED] = useState<Date>(props.ed);
    
    const deploy = () => {
        try{
            if(!(new Date(sd).getTime())) throw new Error('A data inicial é inválida.');
            if(!(new Date(ed).getTime())) throw new Error('A data final é inválida.');
            if(hashDate(sd) > hashDate(ed)) throw new Error('A data inicial não pode ser maior que a final.');
            if(ed > new Date()) throw new Error('A data final não pode ser maior que a data atual.');
            props.change(sd,ed);
        }
        catch(err:any){
            dialogHelper.showError(err);
        }
    }

    return (
        <Stack spacing={1}>
            <Paper variant="outlined">
            <Stack direction='row' gap={"5px"} flexWrap="nowrap" style={{overflowX: 'auto',}} padding="5px" borderRadius={'5px'}>
                {momments.map(([label, msd, med]) => (
                    <Chip 
                        key={label} label={label} 
                        color="primary" 
                        variant={msd === sd && med === ed ? 'filled' : "outlined"} 
                        onClick={() => {
                            setSD(msd);
                            setED(med);
                        }}
                    />
                ))}
            </Stack>
            </Paper>
            <Divider/>
            <DateInput
                onChange={e => setSD(e.target.value)}
                value={sd}
                label="Início"
                type="date"
            />
            <DateInput
                onChange={e => setED(e.target.value)}
                value={ed}
                label="Fim"
                type="date"
            />
            <Stack spacing={1} direction='row' justifyContent="flex-end">
                <Button onClick={props.close} variant='text' color='inherit'>
                    FECHAR
                </Button>
                <Button onClick={deploy} color='success'>
                    OK
                </Button>
            </Stack>
        </Stack>
    )
}

export default function SummariesDates(){
    const controller = useSummariesController();
    const dtHashes = useMemo(() => {
        const hashes = controller.dtHashes;
        return {
            ...hashes,
            bshash: DateToString(controller.startDt, 'date'),
            behash: DateToString(controller.endDt, 'date'),
        }
    }, [controller.startDt, controller.endDt]);

    const [open, setOpen] = useState(false);

    const changeDates = (sd: Date, ed: Date) => {
        controller.setDates({endDt: ed, startDt: sd});
        setOpen(false);
    }

    return (
        <Fragment>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Definir Período
                </DialogTitle>
                <DialogContent>
                    <SummariesDatesComponent close={() => setOpen(false)} ed={controller.endDt} sd={controller.startDt} change={changeDates}/>
                </DialogContent>
            </Dialog>
            <FixedTextField
                value={dtHashes.isSingleDate ? dtHashes.bshash : `${dtHashes.bshash} - ${dtHashes.behash}`}
                onClick={() => controller.canChangeDate && setOpen(true)}
                readOnly={true}
                label="Data"
                InputProps={{
                    endAdornment:<CalendarMonth/>
                }}
            />
        </Fragment>
    )
}