import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { AddressProps, AddressFields } from "../address/model";


export interface ZoneProps extends DatedProps{
    name: string;
    description: string;
    address: AddressProps;
}

export const ZoneFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        description: {
            field: "description",
            type: "text",
            label: 'Descrição'
        },
        ...AddressFields({defaults: options?.defaults?.address, prefix: 'address'})
    }, options);
}