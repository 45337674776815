import { Business } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import ZoneEdit from "./edit";
import ZoneList from "./list";

export const routeConfig: RouteConfig = {
    key: 'zone',
    label: 'Zonas',
    Icon: Business,
}

export default function ZoneModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'zone',
        routes: {
            create: {Component: ZoneEdit},
            update: {Component: ZoneEdit},
            view: {Component: ZoneEdit},
            list: {Component: ZoneList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}