import { ListColumns } from ".";
import ExcelGenerator from "../../utils/excel_generator";

export default async function GenerateListExcel(rows: any[], columns: ListColumns){
    const excel = new ExcelGenerator(`Export ${new Date().toISOString()}`);
    excel.adicionarTabela('planilha', '', '', columns.map(c => {
        let type: any;
        switch(c.type){
            case 'boolean': type = 'boolean'; break;
            case 'money': type = 'currency'; break;
            case 'number': type = 'number'; break;
            case 'date': case 'dateTime': 
                type = 'date'; 
            break;
            default: type = 'text';
        }
        return {
            field: c.field!,
            title: c.headerName||'col',
            type,
            valueGetter: c.valueGetter as any,
        }
    }), rows);

    await excel.gerar(false);
}