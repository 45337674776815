import useAuthService from "../../shared/services/auth_service";
import LoginConfig from "./login_config";
import dialogHelper from "../../shared/components/easy_dialog";

export default function useLoginController(){
    const authService = useAuthService();
    
    const login = (loginConfig: LoginConfig) => {
        dialogHelper.showLoading("Aguarde...");
        authService.login(loginConfig)
        .then(() => dialogHelper.closeDialog())
        .catch(dialogHelper.showError)
    }

    return {
        login
    }
}