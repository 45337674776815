import { StoreProps } from "../../../modules/store/model";
import { UserProps } from "../../../modules/user/model";
import { ZoneProps } from "../../../modules/zone/model";
import { CRUDItem } from "../../crud/crud_service";
import { DBRef } from "../../models/model";
import UserInfo from "../../stores/auth_slice/user_info";

export default function getUserReferentialInfo({user}: UserInfo): {
    zone?: DBRef<ZoneProps, 'name'>,
    store?: DBRef<StoreProps, 'name'>,
    user: DBRef<UserProps, 'name'>,
}{
    return {
        zone: user.configs.zone ? {...user.configs.zone} : undefined,
        store: user.configs.store ? {...user.configs.store} : undefined,
        user: {
            uid: user.uid,
            label: user.name,
        }
    }   
}