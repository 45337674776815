import { MenuItem } from "@mui/material";
import { forwardRef } from "react";
import FixedTextField, { FixedTextFieldProps } from "../fixed_text_field";

export interface SelectInputProps extends FixedTextFieldProps{
    options: {label: string, value: any}[];
}

const SelectInput = forwardRef((props: SelectInputProps, ref: any) => {
    const { options, value, ...other } = props;
    return (
        <FixedTextField {...other} value={value || ''} select ref={ref}>
            {options.map(({label, value}) => (
                <MenuItem value={value}>
                    {label}
                </MenuItem>
            ))}
        </FixedTextField>
    );
});

export default SelectInput;