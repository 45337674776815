import general from "../../../.env/general.json";

export default function redirectToProject(){
    let id = window.location.hostname.split(".")[0];
    const reserve = reserved[id];
    if(reserve){
        if(typeof reserve === 'function'){
            reserve();
            return;
        }
        else{
            id = reserve;
        }
    }
    window.location.replace(`/${id}`);
}

const reserved = {
    'localhost': general.REACT_APP_DEFAULT_PROJECT || 'example',
    'mastercompras-co': 'mastercompras',
    'sagittariuserp-dev': 'example',
    'sagittariuserp-prod': 'assistech'
}