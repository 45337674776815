import styled from "@emotion/styled";
import { AccountCircle, Menu } from "@mui/icons-material";
import { Stack, useScrollTrigger, AppBar, Toolbar, Slide, IconButton, Button, Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemButton, Box, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../shared/hooks/use_auth";
import useMenuComponents from "../../shared/hooks/use_menu_components";
import CleanCache from "../../shared/components/clean_cache";
import VersionComponent from "../../shared/components/version_component";

const SpaceDiv = styled.div({
    height: 55
})

const Separator = styled.div({
    flex: 1,
})

export default function Header(){
    const trigger = useScrollTrigger({
        threshold: 100,
        target: window,
    });
    const auth = useAuth();
    const menu = useMenuComponents({withHome: true, withLogout: true});
    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = () => setOpenDrawer(!openDrawer);
    const navigate = useNavigate();
    const toggleDrawerExec = (buttonProps: any): any => {
        return (e: any) => {
            e.preventDefault();
            if(buttonProps.href) navigate(buttonProps.href);
            if(buttonProps.onClick) buttonProps.onClick();
            toggleDrawer();
        }
    }

    return (
        <Fragment>
            <Slide direction="down" appear={false} in={!trigger}>
                <AppBar>
                    <Toolbar variant="dense">
                        <IconButton color="inherit" onClick={toggleDrawer}>
                            <Menu />
                        </IconButton>
                        <Separator/>
                        <Button endIcon={<AccountCircle/>} color="inherit" variant="text">
                            <Stack spacing={0}>
                                <Typography component="span">
                                    {auth.user?.user.name}
                                </Typography>
                                <Typography variant="caption">
                                    {auth.user?.user.configs.store.label}
                                </Typography>
                            </Stack>
                        </Button>
                    </Toolbar>
                </AppBar>
            </Slide>
            <Drawer open={openDrawer} onClose={toggleDrawer}>
                <Stack sx={{ width: 200 }}>
                    <List>
                        {menu.map((item, i) => (
                            <ListItem disablePadding key={i}>
                                <ListItemButton {...item.buttonProps} onClick={toggleDrawerExec(item.buttonProps)}>
                                    <ListItemIcon>
                                        <item.Icon/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {item.label}
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Box alignSelf="center">
                        <CleanCache/>
                    </Box>
                    <VersionComponent/>
                </Stack>
            </Drawer>
            <SpaceDiv/>
        </Fragment>
    )
}