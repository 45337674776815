import dialogHelper from "../../../shared/components/easy_dialog";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { store } from "../../../shared/stores/root_store/store";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import { AddressProps, makeAddressString } from "../../address/model";
import { StockHistoryProps } from "../../stock_history/model";
import StoreService from "../../store/service";

export default function WarrantyPrint(history: CRUDItem<StockHistoryProps>, options?: any){
    //dialogHelper.showLoading();
    const project = store.getState().project;
    new StoreService().getDescription(history.data.store.uid)
    .then(store => {
        DocumentTemplatePrinter('warranty.html', {
            UID: history.uid!,
            logoURL: project.project?.ui.logo_url!,
            storeDescription: store.data.description,
            storeAddress: makeAddressString(store.data.address||{} as AddressProps),
            storeSocial: store.data.social!,
            storePhone: store.data.phone!,
            crtAt: DateToString(history.data.crtAt),
            productName: history.data.product.label,
            amountDiff: Math.abs(history.data.lastOpAmountDiff).toString(),
            storeName: store.data.name,
            modifier: history.data.modifiedBy.label,
            description: history.data.lastOpObs!,
        });
        //dialogHelper.closeDialog();
    })
    .catch(dialogHelper.showError);
}