import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Divider, Stack } from "@mui/material";
import { useContext } from "react";
import EasyFormContext from "../easy_form/easy_form_context";
import useWizard from "./use_wizard";

export interface WizardNavProps{
    wizard: ReturnType<typeof useWizard>;
    submit?: () => void;
    hidePrevirous?: boolean;
    hideNext?: boolean;
    useFormSubmit?: boolean;
}

export default function WizardNav(props: WizardNavProps){

    const formContext = useContext(EasyFormContext);

    return (
        <Stack>
            <Divider/>
            <BottomNavigation>
                {
                    props.hidePrevirous ? undefined :
                    <BottomNavigationAction label="Anterior" icon={<ArrowBack/>} showLabel onClick={props.wizard.previrous}/>
                }
                {
                    props.hideNext ? undefined :
                    <BottomNavigationAction label="Próximo" icon={<ArrowForward/>} showLabel onClick={props.submit || (props.useFormSubmit ? formContext.submit : props.wizard.submit)}/>
                }
            </BottomNavigation>
        </Stack>
    )
}



