import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import FileService from "../../shared/services/file_service";
import fetcher from "../../shared/utils/fetcher";
import { ProductProps } from "./model";

export default class ProductService extends CRUDService<ProductProps>{
    constructor(){
        super('product');
    }

    public create(item: CRUDItem<ProductProps>){
        item.data.name = item.data.name.toUpperCase().trim();
        return super.create(item);
    }

    public update(item: CRUDItem<Partial<ProductProps>>){
        item.data.name = item.data.name?.toUpperCase().trim();
        return super.update(item);
    }

    public massChangeValues(selected: string[], cost_value: number, sell_value: number){
        return fetcher(`${this.prefix}/massChangeValues`, 'PATCH', {
            selected, cost_value, sell_value
        });
    }
    public massDelete(selected: string[]){
        return fetcher(`${this.prefix}/massDelete`, 'DELETE', {
            selected
        });
    }

    sendPhotos(item: CRUDItem<ProductProps>, newPhotos: File[]){
        const fileService = new FileService();
        const form = fileService.generateFormData(newPhotos, 'photos');
        return fetcher(`${this.prefix}/sendPhotos/${item.uid}`, 'POST', form)
        .then(this.parseMiddlewareItem);
    }

    deletePhoto(uid: string, photoId: string){
        return fetcher(`${this.prefix}/deletePhoto/${uid}/${photoId}`, 'DELETE')
        .then(this.parseMiddlewareItem);
    }
}