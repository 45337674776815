import { Sell, Storefront } from "@mui/icons-material";
import { Navigate, Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import SellEdit from "./edit";
import SellList from "./list";

export default function ProductModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'product',
        routes: {
            create: {Component: () => <Navigate to="/sell_wizard"/>},
            update: {Component: SellEdit},
            view: {Component: SellEdit},
            list: {Component: SellList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

export const routeConfig: RouteConfig = {
    key: 'sell',
    label: 'Vendas',
    Icon: Storefront,
}