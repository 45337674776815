export interface BetterDateOptions{
    FullYear?: string | number;
    Month?: string | number;
    Date?: string | number;
    Hours?: string | number;
    Seconds?: string | number; 
    Milliseconds?: string | number;
}
export default function BetterDate(options: BetterDateOptions, baseDate: any = new Date()){
    const date = new Date(baseDate);
    for(let [key, value] of Object.entries(options)){
        if(value == null) continue;
        const flag = value[0];
        const setter = 'set'+key, getter = 'get'+key;
        if(flag && (flag == "+" ||  flag == "-")){
            const sep = parseInt(value.substring(1));
            const current = date[getter]();
            date[setter](flag == "-" ? current - sep : current + sep);
        }
        else{
            date[setter](parseInt(value));
        }
    }

    return date;
}