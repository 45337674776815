import LoginConfig from "../../../modules/login/login_config";
import UserInfo from "../../stores/auth_slice/user_info";
import { setAuthState } from "../../stores/auth_slice";
import { useAppDispatch } from "../../stores/root_store/hooks";
import fetcher from "../../utils/fetcher";
import forage from "../../stores/cache";

export default function useAuthService(){
    const dispach = useAppDispatch();

    const setLoggedOff = () => dispach(setAuthState({user: undefined, loaded: true, logged: false}));
    (window as any)._dispatchLogout = setLoggedOff;
    const setLoggedIn = (userInfo: UserInfo) => dispach(setAuthState({user: userInfo, loaded: true, logged: true}));

    const fetchCurrentUser = () => fetcher('auth/current')
        .then(resp => resp.json())
        .then((userInfo: UserInfo) => {
            setLoggedIn(userInfo);
            return userInfo;
        })
        .catch(err => {
            console.log(err);
            setLoggedOff();
            forage.clear();
            //throw new Error(err.message || 'Usuário não conectado.');
        })
    ;

    const login = (loginConfig: LoginConfig) => fetcher('auth/login', 'POST', loginConfig)
        .then(resp => resp.json())
        .then((userInfo: UserInfo) => {
            setLoggedIn(userInfo);
            return userInfo;
        })
    ;

    const logout = async () => {
        await forage.clear();
        fetcher('auth/logout')
        .catch(console.log);
        setLoggedOff();
    }

    const loadAuth = async () => fetchCurrentUser();

    return {
        fetchCurrentUser,
        login,
        loadAuth,
        logout,
    }
}