import { Stack, Paper, Typography } from "@mui/material";
import { useState } from "react";
import dialogHelper from "../../../shared/components/easy_dialog";
import NumberInput from "../../../shared/components/number_input";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import useIncrementState from "../../../shared/hooks/use_increment_state";
import PaymentInputs, { PaymentInputsChangeProps } from "../../payment/payment_inputs";
import { PhoneSOProps } from "../model";
import PhoneSOService from "../service";


export default function FinishPayment(props: {name: string}){

    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
        nextCallback: async () => {

        },
    }); 

    const phoneSo = wizard.data.data;
    const [paymentState, setPaymentState] = useState<PaymentInputsChangeProps>({} as any);
    

    const submit = async () => {
        if(!paymentState.valid){
            dialogHelper.showError(new Error('Preencha os valores de pagamento para serem de acordo com o total pago.'));
            return;
        }
        phoneSo.payment = paymentState.values;
        dialogHelper.showLoading();
        const service = new PhoneSOService();
        service.update(wizard.data)
        .then(() => service.finish(wizard.data))
        .then(d => {
            Object.assign(wizard.data, d);
            dialogHelper.closeDialog();
            wizard.submit();
        })
        .catch(dialogHelper.showError);
    }


    return (
        <Stack flex={1}>
            <Stack flex={1} marginTop={1} padding={1}>
                <Paper>
                    <Stack spacing={1} padding={1}>
                        <Typography align="center">
                            PAGAMENTO
                        </Typography>
                        <PaymentInputs
                            defaults={phoneSo.payment}
                            totalValue={phoneSo.totalValue||0}
                            onChangeInputs={v => setPaymentState(v)}
                        />
                    </Stack>
                </Paper>
            </Stack>
            <WizardNav wizard={wizard} submit={submit} />
        </Stack>
    )
}