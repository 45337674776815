import styled from "@emotion/styled";

const SimpleDivider = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    "& > *": {
        flex: '1 250px',
    }
}));

export default SimpleDivider;