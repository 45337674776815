import { Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import ScreenPaper from "../../shared/components/screen_paper";
import useCRUDList from "../../shared/crud/use_crud_list";
import TransferService from "./service";
import List from "../../shared/components/list";
import { TransferStatus, transferTypeConfigs } from "./model";
import Checkbox from "../../shared/components/checkbox";
import { useEffect, useState } from "react";
import useAuth from "../../shared/hooks/use_auth";
import TransferMicroEdit from "./micro_edit";

export default function TransferList(props: any){
        const auth = useAuth();
        const hideShowAllButton = auth.user?.perms.transfer?.split(':')[1] === 'user';
		const list = useCRUDList({
			CRUDService: TransferService,
			props,
			columns: [
                { field: 'Id', headerName: 'Id', valueGetter: (p) => transferTypeConfigs[p.row.typeCode]?.idGetter(p.row) },
                { field: 'typeCode', headerName: 'Tipo', valueGetter: (p) => transferTypeConfigs[p.row.typeCode]?.label || p.row.typeCode },
                { field: 'status', headerName: 'Status', valueGetter: (p) => TransferStatus[p.row.status] },
                { field: 'verifiedBy.label', headerName: 'Verificado Por' },
                { field: 'verifiedAt', headerName: 'Verificado em', type: 'dateTime' },
                { field: 'sourceUser.label', headerName: 'Usuário Origem' },
                { field: 'sourceStore.label', headerName: 'Loja Origem' },
                { field: 'sourceZone.label', headerName: 'Zona Origem' },
                { field: 'targetUser.label', headerName: 'Usuário Destino' },
                { field: 'targetStore.label', headerName: 'Loja Destino' },
                { field: 'targetZone.label', headerName: 'Zona Destino' },
                {field: 'crtAt', headerName: 'Criado em', type: 'dateTime' },
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime' },
			],
            readOnly: true,
		});

        const [showAllStatus, setShowAllStatus] = useState(false);
        const [showAllTransfers, setShowAllTransfers] = useState(hideShowAllButton);
        const [showType, setShowType] = useState(null);
		const [filtered, setFiltered] = useState<any[]>([]);

		useEffect(() => {
            const uuid = auth.user?.user.uid;
			setFiltered(list.list.filter(t => (
                (showAllStatus || t.status === TransferStatus.ABERTO) &&
                (showAllTransfers || t.targetUser.uid === uuid || t.sourceUser.uid === uuid || t.verifiedBy?.uid === uuid) &&
                (!showType || t.typeCode === showType)
            )));
		}, [list.list, showAllStatus, showAllTransfers, showType]);

		return (
			<ScreenPaper flex1>
                {props.edit ? <TransferMicroEdit onSave={list.addItemToList} /> : undefined}
				<Typography>
					TRANSFERÊNCIAS
				</Typography>
                <Stack direction="row">
                    <Checkbox
                        label='Mostrar concluídos'
                        value={showAllStatus}
                        onChange={(e, v) => setShowAllStatus(v)}
                    />
                    {!hideShowAllButton &&
                    <Checkbox
                        label='Mostrar de todos usuários'
                        value={showAllTransfers}
                        onChange={(e, v) => setShowAllTransfers(v)}
                    />}
                </Stack>
                <Divider/>
                <Tabs value={showType} onChange={(e, v) => setShowType(v)}>
                    <Tab label="Todos" value={null}/>
                    {Object.values(transferTypeConfigs).map(t => (
                        <Tab label={t.label} value={t.code} key={t.code}/>
                    ))}
                </Tabs>
				<List
					{...list.listComponentProps}
                    rows={filtered}
					updateList={list.updateList}
					defaultSort={[{ field: 'crtAt', sort: 'desc' }]}
				/>
			</ScreenPaper>
		)
}