import { Stack, Button } from "@mui/material";
import Centered from "../../shared/components/centered";
import { EasyForm, EasyFormInput, EasyFormAction } from "../../shared/components/easy_form";
import VersionComponent from "../../shared/components/version_component";
import useLoginController from "./controller";

export default function Login(){

    const { login } = useLoginController();

    return (
        <Centered maxWidth="xs">
            <Stack padding={1} spacing={1}>
                <EasyForm 
                    onSubmit={login}
                    fields={{ 
                        "login": {
                            field: 'login',
                            type: 'text',
                            label: 'Login',
                        },
                        "password": {
                            field: 'password',
                            type: 'password',
                            label: 'Senha',
                            required: true
                        },
                        "keepConnected": {
                            field: "keepConnected",
                            type: "checkbox",
                            label: "Manter-me conectado"
                        }
                    }}
                >
                    <Stack spacing={1} padding={1}>
                        <EasyFormInput field="login" enableComplete/>
                        <EasyFormInput field="password" enableComplete/>
                        <EasyFormInput field="keepConnected" enableComplete/>
                        <EasyFormAction>
                            <Button type="submit">
                                ENTRAR
                            </Button>
                        </EasyFormAction>
                        <VersionComponent/>
                    </Stack>
                </EasyForm>
            </Stack>
        </Centered>
    )
}