import { Stack, Typography, Divider, Chip, Button, InputAdornment } from "@mui/material";
import ControlInfo from "../../shared/components/control_info";
import { EasyForm, EasyFormInput, EasyFormWriteOnly, EasyFormAction } from "../../shared/components/easy_form";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { GeneralConfigsFields, GeneralConfigsProps } from "./model";
import GeneralConfigsService from "./service";
import CreditTaxes from "./credit_taxes";
import { useEffect } from "react";


export default function GeneralConfigsEdit(props: any){
    const create = useCRUDEdit<GeneralConfigsProps, GeneralConfigsService>({
        props,
        fieldsGen: GeneralConfigsFields,
        CRUDService: GeneralConfigsService,
        id: 'main'
    });

    useEffect(() => {
        if(!create.data.taxes) create.data.taxes = {} as any;
        if(!create.data.taxes!.credit) create.data.taxes!.credit = [];
    }, [create.data])

    if(create.initializing){
        return <ScreenLoading/>
    }

    const percent = {
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">
                    %
                </InputAdornment>
            )
        }
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps} onSubmit={(d: GeneralConfigsProps, e: any) => {
                d.taxes!.credit = create.data.taxes!.credit;
                create.easyFormProps.onSubmit(d, e);
            }}>
                <Stack spacing={1}>
                    <Typography>
                        CONFIGURAÇÕES
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <Divider><Chip label="TAXAS"/></Divider>
                    <EasyFormInput field="taxes.money" {...percent}/>
                    <EasyFormInput field="taxes.debt" {...percent}/>
                    <EasyFormInput field="taxes.pix" {...percent}/>
                    <CreditTaxes generalConfigs={create.data}/>
                    <Divider><Chip label="TERMOS"/></Divider>
                    <EasyFormInput field="texts.terms.sell"/>
                    <EasyFormInput field="texts.terms.so_checkin"/>
                    <EasyFormInput field="texts.terms.so_checkout" maxRows={5}/>
                    <EasyFormWriteOnly>
                        <Divider><Chip label="AÇÕES"/></Divider>
                        <EasyFormAction>
                            <Button fullWidth color="success">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                    </EasyFormWriteOnly>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}