import { Route, Routes } from "react-router-dom";
import StoreList from "./list";
import StoreEdit from "./edit";
import { Store } from "@mui/icons-material";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";

export const routeConfig: RouteConfig = {
    key: 'store',
    label: 'Lojas',
    Icon: Store,
}

export default function StoreModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'store',
        routes: {
            create: {Component: StoreEdit},
            update: {Component: StoreEdit},
            view: {Component: StoreEdit},
            list: {Component: StoreList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}