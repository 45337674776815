import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import dialogHelper from "../../../shared/components/easy_dialog";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";
import PhoneSOGallery from "../phone_so_gallery";
import PhoneSOService from "../service";

export default function CheckoutPhotos(props: any){

    const [newPhotos, setNewPhotos] = useState<File[]>([]);
    const [service] = useState(() => new PhoneSOService());
    
    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
        nextCallback: async () => {
            const newPhotosList = Object.values(newPhotos);
            if(!newPhotosList.length && !Object.values(wizard.data.data.photos || {}).filter(item => item && item.tag === 'manutencao').length){
                throw new Error("Você não anexou nenhuma foto.");
            }
            wizard.data.data.status = 'CHECKOUT';
            dialogHelper.showLoading();
            const newData = await service.update(wizard.data);
            if(newPhotos.length){
                const newData2 = await service.sendPhotos(newData, newPhotos, 'manutencao');
                Object.assign(wizard.data.data, newData2.data);
                dialogHelper.closeDialog();
            }
        },
    });
    
    return (
        <Stack flex={1}>
            <ScreenPaper flex1>
                <Typography align="center">
                    ORDEM DE SERVIÇO
                </Typography>
                <Typography align="center">
                    Fotos
                </Typography>
                <Typography>
                    Faça o anexo das fotos do aparelho.
                </Typography>
                <PhoneSOGallery
                    tagFilter="manutencao"
                    photos={wizard.data.data.photos!}
                    onChangeNewPhotos={(files) => setNewPhotos(files)}
                />
            </ScreenPaper>
            <WizardNav wizard={wizard} />
        </Stack>
    )
}