import { useEffect, useMemo, useRef, useState } from "react";
import List, { ListColumns } from "../../shared/components/list";
import { LastOperationReason } from "../stock/model";
import Checkbox from "../../shared/components/checkbox";
import { CRUDItem } from "../../shared/crud/crud_service";
import { StockHistoryProps } from "./model";
import StockHistoryService from "./service";
import dialogHelper from "../../shared/components/easy_dialog";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { Receipt } from "@mui/icons-material";
import WarrantyPrint from "../print/warranty_print";
import useEffectState from "../../shared/hooks/use_effect_state";
import useIncrementState from "../../shared/hooks/use_increment_state";

export interface StockHistoryListProps{
    readOnly?: boolean;
    readOnlyCheck?: boolean;
    rows: CRUDItem<StockHistoryProps>[];
    onApprove?: (stockHistory: CRUDItem<StockHistoryProps>) => void;
    loading?: boolean;
}

export default function StockHistoryList(props: StockHistoryListProps){
    const service = useMemo(() => new StockHistoryService(), []);
    const [, _setLoadings] = useIncrementState({});
    const loadings = useRef({});

    const setLoadings = (uid: string, value: boolean) => {
        loadings.current[uid] = value;
        _setLoadings(loadings.current);
    }

    const check = (stc: CRUDItem<StockHistoryProps>, value: boolean) => {
        const uid = stc.uid!;
        setLoadings(uid, true);
        service.setApproval(stc, value)
        .then(newStc => {
            props.onApprove?.(newStc);
            setLoadings(uid, false);
        })
        .catch(err => {
            setLoadings(uid, false);
            dialogHelper.showError(err);
        });
    }


    const columns = useMemo(() => {
        const _columns: ListColumns = [
            {
                field: 'data.approved', headerName: 'Aprovado', type: 'boolean', minWidth: 30,
                renderCell: (params) => {
                    return loadings.current[params.row.uid] ? (
                        <CircularProgress color="primary" size={'1.5rem'}/>
                    ) : (
                        <Checkbox
                            value={params.value}
                            label={null}
                            key={params.row.uid + 'checkbox'}
                            onChange={(e,v) => check(params.row as any, v)}
                            disabled={props.readOnlyCheck}
                        />
                    )
                }
            },
            {
                field: '', headerName: 'Ações', type: 'actions', minWidth: 30,
                renderCell: ({row}: {row: CRUDItem<StockHistoryProps>}) => (
                    <Stack>
                        {row.data.lastOpReason === LastOperationReason.GARANTIA && (
                            <IconButton color="primary" onClick={() => WarrantyPrint(row)}>
                                <Receipt/>
                            </IconButton>
                        )}
                    </Stack>
                )
            },
            {field: 'lastOpReason', headerName: 'Motivo', valueGetter: (p) => LastOperationReason[p.row.data.lastOpReason]},
            {field: 'data.lastOpObs', headerName: 'Obs.'},
            {field: 'data.product.label', headerName: 'Produto'},
            {field: 'data.lastOpAmountDiff', headerName: 'Quantidade', type: "number"},
            {field: 'data.modifiedBy.label', headerName: 'Modificado Por'},
            {field: 'data.approvedBy.label', headerName: 'Aprovado Por'},
            {field: 'data.store.label', headerName: 'Loja'},
            {field: 'data.crtAt', headerName: 'Modificado em', type: 'dateTime'},
        ];
        if(props.readOnly) _columns.shift();
        return _columns;
    },[props.readOnly, props.readOnlyCheck]);

    return (
        <List
            rows={props.rows}
            defaultSort={[{ field: 'data.crtAt', sort: 'desc' }]}
            getRowId={(r) => r.uid}
            columns={columns}
            autoHeight
            loading={props.loading}
        />
    )
}