import { Sell } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import ProductEdit from "./edit";
import ProductList from "./list";

export default function ProductModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'product',
        routes: {
            create: {Component: ProductEdit},
            update: {Component: ProductEdit},
            view: {Component: ProductEdit},
            list: {Component: ProductList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

export const routeConfig: RouteConfig = {
    key: 'product',
    label: 'Produtos',
    Icon: Sell,
}