import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import parseBRL from "../../../shared/utils/parse_brl";
import ProductService from "../../product/service";
import StockService from "../../stock/service";
import StoreDBRef from "../../store/dbref";
import { Report } from "../model";

export interface StockReportValue{
    id: string;
    store: string;
    product: string;
    amount: number;
    cost: number;
    price: number;
    profit: number;
}

const StockReport: Report = {
    name: 'Controle de Estoque',
    checkPerm: async u => MinPerm(u.perms.report, 'w'),
    options: {
        store: {
            field: 'store',
            type: 'dbref',
            component: StoreDBRef,
            label: 'Loja',
        }
    },
    validateOptions: async o => o,
    columns: [
        {field: 'store', headerName: 'Loja'},
        {field: 'product', headerName: 'Produto'},
        {field: 'amount', headerName: 'Quantidade', type: 'number'},
        {field: 'cost', headerName: 'Custo', type: 'money'},
        {field: 'price', headerName: 'Preço', type: 'money'},
        {field: 'profit', headerName: 'Lucro', type: 'money'},
    ],
    defaultSort: [{field: 'profit', sort: 'desc'}],
    process: async o => {
        const values: StockReportValue[] = [];
        const stocks = await new StockService().list();
        const products = await new ProductService().list();
        for(let s of Object.values(stocks)){
            if(o.store && s.data.store.uid !== o.store.uid) continue;
            const amount = s.data.amount || 0;
            let cost = 0, price = 0, profit = 0;
            if(amount){
                const p = products[s.data.product.uid];
                if(p){
                    cost = amount * (p.data.cost_value || 0);
                    price = amount * (p.data.sell_value || 0);
                    profit = price - cost;
                }
            }
            values.push({
                id: s.uid!,
                store: s.data.store.label,
                amount,
                cost,
                price,
                profit,
                product: s.data.product.label,
            })
        }
        return values;
    },
    summaries: async (options: any, values: StockReportValue[]) => {
        let cost = 0, price = 0, profit = 0;
        values.forEach(p => {
            cost += p.cost;
            price += p.price;
            profit += p.profit;
        })
        return [
            [['Loja', options.store?.label || 'TODAS', 'colspan="3"']],
            [['Custo Total', parseBRL(cost)], ['Venda Total', parseBRL(price)], ['Potencial Lucro', parseBRL(profit)]]
        ]
    },
    print: async (options: any, values: StockReportValue[]) => {
        return {
            name: 'Relatório de Controle de Estoque',
            options: {},
            summaries: await StockReport.summaries(options, values),
            columns: [
                ...StockReport.columns as any,
            ],
            values,
        }
    }
}

export default StockReport;