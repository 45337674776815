import { Delete, Photo, ReceiptLong, Save } from "@mui/icons-material";
import { Button, Stack, Typography, Divider, Chip } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ControlInfo from "../../../shared/components/control_info";
import dialogHelper from "../../../shared/components/easy_dialog";
import { EasyForm, EasyFormInput, EasyFormWriteOnly, EasyFormAction } from "../../../shared/components/easy_form";
import FixedTextField from "../../../shared/components/fixed_text_field";
import Gallery from "../../../shared/components/gallery";
import ScreenLoading from "../../../shared/components/screen_loading";
import ScreenPaper from "../../../shared/components/screen_paper";
import SimpleDivider from "../../../shared/components/simple_divider";
import useCRUDEdit from "../../../shared/crud/use_crud_edit";
import useAuth from "../../../shared/hooks/use_auth";
import { paymentStringify } from "../../payment/model";
import SoCheckinPrint from "../../print/so_checkin_print";
import SoCheckoutPrint from "../../print/so_checkout_print";
import { PhoneSOProps, PhoneSOFields } from "../model";
import PhoneSOGallery from "../phone_so_gallery";
import PhoneSOService from "../service";
import SoPhotos from "./so_photos";
import CRUDEditContext from "../../../shared/crud/crud_edit_context";

const checktostr = (data: PhoneSOProps, mode: keyof PhoneSOProps) => {
    return Object.entries(data[mode] as any||{})
    .filter(([, checked]) => checked)
    .map(([lbl]) => lbl)
    .join(', ');
}

export default function PhoneSOEdit(props: any){
    const create = useCRUDEdit<PhoneSOProps, PhoneSOService>({
        props,
        fieldsGen: PhoneSOFields,
        CRUDService: PhoneSOService,
    });

    const auth = useAuth();
    const navigate = useNavigate();

    if(create.initializing){
        return <ScreenLoading/>
    }

    const navToSubProcess = (path: string) => {
        navigate(path, {state: {phoneSo: create.getCRUDItem()}})
    }

    const checkRole = (roles: string[]) => {
        return roles.concat('admin', 'generalmanager').includes(auth.user?.perms.system_role);
    }

    const printInputReceipt = () => SoCheckinPrint(create.uid!, create.data);
    const printOutputReceipt = () => SoCheckoutPrint(create.uid!, create.data);

    const statusButton = {
        'ABERTO': () => checkRole(['seller', 'storemanager']) && (
            <Fragment>
                <Button onClick={() => navToSubProcess('/phone_so/send/tech')}>
                    ENVIAR PARA TÉCNICO
                </Button>
                <Button color="error" onClick={() => navToSubProcess('/phone_so/cancel')}>
                    CANCELAR OS
                </Button>
            </Fragment>
        ),
        'MANUTENÇÃO': () => checkRole(['tech']) && (
            <Button onClick={() => navToSubProcess('/phone_so/checkout')}>
                FINALIZAR MANUTENÇÃO
            </Button>
        ),
        'CHECKOUT': () => checkRole(['tech']) && (
            <Button onClick={() => navToSubProcess('/phone_so/send/seller')}>
                ENVIAR PARA VENDEDOR
            </Button>
        ),
        'RETIRADA': () => checkRole(['seller', 'storemanager']) && (
            <Fragment>
            <Button onClick={() => navToSubProcess('/phone_so/finish')}>
                FINALIZAR OS
            </Button>
            <Button color="error" onClick={() => navToSubProcess('/phone_so/cancel')}>
                CANCELAR OS
            </Button>
        </Fragment>
        ),
        'CONCLUÍDO': () => checkRole(['seller', 'storemanager']) && (
            <Fragment>
            <Button color="error" onClick={() => navToSubProcess('/phone_so/cancel')}>
                CANCELAR OS
            </Button>
        </Fragment>
        ),
        'CANCELADO': () => undefined,
    }

    return (
        <CRUDEditContext.Provider value={create}>
        <ScreenPaper>
            <EasyForm {...create.easyFormProps}>
                <Stack spacing={1}>
                    <Typography>
                        Ordem de Serviço
                    </Typography>
                    
                    <ControlInfo {...create}/>
                    <FixedTextField value={create.uid} readOnly label="Cupom"/>
                    <EasyFormInput field="status"/>
                    
                    <Divider><Chip label="Loja"/></Divider>
                    <EasyFormInput field="store"/>
                    <EasyFormInput field="zone"/>
                    <EasyFormInput field="seller"/>

                    <Divider><Chip label="Cliente"/></Divider>
                    <EasyFormInput field="clientName"/>
                    <EasyFormInput field="clientPhone"/>
                    <EasyFormInput field="clientAddress"/>
                    <EasyFormInput field="clientId"/>

                    <Divider><Chip label="Dispositivo"/></Divider>
                    <EasyFormInput field="imei"/>
                    <EasyFormInput field="deviceModel"/>
                    <EasyFormInput field="deviceBrand"/>

                    <Divider><Chip label="Check-in"/></Divider>
                    <EasyFormInput field="dateIncome"/>
                    <EasyFormInput field="suggestedValue"/>
                    <EasyFormInput field="serviceObs"/>
                    <EasyFormInput field="sellerObs"/>
                    <FixedTextField value={checktostr(create.data, 'checkList')} readOnly label="Defeitos" multiline/>

                    <Divider><Chip label="Técnico"/></Divider>
                    <EasyFormInput field="serviceCompleted"/>
                    <EasyFormInput field="assignedTech"/>
                    <EasyFormInput field="assignedReceiver"/>
                    <EasyFormInput field="costValue"/>
                    <EasyFormInput field="problemsFound"/>
                    <EasyFormInput field="testsMade"/>
                    <EasyFormInput field="resolution"/>
                    <EasyFormInput field="techObs"/>
                    <FixedTextField value={checktostr(create.data, 'checkListTech')} readOnly label="Defeitos" multiline/>

                    <Divider><Chip label="Pagamento"/></Divider>
                    <EasyFormInput field="dateOutcome"/>
                    {create.data.cancelled ? 
                    <Fragment>
                        <EasyFormInput field="cancelled"/>
                        <EasyFormInput field="cancelReason"/>
                    </Fragment> : undefined}
                    <FixedTextField value={paymentStringify(create.data.payment, create.data.totalValue!)} label="Pagamento"/>
                    <EasyFormInput field="totalValue"/>
                    <EasyFormInput field="warranty"/>
                    <EasyFormInput field="otherObs"/>
                    <Divider><Chip label="AÇÕES"/></Divider>
                    <SimpleDivider>
                        {statusButton[create.data.status]?.()}
                        <EasyFormWriteOnly>
                            <EasyFormAction>
                                <Button fullWidth color="success" type="submit" startIcon={<Save/>}>
                                    SALVAR
                                </Button>
                            </EasyFormAction>
                            {create.uid ? 
                            <Button startIcon={<Delete/>} color="error" onClick={create.deleteAction}>
                                DELETAR
                            </Button>
                            : undefined}
                        </EasyFormWriteOnly>
                        <SoPhotos/>
                    </SimpleDivider>
                    <SimpleDivider>
                        <Button onClick={printInputReceipt} startIcon={<ReceiptLong/>}>
                            IMPRIMIR RECIBO DE ENTRADA
                        </Button>
                        <Button onClick={printOutputReceipt} startIcon={<ReceiptLong/>} color="secondary">
                            IMPRIMIR RECIBO DE SAÍDA
                        </Button>
                    </SimpleDivider>
                </Stack>
            </EasyForm>
        </ScreenPaper>
        </CRUDEditContext.Provider>
    )
}