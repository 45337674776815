import { Button, Stack, Typography } from '@mui/material';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import ProductService from './service';
import { useState } from 'react';
import dialogHelper from '../../shared/components/easy_dialog';
import MassChangeCostValue from './mass_change_cost';
import MinPerm from '../../shared/utils/min_perm';
import useAuth from '../../shared/hooks/use_auth';
import { Delete, SwapHoriz } from '@mui/icons-material';

export default function ProductList(props: any){
		const list = useCRUDList({
			CRUDService: ProductService,
			props,
			columns: [
				{field: 'name', headerName: 'Produto', 'type': 'multiline', minWidth: 250 },
				{field: 'cost_value', headerName: 'Custo', type: "money", minWidth: 140 },
				{field: 'sell_value', headerName: 'Venda', type: "money", minWidth: 140 },
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime'},
			]
		});
		const auth = useAuth();

		const [selected, setSelected] = useState<string[]>([]);

		const massChangeCostValue = () => {
			if(!selected.length) {
				dialogHelper.showError({message: 'Selecione ao menos um item!'});
				return;
			}
			const confirm = (values: any) => {
				dialogHelper.showLoading();
				new ProductService().massChangeValues(selected, values.cost_value, values.sell_value)
				.then(() => list.updateList())
				.then(() => dialogHelper.showDialog({
					title: 'Sucesso!',
					content: 'O valor dos itens selecionados foi atualizado com sucesso.',
					buttons: [dialogHelper.okButton()],
				}))
				.catch(dialogHelper.showError);
			}
			dialogHelper.showDialog({
				title: 'Alterar Valores',
				content: <MassChangeCostValue cancel={dialogHelper.closeDialog} onSubmit={confirm}/>
			})
		}

		const massDelete = () => {
			if(!selected.length) {
				dialogHelper.showError({message: 'Selecione ao menos um item!'});
				return;
			}
			dialogHelper.showConfirmation({
				callback: () => {
					dialogHelper.showLoading();
					new ProductService().massDelete(selected)
					.then(() => list.updateList())
					.then(() => dialogHelper.showDialog({
						title: 'Sucesso!',
						content: 'Os itens foram excluídos com sucesso.',
						buttons: [dialogHelper.okButton()],
					}))
					.catch(dialogHelper.showError);
				},
				content: `Tem certeza de que deseja excluir ${selected.length} itens? Esta ação não pode ser desfeita! Além disso ela também apagará todos os estoques relacionados à estes produtos.`
			})
		}

		return (
			<ScreenPaper flex1>
				<Typography>
					PRODUTOS
				</Typography>
				{MinPerm(auth.user?.perms.mass_mod, 'w') &&
				<Stack flexWrap="wrap" direction="row" gap="5px">
					<Button color="primary" onClick={massChangeCostValue} startIcon={<SwapHoriz/>}>
						Alterar Valores
					</Button>
					<Button color="error" onClick={massDelete} startIcon={<Delete/>}>
						Deletar Vários
					</Button>
				</Stack>}
				<List
					{...list.listComponentProps}
					updateList={list.updateList}
					defaultSort={[{ field: 'updAt', sort: 'desc' }]}	
					checkboxSelection
					onSelectionModelChange={(m,d) => setSelected(m as string[])}					
				/>
			</ScreenPaper>
		)
}