import { CheckCircleOutline, ReceiptLong } from "@mui/icons-material";
import { Button, Link, Stack, Typography } from "@mui/material";
import Centered from "../../../shared/components/centered";
import { useContext, useEffect } from "react";
import SellWizardContext from "../sell_wizard_context";
import { useNavigate } from "react-router-dom";
import SellPrint from "../../print/sell_print";


export default function Success(){
    const {sell} = useContext(SellWizardContext);

    const print = () => SellPrint(sell['_uid'], sell);

    return (
        <Centered>
            <Stack alignItems="center" spacing={1}>
                <CheckCircleOutline color="success" sx={{fontSize: '100px'}}/>
                <Typography color="success" variant="h4" align='center'>
                    Venda finalizada com sucesso!
                </Typography>
                <Typography>
                    O número de cupom da venda é: {sell['_uid']}
                </Typography>
                <Button startIcon={<ReceiptLong/>} onClick={print}>
                    IMPRIMIR
                </Button>
                <Button href={`/sell/edit/${sell['_uid']}`} color='secondary'>
                    Detalhes da venda
                </Button>
                <Button href="/">
                    Retornar ao menu principal
                </Button>
                
            </Stack>
        </Centered>
    )
}