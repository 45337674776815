import { useEffect, useState } from "react";

export default function useEffectState<T>(defaultValue: T | (() => T), deps: any[]){
    const state = useState<T>(defaultValue);
    const [value, setValue] = state;
    useEffect(() => {
        //@ts-ignore
        setValue(typeof defaultValue === 'function' ? defaultValue() : defaultValue);
    }, deps);

    return state;
}