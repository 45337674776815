import CRUDService from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { UserProps } from "./model";

export default class UserService extends CRUDService<UserProps>{
    constructor(){
        super('user');
    }

    changePassword(userId: string, newPassword: string){
        return fetcher(`user/change_password/${userId}`, "PATCH", {newPassword});
    }

    disableSwitchUser(userId: string){
        return fetcher(`user/disable_user/${userId}`, "PATCH")
        .then(this.parseMiddlewareItem);
    }
}

