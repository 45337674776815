import { SetStateAction, useMemo, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import GeneralConfigsService from "./service";

export default function CreditTimesInput(props: Partial<DBRefInputProps> & {payment: any, setPayment: SetStateAction<any>}){
    const service = useMemo(() => new GeneralConfigsService(), []);
    const [selected, setSelected] = useState(props.payment.creditX);
    return (
        <DBRefInput
            {...props}
            label="Divisão Crédito"
            options={() => service.getCached().then(g => {
                const v = g.data.taxes?.credit?.map(t => ({label: t.times.toString(), value: {label: t.times.toString(), uid: t.times}})) || [];
                return v;
            })}
            onChange={e => {
                setSelected(e.target.value);
                props.setPayment({creditX: e.target.value?.label});
            }}
            value={selected||null}
        />
    )
}