import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { MultiStockTransferResult } from "../stock/multi_stock_transfer";
import { StockHistoryProps } from "../stock_history/model";
import { WarrantyStockProps } from "./model";

export default class WarrantyStockService extends CRUDService<WarrantyStockProps>{
    constructor(){
        super('warranty_stock');
    }

    protected async _parseMiddlewareItem(resp: Response): Promise<CRUDItem<WarrantyStockProps>> {
        const parsed = await super._parseMiddlewareItem(resp);
        parsed.data._originalAmount = parsed.data.amount;
        return parsed;
    }

    update(item: CRUDItem<Partial<WarrantyStockProps>>): Promise<CRUDItem<WarrantyStockProps>> {
        item.data.lastOpAmountDiff = item.data.amount! - item.data._originalAmount!;
        if(!item.data.lastOpAmountDiff && item.data.lastOpAmountDiff !== 0){
            return Promise.reject(new Error('Quantidade inválida.'));
        }
        return super.update(item);
    }

    warrantyFromStock(config: MultiStockTransferResult): Promise<CRUDItem<StockHistoryProps>[]>{
        const data = config.stocks.map(s => ({
            productUid: s.stock.product.uid,
            amount: s.amount,
            storeUid: s.stock.store.uid,
            description: s.description,
        }))

        return fetcher(this.prefix + '/warrantyFromStock', 'PATCH', data)
        .then(r => r.json())
        .then(l => (l||[]).map(this.parseCRUDItem))
    }

    stockFromWarranty(config: MultiStockTransferResult): Promise<CRUDItem<StockHistoryProps>[]>{
        const data = config.stocks.map(s => ({
            productUid: s.stock.product.uid,
            amount: s.amount,
            storeUid: s.stock.store.uid,
        }))

        return fetcher(this.prefix + '/stockFromWarranty', 'PATCH', data)
        .then(r => r.json());
    }

    transferWarranty(config: MultiStockTransferResult): Promise<CRUDItem<StockHistoryProps>[]>{
        const data = config.stocks.map(s => ({
            productUid: s.stock.product.uid,
            amount: s.amount,
            storeUid: s.stock.store.uid,
        }))

        return fetcher(this.prefix + '/transferWarranty', 'PATCH', {storeUid: config.store?.uid, items: data})
        .then(r => r.json());
    }

    supplierFromWarranty(config: MultiStockTransferResult): Promise<CRUDItem<StockHistoryProps>[]>{
        const data = config.stocks.map(s => ({
            productUid: s.stock.product.uid,
            amount: s.amount,
            storeUid: s.stock.store.uid,
        }))

        return fetcher(this.prefix + '/supplierFromWarranty', 'PATCH', {storeUid: config.store?.uid, items: data, date: new Date().setHours(0,0,0,0), label: config.label})
        .then(r => r.json());
    }
}