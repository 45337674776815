import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DailySummaryHistoryService, DailySummaryService } from "./services";
import { CRUDItem, CRUDItemList } from "../../shared/crud/crud_service";
import { DailySummaryProps, DailySummaryStatus } from "./models";
import useAuth from "../../shared/hooks/use_auth";
import { DBRef } from "../../shared/models/model";
import { StoreProps } from "../store/model";
import dialogHelper from "../../shared/components/easy_dialog";
import { DBRefEasyFormSelectValues, DBRefsToSelectValues } from "../../shared/components/dbref_input";
import DateToString from "../../shared/utils/date_to_string";
import DailySummaryConclusion from "../print/daily_summary_conclusion";
import { CashRemittanceProps } from "../cash_remittance/model";
import CashRemittanceService from "../cash_remittance/service";

export interface CurrentDailySummary{
    isSingle: boolean;
    exists: boolean;
    isToday: boolean;
    canDoActions: boolean;
    ds?: CRUDItem<DailySummaryProps>;
    singleHash?: number;
}

export function hashDate(date: EpochTimeStamp | Date){
    return parseInt(DateToString(date, 'date').split('/').reverse().join(''));
}

function mergeSummaries(summaries: CRUDItem<DailySummaryProps>[]){
    const merged: CRUDItem<DailySummaryProps> = {
        data: {
            currentDate: null!,
            currentValues: {},
            openValues: {},
            status: null!,
            store: null!,
        }
    }

    const mergedData = merged.data.currentValues;

    summaries.forEach(s => {
        Object.entries(s.data.currentValues||{}).forEach(([key, value]) => {
            if(!value) return;
            if(!mergedData[key]) mergedData[key] = 0;
            mergedData[key] += value;
        })
    })

    return merged;
}

export function SummariesController(){
    const dsService = useMemo(() => new DailySummaryService(), []);
    const dshService = useMemo(() => new DailySummaryHistoryService(), []);
    const auth = useAuth();
    const [today, todayHash] = useMemo(() => [new Date(), hashDate(new Date())], []);
    const canChangeDate = useMemo(() => auth.user?.perms.dsh === 'r', []);
    const canChangeStore = useMemo(() => auth.user?.perms.daily_summary === 'w', []);
    
    const [dates, setDates] = useState({startDt: today, endDt: today});
    const { endDt, startDt } = dates;
    const [selectedStore, setSelectedStore] = useState<DBRef<StoreProps, 'name'> | null>(canChangeStore ? null : auth.user?.user.configs.store);
    const [dsList, setDSList] = useState<CRUDItem<DailySummaryProps>[]>([]);
    const [currentDS, setCurrentDS] = useState<CurrentDailySummary>({} as any);
    
    const dtHashes = useMemo(() => {
        const shash = hashDate(startDt);
        const ehash = hashDate(endDt);
        const isToday = shash === todayHash && ehash === todayHash;
        const isSingleDate = shash === ehash;
        return {isToday, isSingleDate, shash, ehash};
    }, [startDt, endDt]);

    const updateAll = () => {
        if(!dtHashes.shash || !dtHashes.ehash) return;
        const listDS = async () => {
            dialogHelper.showLoading();
            const proms: Promise<CRUDItemList<DailySummaryProps>>[] = [];
            if(dtHashes.ehash === todayHash) proms.push(dsService.list());
            if(dtHashes.shash !== todayHash) proms.push(dshService.list());
            const result = await Promise.all(proms)
            .then(results => results.flatMap((l) => Object.values(l)).filter(ds => {
                if(dtHashes.isToday) return true;
                const hash = hashDate(ds.data.currentDate);
                return hash >= dtHashes.shash && hash <= dtHashes.ehash;
            }))
            dialogHelper.closeDialog();
            return result;
        }
        listDS().then(setDSList)
        .catch(dialogHelper.showError);
    }
    
    useEffect(() => {
        updateAll();
    }, [dtHashes.shash, dtHashes.ehash]);
    
    useEffect(() => {
        let usedDSlist = dsList;
        let mainDS: CRUDItem<DailySummaryProps> | undefined;
        if(selectedStore){
            if(dtHashes.isSingleDate){
                mainDS = dsList.find(ds => ds.data.store.uid === selectedStore.uid);
            }
            else{
                usedDSlist = dsList.filter(ds => ds.data.store.uid === selectedStore.uid);
            }
        }
        setCurrentDS({
            exists: usedDSlist.length ? true : false,
            isSingle: mainDS ? true : false,
            isToday: dtHashes.isToday && (!mainDS || hashDate(mainDS.data.currentDate) === todayHash),
            canDoActions: dtHashes.isToday && mainDS ? true : false,
            ds: mainDS ? mainDS : mergeSummaries(usedDSlist),
            singleHash: mainDS ? hashDate(mainDS.data.currentDate!) : undefined,
        });
    }, [selectedStore?.uid, dsList]);

    const updateSingleDS = (ds: CRUDItem<DailySummaryProps>) => {
        const index = dsList.findIndex(d => d.uid === ds.uid);
        if(index >= 0){
            dsList[index] = ds;
            setDSList(dsList.concat());
        }
        if(currentDS.ds?.uid === ds.uid){
            setCurrentDS({
                ...currentDS,
                ds,
            })
        }
    }

    const updateDSByUid = (uid: string) => {
        dsService.read(uid)
        .then(updateSingleDS)
        .catch(console.log);
    }

    useEffect(() => {
        if(currentDS.ds?.uid){
            updateDSByUid(currentDS.ds?.uid);
        }
    }, [currentDS.ds?.uid]);

    const openDS = async () => {
        if(!currentDS.ds?.uid){
            dialogHelper.showError(new Error('Caixa inválido!'));
            return;
        }
        dialogHelper.showLoading();
        return await dsService.open(currentDS.ds!)
        .then(newds => {
            updateSingleDS(newds);
            dialogHelper.closeDialog();
        })
        .catch(err => {
            dialogHelper.showError(err);
            updateDSByUid(currentDS.ds?.uid!);
        });
    };

    const closeDS = async (send: boolean) => {
        if(!currentDS.ds?.uid){
            dialogHelper.showError(new Error('Caixa inválido!'));
            return;
        }
        dialogHelper.showLoading();
        return await dsService.close(currentDS.ds!, send)
        .then(newds => {
            updateSingleDS(newds);
            updateAll();
        })
        .catch(err => {
            dialogHelper.showError(err);
            updateDSByUid(currentDS.ds?.uid!);
        });
    };

    const afterSendRemittance = (item: CRUDItem<CashRemittanceProps>) => {
        updateDSByUid(item.data.store.uid);
    };

    const filterItem = (item: {date: any, storeUid: string}) => {
        const hash = hashDate(item.date);
        if(currentDS.isSingle){
            //Em caso de atraso e houver operações pendentes.
            if(!currentDS.isToday && dtHashes.isToday){
                return hash >= currentDS.singleHash! && hash <= dtHashes.ehash && item.storeUid === currentDS.ds?.data.store.uid;
            }
            else{
                return hash === currentDS.singleHash && item.storeUid === currentDS.ds?.data.store.uid;
            }
        }
        else{
            return hash >= dtHashes.shash && hash <= dtHashes.ehash && (!selectedStore || item.storeUid === selectedStore?.uid)
        }
    }
    const listsDeps = (resolved: boolean) => [dtHashes.shash, dtHashes.ehash, resolved, selectedStore?.uid, currentDS.ds?.data.store?.uid];
    
    const generateConclusionReport = async () => {
        try{
            dialogHelper.showLoading()
            if(currentDS.ds?.data.status !== DailySummaryStatus.CONCLUIDO){
                dialogHelper.showError(new Error('Caixa inválido!'));
                return;
            }
            let list = await new CashRemittanceService().list().then(s => Object.values(s))
            list = list.filter(i => filterItem({date: i.data.crtAt, storeUid: i.data.store.uid}))
                .filter(i => i.data.manual)
            ;
            DailySummaryConclusion(currentDS.ds, list);
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    };

    return {
        startDt, endDt, setDates,
        selectedStore, setSelectedStore,
        updateSingleDS, dsList,
        openDS, closeDS, generateConclusionReport,
        afterSendRemittance, dtHashes,
        canChangeDate, canChangeStore, currentDS,
        updateDSByUid, filterItem, listsDeps
    }
}

export type SummariesControllerData = ReturnType<typeof SummariesController>;

export const SummariesControllerContext = createContext<SummariesControllerData>(undefined!);
export const useSummariesController = () => useContext(SummariesControllerContext);