const DEFAULT_DIMENSIONS = {
    width: 1024,
    quality: 0.3,
}

export default class FileService{

    protected dimensions: {width: number, quality: number};

    constructor(dimensions = DEFAULT_DIMENSIONS){
        this.dimensions = dimensions;
    }
    captureFiles(modo: 'photo'|'images'|'files', multiplo?: boolean): Promise<Blob[]>{
        return new Promise((res,rej)=>{
            let ok = false;
            const fileinput = document.createElement('input');
            fileinput.type = "file";
            if(multiplo) fileinput.multiple = true;
            switch(modo){
                case "photo":
                    fileinput.accept = "image/*";
                    fileinput.capture = "camera";
                break;
                case "images":
                    fileinput.accept = "image/*";
                break;
                case 'files':
                break;
                default:
                    fileinput.accept = modo;
            }
            fileinput.onchange = () => { 
                ok = true;
                if (!fileinput.files) rej(new Error("Impossível carregar arquivos no seu navegador!"));
                else{
                    const list = [];
                    if(fileinput.accept){
                        const regexes = fileinput.accept
                        .split(",")
                        .map(item => new RegExp(item.trim()));
                        for(let arquivo of fileinput.files){
                            if(regexes.find(item=>item.test(arquivo.type))){
                                list.push(arquivo);
                            }
                        }
                    }
                    else for(let arquivo of fileinput.files) list.push(arquivo);
                    res(list);
                }
            }
            const listener = () => {
                setTimeout(() => {
                    if(fileinput.files?.length || 0 < 1) res([]);
                    document.body.removeEventListener('focus', listener);
                }, 600);
            }
            document.body.addEventListener('focus', listener);
            fileinput.click();
        })
    }

    async zipImage(blob: Blob){
        const img: HTMLImageElement = await new Promise((r)=>{
            const image = new Image();
            image.onload = () => r(image);
            image.src = URL.createObjectURL(blob);
        });

        const canvas = document.createElement("canvas");

        if(img.width > this.dimensions.width || img.height > this.dimensions.width){
            if(img.width > img.height){
                canvas.width = this.dimensions.width;
                canvas.height = this.dimensions.width * img.height / img.width;
            } 
            else{
                canvas.width = this.dimensions.width * img.width / img.height;
                canvas.height = this.dimensions.width;
            }
        } 
        else{
            canvas.width = img.width;
            canvas.height = img.height;
        }
        
        canvas.getContext('2d')?.drawImage(img, 0, 0, img.width, img.height,0, 0, canvas.width, canvas.height);
        const novoBlob = await new Promise((r)=>canvas.toBlob(r, 'image/jpeg', this.dimensions.quality));
        return novoBlob as Blob;
    }

    generateFormData(files: File[], label: string){
        const formData = new FormData();

        files.forEach(file => formData.append(label, file, file.name));

        return formData;
    }
}