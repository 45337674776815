import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Checkbox from '../../shared/components/checkbox';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import UserService from './service';
import { UserProps } from './model';


export default function UserList(props: any){
		const list = useCRUDList({
			CRUDService: UserService,
			props,
			columns: [
				{field: 'name', headerName: 'Nome', },
				{field: 'group.label', headerName: 'Grupo', },
				{field: 'configs.store.label', headerName: 'Loja'},
				{field: 'disabled', headerName: 'Desabilitado', type: 'boolean'},
				
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime'},
			]
		});

		const [showAll, setShowAll] = useState(false);
		const [filtered, setFiltered] = useState<any[]>([]);

		useEffect(() => {
			setFiltered(list.list.filter(user => !user.disabled));
		}, [list.list]);

		return (
			<ScreenPaper flex1>
				<Typography>
					USUÁRIOS
				</Typography>
				<Checkbox
					label='Mostrar desabilitados'
					value={showAll}
					onChange={(e, v) => setShowAll(v)}
				/>
				<List
					{...list.listComponentProps}
					rows={showAll ? list.list : filtered}
					updateList={list.updateList}
					defaultSort={[{ field: 'updAt', sort: 'desc' }]}
				/>
			</ScreenPaper>
		)
}