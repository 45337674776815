import { useEffect, useState, useMemo } from "react";
import dialogHelper from "../../components/easy_dialog";
import { ListProps } from "../../components/list";
import CRUDService, { CRUDItem, CRUDServiceSignature } from "../crud_service";
import { goFieldColumn } from "./go_field";
import SafeList from "../../utils/safe_list";

type Columns = ListProps['columns'];

export interface useCRUDListProps<T>{
    props: any;
    CRUDService: CRUDServiceSignature<T>;
    columns: Columns;
    hasntView?: boolean;
    readOnly?: boolean;
}
export interface WithID{
    id: string;
}

export default function useCRUDList<T>(props: useCRUDListProps<T>){
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(true);
    const [crudService] = useState<CRUDService<T>>(() => new props.CRUDService());
    const [list, setList] = useState<(T & WithID)[]>([]);
    
    const columns: Columns = useMemo<any>(() => SafeList([!props.hasntView && goFieldColumn, ...props.columns]), [props.columns, props.hasntView]);
    const addButtonProps = props.readOnly || props.props.readOnly ? undefined : {href: 'create'};
    const disabled = loading || initializing;

    const parseItem = (item: CRUDItem<T>): (T & WithID) => ({...item.data, id: item.uid!});

    const parseList = (serviceList: {[uid: string]: CRUDItem<T>}): any => {
        return Object.values(serviceList).map(parseItem);
    }

    const updateList = (service = crudService) => {
        setLoading(true);
        return service?.list()
        .then(result => {
            setList(parseList(result));
            setLoading(false);
        })
    }

    const addItemToList = (newItem: CRUDItem<T>) => {
        const converted = parseItem(newItem);
        const oldI = list.findIndex(item => item.id === converted.id);
        const _list = list.concat();
        if(oldI >= 0){
            _list[oldI] = converted;
        }
        else{
            _list.push(converted)
        }
        setList(_list);
    }

    useEffect(() => {
        Promise.all([
            updateList(crudService)
        ])
        .then(() => {
            setInitializing(false);
        })
        .catch(dialogHelper.showError);
    }, []);

    



    const listComponentProps: ListProps = {
        columns,
        loading: disabled,
        addProps: addButtonProps,
        rows: list,
    }

    return {
        disabled,
        loading,
        crudService,
        initializing,
        list,
        setLoading,
        setList,
        addButtonProps,
        listComponentProps,
        addItemToList,
        updateList,
    }
}