import { forwardRef, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import UserService from "./service";


const UserDBRef = forwardRef(function _UserDBRef(props: Omit<DBRefInputProps, 'options'>, ref){
    const [service] = useState(() => new UserService());

    return (
        <DBRefInput
            {...props}
            options={() => service.DBRefList(props.itemLabel || 'name')
                        .then(items => items.filter(i => !i.value?._ref?.data?.disabled))
            }
            ref={ref}
        />
    )
});

export default UserDBRef;