import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Scroll from "./shared/components/scroll";
import Dialog from "./shared/components/easy_dialog/dialog";
import { useEffect, useMemo } from "react";
import projectId from "./shared/utils/project_id";
import redirectToProject from "./shared/utils/redirect_to_project";
import RootRoute from "./routes/root_route";
import SagittariusSplash from "./modules/sagittarius_splash";
import defaultTheme, { defaultThemeOptions } from "./shared/stores/theme";
import { useProject } from "./shared/stores/project_slice";
import { ptBR } from "@mui/material/locale";
import { ptBR as dgptBR} from "@mui/x-data-grid";

export default function App(){
    const { project } = useProject();
    useEffect(() => {
        if(!projectId) redirectToProject();
    }, []);
    const theme = useMemo(() => createTheme(project?.ui ? {
        ...defaultThemeOptions,
        palette: {
            ...defaultThemeOptions.palette,
            //@ts-ignore
            primary: {main: project?.ui.primary_color},
            mode: project?.ui.mode,
        }
    } : {}, ptBR, dgptBR), [project?.ui]);

    useEffect(() => {
        if(project?.ui?.logo_url) document.getElementById('link_icon')?.setAttribute('href', project?.ui?.logo_url);
        if(project?.ui?.public_name) document.title = project?.ui.public_name;
    }, [project?.ui]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <ThemeProvider theme={theme}>
                <Scroll/>
                <Dialog/>
                <CssBaseline/>
                {projectId ? <RootRoute /> : <SagittariusSplash/>}
            </ThemeProvider>
        </ThemeProvider>
    )
}