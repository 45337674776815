import dialogHelper from "../../shared/components/easy_dialog";
import { CRUDItem, CRUDItemList } from "../../shared/crud/crud_service";
import StockHistoryList from "./list";
import { StockHistoryProps } from "./model";
import StockHistoryService from "./service";

export default async function ShowHistoryList(config: {
    strList?: string[],
    historyList?: CRUDItem<StockHistoryProps>[],
}){
    try{
        dialogHelper.showLoading();
        const service = new StockHistoryService();
        const histories = config.historyList ? config.historyList : (await service.getByList(config.strList||[]));
        dialogHelper.showDialog({
            title: 'Histórico',
            content: (
                <StockHistoryList
                    rows={histories}
                    readOnly
                />
            ),
            buttons: [dialogHelper.okButton({content: 'FECHAR'})]
        })
    }
    catch(err:any){
        dialogHelper.showError(err);
    }
}