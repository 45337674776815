import { Stack, Paper, Typography, Divider, Chip } from "@mui/material";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import FixedTextField from "../../../shared/components/fixed_text_field";
import List from "../../../shared/components/list";
import { paymentStringify } from "../../payment/model";
import SellBottomNavigation, { SellBottomButtonPrevirous, SellBottomButtonNext } from "../sell_bottom_navigation";
import SummaryController from "./controller";

export default function Summary(props: {name: string}){

    const controller = SummaryController();

    return(
        <Stack flex={1}>
            <Stack flex={1} marginTop={1} padding={1}>
                <Paper>
                    <EasyForm onSubmit={() => {}} fields={controller.fields} readOnly>
                        <Stack spacing={1} padding={1}>
                            <Typography align='center'>
                                RESUMO
                            </Typography>
                            <EasyFormInput field="clientName"/>
                            <EasyFormInput field="clientPhone"/>
                            <EasyFormInput field="warranty"/>
                            <EasyFormInput field="description"/>
                            <EasyFormInput field="totalValue"/>
                            <FixedTextField value={paymentStringify(controller.sell.payment, controller.sell.totalValue!)} label="Pagamento"/>
                            <Divider><Chip label="PRODUTOS"/></Divider>
                            <List
                                autoHeight
                                rows={Object.values(controller.sell.items)}
                                columns={[
                                    {field: 'product.label', headerName: 'Produto'},
                                    {field: 'amount', headerName: 'Quantidade', type: 'number'},
                                    {field: 'sellValue', headerName: 'Preço Un.', type: 'money'},
                                    {field: 'total', headerName: 'Total', type: 'money', valueGetter: ({row}) => row.amount * row.sellValue},
                                    {field: 'gift', headerName: 'Brinde', type: 'boolean'},
                                ]}
                                getRowId={(r) => r.product.uid}
                            />
                        </Stack>
                    </EasyForm>
                </Paper>
            </Stack>
            <SellBottomNavigation>
                <SellBottomButtonPrevirous/>
                <SellBottomButtonNext name={props.name} beforeRun={controller.submit}/>
            </SellBottomNavigation>
        </Stack>
    )
}