import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import ProductDBRef from "../product/dbref";
import { ProductProps } from "../product/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import { ZoneProps } from "../zone/model";

export enum LastOperationReason{
    'CADASTRO' = 1,
    'VENDA' = 2,
    'VENDA EXTERNA' = 3,
    'BAIXA' = 4,
    'GARANTIA' = 5,
    'REPOSIÇÃO' = 6,
    'TRANSFERÊNCIA' = 7,
    'CONSUMO' = 8,
    'DEVOLUÇÃO' = 9,
    'EXCLUSÃO' = 10,
    'OUTROS' = 90
}

export interface StockProps extends DatedProps{
    store: DBRef<StoreProps, 'name'>;
    product: DBRef<ProductProps, 'name'>;
    zone: DBRef<ZoneProps, 'name'>;
    amount: number;
    lastOpAmountDiff: number;
    lastOpReason: LastOperationReason;
    _originalAmount: number;
    lastOpObs?: string;
}

export const StockFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        store: {
            field: "store",
            type: "dbref",
            label: "Loja",
            component: StoreDBRef,
            required: true,
        },
        product: {
            field: "product",
            type: "dbref",
            label: "Produto",
            component: ProductDBRef,
            required: true,
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade",
            required: true,
        },
        lastOpReason: {
            label: "Motivo da Alteração",
            type: 'select',
            field: "lastOpReason",
            options: Object.entries(LastOperationReason).filter(([label]) => isNaN(parseInt(label))).map(([label, value]) => ({label, value})),
            defaultValue: null,
        },
        lastOpObs: {
            field: 'lastOpObs',
            type: 'multiline',
            label: 'Observações'
        }

    }, options)
}