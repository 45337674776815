import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import { SwapHoriz } from "@mui/icons-material";
import TransferList from "./list";

export const routeConfig: RouteConfig = {
    key: 'transfer',
    label: 'Envios',
    Icon: SwapHoriz,
    buttonProps: {
        color: 'info'
    }
}

export default function ZoneModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'transfer',
        routes: {
            view: {Component: TransferList, ComponentProps: {edit: true}, expectedPerm: ['s', 'w']},
            list: {Component: TransferList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}