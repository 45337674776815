import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Checkbox from '../../shared/components/checkbox';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import { CRUDItem } from '../../shared/crud/crud_service';
import useCRUDList, { WithID } from '../../shared/crud/use_crud_list';
import useAuth from '../../shared/hooks/use_auth';
import { PhoneSOProps } from './model';
import PhoneSOService from './service';

const onlyOpenedRetrieve = ['ABERTO', 'RETIRADA'];
const onlyOpened = ['ABERTO', 'MANUTENÇÃO', 'CHECKOUT', 'RETIRADA'];
const onlyTech = ['MANUTENÇÃO', 'CHECKOUT'];

const filters = {
	onlyOpenedRetrieve: (list: (PhoneSOProps & WithID)[]) => list.filter(item => onlyOpenedRetrieve.includes(item.status)),
	onlyOpened: (list: (PhoneSOProps & WithID)[]) => list.filter(item => onlyOpened.includes(item.status)),
	onlyTech: (list: (PhoneSOProps & WithID)[]) => list.filter(item => onlyTech.includes(item.status))
}

const filterMode = {
	tech: filters.onlyTech,
	seller: filters.onlyOpenedRetrieve,
	storemanager: filters.onlyOpened,
	generalmanager: filters.onlyOpened,
	admin: filters.onlyOpened,
}

export default function PhoneSOList(props: any){
		const list = useCRUDList({
			CRUDService: PhoneSOService,
			props,
			columns: [
				{field: 'id', headerName: 'ID', },
				{field: 'status', headerName: 'Status', },
				{field: 'clientName', headerName: 'Cliente', },
				{field: 'clientId', headerName: 'CPF', },
				{field: 'seller.label', headerName: 'Vendedor', },
				{field: 'store.label', headerName: 'Loja', },
				{field: 'assignedTech.label', headerName: 'Técnico', },
				{field: 'suggestedValue', headerName: 'Valor Inicial', type: "money" },
				{field: 'totalValue', headerName: 'Valor Final', type: "money" },
				{field: 'cancelled', headerName: 'Cancelado', type: "boolean" },
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime'},
			]
		});

		const [filtered, setFiltered] = useState([]);
		const [showAll, setShowAll] = useState(false);
		const auth = useAuth();

		useEffect(() => {
			setFiltered(filterMode[auth.user?.perms.system_role](list.list));
		}, [list.list]);

		return (
			<ScreenPaper flex1>
				<Typography>
					Ordens de Serviço
				</Typography>
				<Checkbox
					label='Mostrar todas O.S.'
					value={showAll}
					onChange={(e, v) => setShowAll(v)}
				/>
				<List
					{...list.listComponentProps}
					rows={showAll ? list.list : filtered}
					updateList={list.updateList}
					defaultSort={[{ field: 'updAt', sort: 'desc' }]}						
				/>
			</ScreenPaper>
		)
}