import { Typography } from "@mui/material"
import DateToString from "../../utils/date_to_string";

const LABELS: {[key: string]: string} = {
    uid: 'ID',
    crtAt: 'criado',
    updAt: 'atualizado',
}

export default function ControlInfo(props: {uid?: string, data?: any}){
    const info = Object.entries({
        uid: props.uid,
        crtAt: DateToString(props.data?.crtAt),
        updAt: DateToString(props.data?.updAt),

    })
    .filter(([, value]) => value);

    return (
        <Typography variant="caption">
            {info.map(([key, value]) => `${LABELS[key]}: ${value}`).join(' | ')}
        </Typography>    
    )
}