import { Business, Diversity3, Face } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import ClientEdit from "./edit";
import ClientList from "./list";
import getDomain from "../../shared/utils/get_domain";

export const routeConfig: RouteConfig = {
    key: 'client',
    label: 'Clientes',
    Icon: Face,
    active: () => getDomain('client')
}

export default function ClientModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'client',
        routes: {
            create: {Component: ClientEdit},
            update: {Component: ClientEdit},
            view: {Component: ClientEdit},
            list: {Component: ClientList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}