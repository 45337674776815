import { useState, useEffect } from 'react';
import dialogHelper from '../../components/easy_dialog';

export interface PromisedState<T>{
    value: T | undefined;
    resolved: boolean;
}

type PromisedStateValueDefiner<T> = (() => Promise<T>) | Promise<T>;

export interface PromisedStateProps<T>{
    initialState?: () => Promise<T>;
    catch?: (err: unknown) => void;
}

export default function usePromisedState<T>(props: PromisedStateProps<T>): [
    state: PromisedState<T>, 
    setState: (promise: PromisedStateValueDefiner<T>) => void,
    setValue: (promise: PromisedState<T>) => void
] {
    const [state, _setState] = useState<PromisedState<T>>({resolved: false, value: undefined});

    const setState = (promise: PromisedStateValueDefiner<T>) => {
        _setState({resolved: false, value: undefined});
        if(typeof promise === 'function'){
            promise = promise();
        }
        promise.then((val) => {
            _setState({resolved: true, value: val});
        })
        .catch(props.catch ?? dialogHelper.showError);
    }

    useEffect(() => {
        if(props.initialState) setState(props.initialState);
    }, []);

    return [state, setState, _setState];
}