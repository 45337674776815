export default function DocumentTemplatePrinter(templatePath: string, substitutions: {[key: string]: string}){
    return new Promise<void>((resolve,reject) => {
        fetch(`${process.env.PUBLIC_URL}/print_models/${templatePath}`)
        .then(v => v.text())
        .then(txt => {
            try{
                for(let [key, value] of Object.entries(substitutions)){
                    value = value == undefined ? '' : value;
                    txt = txt.replace(new RegExp(`{{${key}}}`, 'g'), value);
                }
                const iframe = document.createElement('iframe');
                iframe.onload = async () => {
                    iframe.contentWindow?.print();
                    resolve();
                }
                iframe.srcdoc = txt;
                iframe.style.display = 'none';
                const printframes = document.getElementById('printframes')!;
                printframes.children[0] ? printframes.replaceChild(iframe, printframes.children[0]) : printframes.appendChild(iframe);
            }
            catch(err){
                reject(err);
            }
        })
        .catch(reject);
    });
}