import { Close, Photo, Save } from "@mui/icons-material";
import { AppBar, Button, Dialog, DialogContent, Divider, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import dialogHelper from "../../../shared/components/easy_dialog";
import useCRUDEditContext from "../../../shared/crud/use_crud_edit_context";
import useEffectState from "../../../shared/hooks/use_effect_state";
import useIncrementState from "../../../shared/hooks/use_increment_state";
import { PhoneSOProps } from "../model";
import PhoneSOGallery from "../phone_so_gallery";
import PhoneSOService from "../service";

export default function SoPhotos(){
    const create = useCRUDEditContext<PhoneSOProps, PhoneSOService>();
    const [newPhotos, setNewPhotos] = useState<{[tag: string]: File[]}>({});
    const [open, setOpen] = useState(false);
    const [photos, setPhotos] = useEffectState(() => Object.assign({}, create.data.photos), [create.data.photos, open]);
    const close = () => setOpen(false);
    
    const save = async () => {
        
        dialogHelper.showLoading();
        try{
            const newItem = await create.crudService.update({uid: create.uid, data: {photos}});
            const obj = newItem.data.photos||{};
            for(let key in obj){
                if(!obj[key]) delete obj[key];
            }

            for(let [tag, files] of Object.entries(newPhotos)){
                if(files.length){
                    const newItem2 = await create.crudService.sendPhotos(newItem, files, tag as any);
                    Object.assign(newItem.data, newItem2.data);
                }
            }
            setNewPhotos({});
            setPhotos({...create.data.photos})
            create.setData(newItem.data);
            close();
            dialogHelper.closeDialog();
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    }

    const photoListTag = [
        { tag: "checkin", label: 'CHECK-IN' },
        { tag: "manutencao", label: 'MANUTENÇÃO' }
    ]
    
    return (
        <Fragment>
            <Button onClick={() => setOpen(true)} startIcon={<Photo/>}>
                Ver Fotos
            </Button>
            <Dialog open={open} onClose={close} fullScreen>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={close}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Fotos
                        </Typography>
                        <Button startIcon={<Save/>} color="success" onClick={save}>
                            SALVAR
                        </Button>
                    </Toolbar>
                </AppBar>
                    <DialogContent>
                        <Stack flex={1}>
                            {photoListTag.map(i => (
                                <Fragment>
                                    <Divider>
                                        <Typography color="primary">
                                            {i.label}
                                        </Typography>
                                    </Divider>
                                    <PhoneSOGallery
                                        tagFilter={i.tag}
                                        photos={photos!}
                                        onChangeNewPhotos={(files) => setNewPhotos((_p) => ({..._p, [i.tag]: files}))}
                                    />
                                </Fragment>
                            ))}
                        </Stack>
                    </DialogContent>
            </Dialog>
        </Fragment>
    )
}