import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { useState } from "react"
import dialogHelper from "../../../shared/components/easy_dialog";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import ModelFieldsGen from "../../../shared/utils/model_fields_gen"
import { checkListItems, PhoneSOProps } from "../model";

const CheckBoxContainer = styled.div(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
        flex: '1 350px',
    },
    alignItems: 'center',
    justifyContent: 'center',
}));

export default function SimpleChecklist(props: any){

    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
        nextCallback: async () => undefined,
    }); 

    const [fields] = useState(() => {
        const fields = ModelFieldsGen(Object.assign({}, ...checkListItems.map(key => ({
            [key]: {
                field: key,
                type: 'checkbox',
                label: key,
            }
        }))), {defaults: wizard.data.data.checkList});

        return fields;
    })

    const submit = (data: any) => {
        if(!Object.values(data).filter(v => v).length){
            dialogHelper.showError({message: 'Marque ao menos uma opção!'});
        }
        else{
            for(let key in data){
                data[key] = Boolean(data[key]);
            }
            wizard.data.data.checkList = data;
            wizard.submit();
        }
    }

    return (
        <Stack flex={1}>
            <EasyForm onSubmit={submit} fields={fields} flex1>
                <ScreenPaper flex1>
                    <Typography align="center">
                        CHECK LIST DE ENTRADA
                    </Typography>
                    <Typography align="center">
                        Marque as peças com defeito
                    </Typography>
                    <CheckBoxContainer>
                        {checkListItems.map((key) => (
                            <div>
                                <EasyFormInput key={key} field={key}/>
                            </div>
                        ))}
                    </CheckBoxContainer>
                </ScreenPaper>
                <WizardNav wizard={wizard} useFormSubmit/>
            </EasyForm>
        </Stack>
    )
}