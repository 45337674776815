import { Close, Delete } from "@mui/icons-material";
import { AppBar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import dialogHelper from "../../shared/components/easy_dialog";
import { EasyForm, EasyFormAction, EasyFormInput, EasyFormWriteOnly } from "../../shared/components/easy_form";
import useCRUDEdit, { UseCRUDEditComponentProps } from "../../shared/crud/use_crud_edit";
import { SupplierStockFields, SupplierStockProps } from "./model";
import SupplierStockService from "./service";
import Stack from '@mui/material/Stack';
import useAuth from "../../shared/hooks/use_auth";

export default function SupplierStockMicroEdit(props: UseCRUDEditComponentProps<SupplierStockProps>){
    const create = useCRUDEdit({
        props: props,
        fieldsGen: SupplierStockFields,
        CRUDService: SupplierStockService,
        goBackOnSave: true,
        beforeSave: (old, newobj) => newobj._originalAmount = old._originalAmount,
    });
    const auth = useAuth();

    const exists = Boolean(create.uid);

    if(create.initializing){
        return (
            <Dialog open>
                <DialogContent>
                    <CircularProgress color="primary"/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog open onClose={create.gotoIndex} fullScreen>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={create.gotoIndex}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Estoque de Fornecedor
                    </Typography>
                </Toolbar>
            </AppBar>
            <EasyForm {...create.easyFormProps}>
                <DialogContent>
                    <Stack spacing={1}>
                        <EasyFormInput field="store" readOnly={exists} onChange={(e, f) => {
                            const zoneRef = e?.target.value?._ref?.data?.zone || null;
                            f?.formConfig.setValue('zone', zoneRef);
                        }}/>
                        <EasyFormInput field="product" readOnly={exists}/>
                        <EasyFormInput field="amount"/>
                        <EasyFormInput field="date"/>
                        <EasyFormInput field="label"/>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <EasyFormWriteOnly>
                        {auth.user?.perms.supplier_stock === 's' && create.uid &&
                        <Button color="error" onClick={create.deleteAction} fullWidth>
                            REMOVER
                        </Button>}
                        <EasyFormAction>
                            <Button fullWidth color="success" type="submit">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                    </EasyFormWriteOnly>
                </DialogActions>
            </EasyForm>
        </Dialog>
    )
}