import { Typography } from '@mui/material';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import ClientService from './service';

export default function ClientList(props: any){
		const list = useCRUDList({
			CRUDService: ClientService,
			props,
			columns: [
				{field: 'name', headerName: 'Nome', },
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
				{field: 'updAt', headerName: 'Atualizado em', type: 'dateTime'},
			]
		});

		return (
			<ScreenPaper flex1>
				<Typography>
					CLIENTES
				</Typography>
				<List
					{...list.listComponentProps}
					updateList={list.updateList}
					defaultSort={[{ field: 'crtAt', sort: 'desc' }]}
				/>
			</ScreenPaper>
		)
}