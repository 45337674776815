import { Inventory, Sell } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import StockList from "./list";

export default function StockModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'warranty_stock',
        routes: {
            create: {Component: StockList, ComponentProps: {edit: true}},
            update: {Component: StockList, ComponentProps: {edit: true}},
            view: {Component: StockList, ComponentProps: {edit: true, readOnly: true}},
            list: {Component: StockList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

export const routeConfig: RouteConfig = {
    key: 'warranty_stock',
    label: 'Garantia',
    Icon: Inventory,
}