import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";
import { useState, forwardRef } from "react";
import FixedTextField, { FixedTextFieldProps } from "../fixed_text_field";
  
const PasswordTextField = forwardRef((props: FixedTextFieldProps, ref:any) => {
    const [show, setShow] = useState(false);
    return (
        <FixedTextField 
            InputProps={{ 
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={() => setShow(!show)} disabled={props.disabled}>
                        {show ?  <VisibilityOff/> : <Visibility/>}
                    </IconButton>
                </InputAdornment>
                )
            }}
            {...props}
            ref={ref}
            type={show ? 'text' : 'password'}
        />
    )
});

export default PasswordTextField;