import { Search } from "@mui/icons-material";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { forwardRef, useContext, useState } from "react";
import EasyFormContext from "../../shared/components/easy_form/easy_form_context";
import MaskedTextField, { MaskedTextFieldProps } from "../../shared/components/masked_text_field";

const URL = "https://viacep.com.br/ws/";

interface ApiReturn{
    cep: string;
    uf: string;
    localidade: string;
    bairro: string;
    logradouro: string;
}

export interface ZipInputProps extends MaskedTextFieldProps{
    updateAddress: () => void;
}

const convertAPIReturn = (prefix: string, result: ApiReturn) => {
    return {
        [`${prefix}.street`]: result.logradouro,
        [`${prefix}.city`]: result.localidade,
        [`${prefix}.neighborhood`]: result.bairro,
        [`${prefix}.state`]: result.uf,
        [`${prefix}.zip`]: result.cep,
        [`${prefix}.country`]: "BR",
    }
}

const ZipInput = forwardRef(function _ZipInput(props: ZipInputProps, ref: any){

    const context = useContext(EasyFormContext);

    const [warning, setWarning] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const zipSearch = () => {
        const zip = context.formConfig.getValues(props.name!);
        if(zip?.length !== 9){
            setWarning('Preencha o CEP corretamente.');
        }
        else{
            setWarning(null);
            let prefixItens = props.name?.split(".");
            prefixItens?.pop();
            const prefix = prefixItens?.join('.')!;

            setLoading(true);
            fetch(`${URL}/${zip}/json`)
            .then(async resp => {
                if(resp.status !== 200){
                    setWarning('Ocorreu um erro ao consultar o CEP.');
                }
                else{
                    const result = await resp.json();
                    const converted = convertAPIReturn(prefix, result);
                    for(let key in converted){
                        context.formConfig.setValue(key, converted[key]);
                    }
                    props.updateAddress();
                }
            })
            .catch(err => {
                console.log(err);
                setWarning(err.message || 'Erro ao consultar CEP.');
            })
            .finally(() => setLoading(false));
        }
    }

    return (
        <MaskedTextField
            {...props}
            error={props.error || Boolean(warning)}
            helperText={props.helperText || warning}
            mask="00000-000"
            onBlur={(e) => {
                if(warning) setWarning(null);
                props.onBlur?.(e);
            }}
            onChange={(e) => {
                props.onChange?.(e);
            }}
            InputProps={{ 
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        {!loading ?
                        <IconButton color={props.disabled ? 'inherit' : 'primary'} onClick={zipSearch}>
                            <Search/>
                        </IconButton> :
                        <CircularProgress size={20}/>}
                    </InputAdornment>
                )
            }}
            ref={ref}
        />
    )
});

export default ZipInput;