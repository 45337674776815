import { ArrowBack, Error } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import ScreenPaper from "../screen_paper";

export interface ScreenErrorProps{
    error: Error;
    onDismiss?: () => void;
}

export default function ScreenError(props: ScreenErrorProps){
    return (
        <ScreenPaper spacing={1}>

            <Stack direction="row" alignSelf="center" justifyContent="center" spacing={1} alignItems="center">
                <Error color="error"/>
                <Typography color="error" align="center" variant="h5">
                    ERRO
                </Typography>
            </Stack>
            
            <Typography color="error">
                Descrição do erro: {props.error.message}
            </Typography>
            <Button 
                onClick={props.onDismiss} 
                disabled={!props.onDismiss} 
                color="success" 
                startIcon={<ArrowBack/>}
            >
                VOLTAR
            </Button>
        </ScreenPaper>
    )
}