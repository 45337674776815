import { ListColumn } from "../../components/list";
import DateToString from "../date_to_string";
import parseBRL from "../parse_brl";

export default function ColumnToString(data: any, column: ListColumn): string{
    const v = data[column.field];

    switch(column.type){
        case 'boolean': return v ? 'S' : 'N';
        case 'money': return parseBRL(v);
        case 'number': return parseBRL(v, true, undefined, 0);
        case 'date': return DateToString(v, 'date');
        case 'dateTime': return DateToString(v, 'datetime');
        default:
            if(v == null) return '';
            else return v;
    }
}