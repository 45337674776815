import { useEffect, useMemo, useState } from "react";
import List from "../../shared/components/list";
import { hashDate, useSummariesController } from "./controller";
import usePromisedState from "../../shared/hooks/use_promised_state";
import StockHistoryService from "../stock_history/service";
import StockHistoryList from "../stock_history/list";

export default function SummariesStockList(){
    const controller = useSummariesController();
    
    const service = useMemo(() => new StockHistoryService(), []);
    const getSells = () => service.list().then(s => Object.values(s));
    const [list] = usePromisedState({initialState: getSells})
    const [filtred, setFiltred] = useState<typeof list['value']>([]);

    useEffect(() => {
        if(list.resolved){
            setFiltred(list.value?.filter(i => controller.filterItem({date: i.data.crtAt, storeUid: i.data.store.uid})));
        }
    }, controller.listsDeps(list.resolved));
    return (
        <StockHistoryList
            rows={filtred!}
            readOnlyCheck={(!controller.currentDS.canDoActions || !controller.currentDS.isSingle)}
            onApprove={(sh) => controller.updateDSByUid(sh.data.store.uid)}
            loading={!list.resolved}
        />
    )
}