import { useState } from "react"

export default function DataGridFilteredList(): [filteredList: any[], setDataGridFilteredList: (state: any, rows: any) => void]{
    const [filteredList, _setFL] = useState([]);
    const setDataGridFilteredList = (state: any, rows: any[]) => {
        const filtered = state.filter.filteredRowsLookup||{};
        const sorted = state.sorting.sortedRows||[];
        const sortedFilteredIds = sorted.filter((id:string) => filtered[id]);
        const list = sortedFilteredIds.map((id: string) => rows.find(r => r?.id === id || r?.uid === id));
        _setFL(list);
    }
    return [filteredList, setDataGridFilteredList];
}