import { createTheme, LinkProps, ThemeOptions } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import { ptBR as dgptBR} from "@mui/x-data-grid";
import LinkBehavior from "../../components/link_behavior";

export const defaultThemeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: '#3505E0'
        }
    },
    components:{
        MuiButton:{
            defaultProps: {
                variant: "contained"
            }
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as LinkProps,
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        }
    }
}

const defaultTheme: ThemeOptions = createTheme({
    ...defaultThemeOptions
}, ptBR, dgptBR);

export default defaultTheme;