import { Button, Divider, Grid, List, Paper, Stack, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { SummariesController, SummariesControllerContext } from "./controller";
import SummariesDates from "./summaries_dates";
import { DailySummaryStatus } from "./models";
import { Fragment, useMemo, useState } from "react";
import InfoItem from "../../shared/components/info_item";
import parseBRL from "../../shared/utils/parse_brl";
import SummariesStoreSelect from "./summaries_store_select";
import { Paid } from "@mui/icons-material";
import ScreenPaper from "../../shared/components/screen_paper";
import StockHistoryList from "../stock_history/list";
import SummariesSellList from "./summaries_sell_list";
import SummariesPhoneSOList from "./summaries_phone_so_list";
import SummariesStockList from "./summaries_stock_list";
import dialogHelper from "../../shared/components/easy_dialog";
import DateToString from "../../shared/utils/date_to_string";
import SummariesCashRemittanceList from "./summaries_cash_remittance_list";
import CashRemittanceMicroEdit from "../cash_remittance/micro_edit";
import Checkbox from "../../shared/components/checkbox";
import useDomain from "../../shared/hooks/use_domain";
import If from "../../shared/components/if";


export default function Summaries(){
    const controller = SummariesController();
    const [currentTable, setCurrentTable] = useState<number|undefined>();
    const [showRemittance, setShowRemittance] = useState<string>();
    const mobile = useMediaQuery('(max-width:600px)');
    const currentDS = controller.currentDS;
    const ds = controller.currentDS.ds;
    const cv = ds?.data.currentValues;
    const hasntSO = !useDomain('phone_so');

    const open = () => {
        dialogHelper.showConfirmation({
            title: 'Abrir caixa',
            content: 'Tem certeza de que deseja abrir este caixa?',
            callback: controller.openDS,
        })
    }
    const close = () => {
        let send = true;
        dialogHelper.showConfirmation({
            title: 'FECHAR CAIXA',
            content: (
                <Stack>
                    <Typography>Esta ação consolidará as informações da loja e finalizará a operação diária, deseja continuar?</Typography>
                    <Checkbox
                        label="Enviar todos os valores para o caixa principal"
                        defaultValue={send}
                        onChange={() => send = !send}
                    />
                </Stack>
            ),
            callback: () => controller.closeDS(send),
        })
    }

    const buttons = useMemo(() => [
        {label: 'ABRIR CAIXA', color: 'success', action: open, show: currentDS.isToday && [DailySummaryStatus.CONCLUIDO, DailySummaryStatus.FECHADO].includes(ds?.data.status!)},
        {label: 'FECHAR CAIXA', color: 'error', action: close, show: [DailySummaryStatus.ABERTO, DailySummaryStatus.ATRASO].includes(ds?.data.status!)},
        {label: 'MOVIMENTAR CAIXA', action: () => setShowRemittance('create'), show: currentDS.isToday && [DailySummaryStatus.ABERTO].includes(ds?.data.status!)},
        {label: 'ENVIAR REMESSA', color: 'info', action: () => setShowRemittance('request'), show: controller.canChangeStore && currentDS.isToday && [DailySummaryStatus.ABERTO].includes(ds?.data.status!)},
        {label: 'GERAR RELATÓRIO', color: 'warning', action: controller.generateConclusionReport, show: [DailySummaryStatus.CONCLUIDO].includes(ds?.data.status!)},
    ].filter(b => b.show), [currentDS]);

    const consolidations = useMemo(() => [
        {name: null, items: [
            {label: 'Faturamento', value: parseBRL(cv?.invoice||0), xs: mobile ? 6 : 3},
            {label: 'Taxas', value: parseBRL(cv?.tax||0), xs: mobile ? 6 : 3},
            {label: 'Custo', value: parseBRL(cv?.cost||0), xs: mobile ? 6 : 3},
            {label: 'Lucro', value: parseBRL(cv?.profit||0), xs: mobile ? 6 : 3},
        ]},
        {name: 'CAIXA', hide: !controller.dtHashes.isSingleDate, items: [
            {label: 'Dinheiro', value: parseBRL(cv?.money||0)},
            {label: 'PIX', value: parseBRL(cv?.pix||0)},
            {label: 'Cartão de Crédito', value: parseBRL(cv?.credit||0)},
            {label: 'Cartão de Débito', value: parseBRL(cv?.debt||0)},
        ]},
        {name: 'MOVIMENTAÇÃO', items: [
            {label: 'Total Vendas', value: parseBRL(cv?.totalSells||0)},
            {label: 'Qnt. Vendas', value: (cv?.amountSells||0)},
            {label: 'Total OS', value: parseBRL(cv?.totalSO||0), hide: hasntSO},
            {label: 'Qnt. OS', value: (cv?.amountSO||0), hide: hasntSO},

            {label: 'Qnt. Remessas', value: (cv?.amountCashRemittances||0)},
            {label: 'Remessas Aprovadas', value: (cv?.amountApprovedCashRemittances||0)},
            {label: 'Qnt. Estoque', value: (cv?.amountStockHistories||0)},
            {label: 'Estoque Aprovados', value: (cv?.amountApprovedStockHistories||0)},
        ]},
    ].filter(i => !i.hide), [currentDS, mobile, controller.dtHashes.isSingleDate]);

    const metadata = [
        [controller.currentDS.ds?.data.openIn, `ABERTURA: ${DateToString(controller.currentDS.ds?.data.openIn!)} - ${controller.currentDS.ds?.data.openBy?.label}`],
        [controller.currentDS.ds?.data.closedIn, `CONCLUSÃO: ${DateToString(controller.currentDS.ds?.data.closedIn!)} - ${controller.currentDS.ds?.data.closedBy?.label}`],
    ].filter(a => a[0]);

    return (
        <SummariesControllerContext.Provider value={controller}>
            {showRemittance && (
                <CashRemittanceMicroEdit
                    mode={showRemittance as any}
                    store={controller.currentDS.ds?.data.store!}
                    readOnlyMode={!controller.canChangeStore}
                    onlyMoney={showRemittance === 'create' ? !controller.canChangeStore : false}
                    noNavigation={{new: true, gotoIndex: () => setShowRemittance(undefined)}}
                    onSave={(n) => {
                        setShowRemittance(undefined);
                        setTimeout(() => dialogHelper.showDialog({title: 'Sucesso!', content: 'Operação realizada com sucesso.', buttons: [dialogHelper.okButton()]}), 500);
                        controller.afterSendRemittance(n);
                    }}
                />
            )}
            <Stack spacing={1} padding={1}>
                <Paper>
                    <Stack spacing={1} padding={1}>
                        <Typography>SELEÇÃO</Typography>
                        {controller.canChangeDate && <SummariesDates/>}
                        {controller.canChangeStore && <SummariesStoreSelect/>}
                    </Stack>
                    {currentDS.isSingle && controller.dtHashes.isSingleDate && 
                    <Stack spacing={1} padding={1}>
                        <Divider/>
                        {metadata.map(([show, t], i) => (
                            <Typography key={i} variant="caption" color="primary">
                                {t}
                            </Typography>
                        ))}
                        {buttons.map(b => (
                            <Button key={b.label} color={(b.color as any)} onClick={b.action}>
                                {b.label}
                            </Button>
                        ))}
                    </Stack>}
                </Paper>
                <Paper>
                    <Stack spacing={1} padding={1}>
                        <Typography>CONSOLIDAÇÃO</Typography>
                        {consolidations.map((c: any) => (
                            <Fragment>
                                {c.name && (
                                    <Divider>{c.name}</Divider>
                                )}
                                <Grid container>
                                    {c.items.filter((item: any) => !item.hide).map((item: any) => (
                                        <Grid item xs={item.xs || 6} key={item.label}>
                                            <InfoItem
                                                label={item.label}
                                                value={item.value}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Fragment>
                        ))}
                    </Stack>
                </Paper>
                <Paper>
                    <Stack padding={1}>
                        <Tabs value={currentTable} onChange={(e, n) => setCurrentTable(n)}>
                            <Tab label="CAIXA"/>
                            <Tab label="ESTOQUE"/>
                            <Tab label="VENDAS"/>
                            <If cond={!hasntSO}>
                                <Tab label="O.S."/>
                            </If>
                        </Tabs>
                        {[
                            () => <SummariesCashRemittanceList/>,
                            () => <SummariesStockList/>,
                            () => <SummariesSellList/>,
                            () => <SummariesPhoneSOList/>,
                        ][currentTable!]?.()}
                    </Stack>
                </Paper>
            </Stack>
        </SummariesControllerContext.Provider>
    )
}

export const routeConfig: RouteConfig = {
    key: 'billing',
    label: 'Fechamento',
    Icon: Paid,
    buttonProps: {
        color: 'warning'
    }
}