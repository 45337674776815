import { useMemo, useRef } from "react";
import { CRUDItem } from "../../shared/crud/crud_service";
import { CashRemittanceOpReason, CashRemittanceProps } from "./model";
import CashRemittanceService from "./service";
import dialogHelper from "../../shared/components/easy_dialog";
import List, { ListColumns } from "../../shared/components/list";
import Checkbox from "../../shared/components/checkbox";
import useIncrementState from "../../shared/hooks/use_increment_state";
import { CircularProgress } from "@mui/material";

export interface CashRemittanceListProps{
    readOnly?: boolean;
    readOnlyCheck?: boolean;
    rows: CRUDItem<CashRemittanceProps>[];
    onApprove?: (item: CRUDItem<CashRemittanceProps>) => void;
    loading?: boolean;
}

export default function CashRemittanceList(props: CashRemittanceListProps){
    const service = useMemo(() => new CashRemittanceService(), []);
    const [, _setLoadings] = useIncrementState({});
    const loadings = useRef({});

    const setLoadings = (uid: string, value: boolean) => {
        loadings.current[uid] = value;
        _setLoadings(loadings.current);
    }

    const check = (stc: CRUDItem<CashRemittanceProps>, value: boolean) => {
        const uid = stc.uid!;
        setLoadings(uid, true);
        service.setApproval(stc, value)
        .then(newStc => {
            props.onApprove?.(newStc);
            setLoadings(uid, false);
        })
        .catch(err => {
            setLoadings(uid, false);
            dialogHelper.showError(err);
        });
    }

    const columns = useMemo(() => {
        const _columns: ListColumns = [
            {
                field: 'data.approved', headerName: 'Aprovado', type: 'boolean', minWidth: 30,
                renderCell: (params) => {
                    return loadings.current[params.row.uid] ? (
                        <CircularProgress color="primary" size={'1.5rem'}/>
                    ) : (
                        <Checkbox
                            value={params.value}
                            label={null}
                            key={params.row.uid + 'checkbox'}
                            onChange={(e,v) => check(params.row as any, v)}
                            disabled={props.readOnlyCheck}
                        />
                    )
                }
            },
            {field: 'lastOpReason', headerName: 'Motivo', valueGetter: (p) => CashRemittanceOpReason[p.row.data.lastOpReason]},
            {field: 'data.crtAt', headerName: 'Data', type: 'dateTime'},
            {field: 'data.values.money', headerName: 'Dinheiro', type: "money"},
            {field: 'data.values.pix', headerName: 'PIX', type: "money"},
            {field: 'data.values.debt', headerName: 'Débito', type: "money"},
            {field: 'data.values.credit', headerName: 'Crédito', type: "money"},
            {field: 'data.lastOpObs', headerName: 'Obs.'},
            {field: 'data.modifiedBy.label', headerName: 'Modificado Por'},
            {field: 'data.approvedBy.label', headerName: 'Aprovado Por'},
            {field: 'data.store.label', headerName: 'Loja'},
        ];
        if(props.readOnly) _columns.shift();
        return _columns;
    },[props.readOnly, props.readOnlyCheck]);

    return (
        <List
            rows={props.rows}
            defaultSort={[{ field: 'data.crtAt', sort: 'desc' }]}
            getRowId={(r) => r.uid}
            columns={columns}
            autoHeight
            loading={props.loading}
        />
    )
}