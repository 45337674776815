import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../../modules/login";

export default function UnauthenticatedRoute(){    
    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Login/>} />
                <Route path="*" element={<Navigate to="/"/>} />
            </Routes>
        </Fragment>
    )
}