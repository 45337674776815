import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import ProductDBRef from "../product/dbref";
import { ProductProps } from "../product/model";
import { StockProps } from "../stock/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import SupplierDBRef from "../supplier/dbref";
import { ZoneProps } from "../zone/model";

export interface SupplierStockProps extends StockProps{
    date: EpochTimeStamp;
    label: string;
}

export const SupplierStockFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        store: {
            field: "store",
            type: "dbref",
            label: "Fornecedor",
            component: SupplierDBRef,
            required: true,
        },
        product: {
            field: "product",
            type: "dbref",
            label: "Produto",
            component: ProductDBRef,
            required: true,
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade",
            required: true,
        },
        date: {
            field: "date",
            type: "date",
            label: "Data Envio",
            required: true,
        },
        label: {
            field: "label",
            type: "text",
            label: "Descrição"
        }
    }, options)
}