import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import ControlInfo from "../../shared/components/control_info";
import { EasyForm, EasyFormInput, EasyFormWriteOnly } from "../../shared/components/easy_form";
import useCRUDEdit, { UseCRUDEditComponentProps } from "../../shared/crud/use_crud_edit";
import { TransferFields, TransferProps } from "./model";
import TransferService from "./service";
import useEffectState from "../../shared/hooks/use_effect_state";
import { transferTypeConfigs } from "./model";
import { TransferStatus } from "./model";
import { Fragment, useState } from "react";
import { Close, Done, ExpandLess, ExpandMore } from "@mui/icons-material";
import dialogHelper from "../../shared/components/easy_dialog";
import { CRUDItem } from "../../shared/crud/crud_service";
import useAuth from "../../shared/hooks/use_auth";
import UserInfo from "../../shared/stores/auth_slice/user_info";

const _canChange = (isOpen: boolean, data: TransferProps, user: UserInfo) => {
    if(isOpen){
        const perm = user.perms.transfer;
        const permType = perm?.split(':')[1];
        if(perm[0] === 's') return true;
        if(permType === 'store' && user.user.configs.store.uid === data.targetStore.uid){
            return true;
        }
        if(user.user.uid === data.targetUser.uid){
            return true;
        }
    }
    return false;
}

export default function TransferMicroEdit(props: UseCRUDEditComponentProps<TransferProps>){
    const create = useCRUDEdit({
        props: props,
        fieldsGen: TransferFields,
        CRUDService: TransferService,
        goBackOnSave: true,
    });
    const auth = useAuth();
    const typeConfig = transferTypeConfigs[create.data.typeCode];
    const isOpen = create.data.status === TransferStatus.ABERTO;

    const [addFields] = useEffectState(() => typeConfig?.propFields({defaults: create.data}), [create.data.typeCode, create.uid, create.initializing]);
    const [str] = useEffectState(() => typeConfig?.makeStr(create.data), [create.data.typeCode, create.uid]);
    const [showDetails, setShowDetails] = useEffectState(!isOpen, [create.data.status]);
    
    const operate = (accept: boolean) => {
        dialogHelper.showLoading();
        const prom = accept ? create.crudService.approve(create.getCRUDItem()) : create.crudService.reject(create.getCRUDItem());
        prom.then((newItem: CRUDItem<TransferProps>) => {
            props.onSave?.(newItem);
            create.gotoIndex();
            dialogHelper.showDialog({
                title: 'Sucesso!', 
                content: `A transferência foi ${accept ? 'aprovada' : 'recusada'}!`,
                buttons: [dialogHelper.okButton()]
            })
        })
        .catch(dialogHelper.showError);
    }

    if(create.initializing || !addFields){
        return (
            <Dialog open>
                <DialogContent>
                    <CircularProgress color="primary"/>
                </DialogContent>
            </Dialog>
        )
    }

    const canChange = _canChange(isOpen, create.data, auth.user!);

    return (
        <Dialog open onClose={create.gotoIndex} fullScreen={showDetails}>
            <DialogTitle>
                Transferência
            </DialogTitle>
            <EasyForm {...create.easyFormProps} fields={{...create.easyFormProps.fields, ...addFields}} readOnly>
                <DialogContent style={{padding: '20px 10px'}}>
                    <Stack spacing={1}>
                        <Typography style={{whiteSpace: 'pre-line'}}>
                            {str}
                        </Typography>
                        {showDetails &&
                        <Fragment>
                            <ControlInfo {...create}/>
                            <EasyFormInput field="typeCode"/>
                            <EasyFormInput field="status"/>
                            <EasyFormInput field="verifiedBy"/>
                            <EasyFormInput field="verifiedAt"/>
                            <EasyFormInput field="sourceUser"/>
                            <EasyFormInput field="sourceStore"/>
                            <EasyFormInput field="sourceZone"/>
                            <EasyFormInput field="targetUser"/>
                            <EasyFormInput field="targetStore"/>
                            <EasyFormInput field="targetZone"/>
                            <Divider/>
                            {Object.keys(addFields||{}).map(k => <EasyFormInput field={k}/>)}
                        </Fragment>}
                        <Button variant="text" sx={{alignSelf: 'center'}} startIcon={showDetails ? <ExpandLess/> : <ExpandMore/>} onClick={() => setShowDetails(!showDetails)}>
                            DETALHES
                        </Button>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={create.gotoIndex} variant="text" color="inherit">
                        FECHAR
                    </Button>
                    {canChange &&
                    <Fragment>
                        <Button color="error" startIcon={<Close/>} onClick={() => operate(false)}>
                            REJEITAR
                        </Button>
                        <Button color="success" startIcon={<Done/>} onClick={() => operate(true)}>
                            ACEITAR
                        </Button>
                    </Fragment>}
                </DialogActions>
            </EasyForm>
        </Dialog>
    )
}