import { Business, Diversity3 } from "@mui/icons-material";
import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import SupplierEdit from "./edit";
import SupplierList from "./list";

export const routeConfig: RouteConfig = {
    key: 'supplier',
    label: 'Fornecedores',
    Icon: Diversity3,
}

export default function ZoneModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'supplier',
        routes: {
            create: {Component: SupplierEdit},
            update: {Component: SupplierEdit},
            view: {Component: SupplierEdit},
            list: {Component: SupplierList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}