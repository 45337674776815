import { Route, Routes } from "react-router-dom";
import { SupervisedUserCircle } from "@mui/icons-material";
import UserList from "./list";
import UserEdit from "./edit";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";

export const routeConfig: RouteConfig = {
    key: 'user',
    label: 'Usuários',
    Icon: SupervisedUserCircle,
}

export default function UserModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'user',
        routes: {
            create: {Component: UserEdit},
            update: {Component: UserEdit},
            view: {Component: UserEdit},
            list: {Component: UserList},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

