import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Cancel, Delete, History, ReceiptLong, Security } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import { SellFields, SellProps } from "./model";
import ControlInfo from "../../shared/components/control_info";
import SellService from "./service";
import List from "../../shared/components/list";
import FixedTextField from "../../shared/components/fixed_text_field";
import dialogHelper from "../../shared/components/easy_dialog";
import useAuth from "../../shared/hooks/use_auth";
import SellPrint from "../print/sell_print";
import { paymentStringify } from "../payment/model";
import { Fragment } from "react";
import Checkbox from "../../shared/components/checkbox";
import { StockProps } from "../stock/model";
import { WithID } from "../../shared/crud/use_crud_list";
import MultiStockTransfer, { MultiStockTransferResult } from "../stock/multi_stock_transfer";
import ShowHistoryList from "../stock_history/show_history_list";

export default function SellEdit(props: any){
    const create = useCRUDEdit<SellProps, SellService>({
        props,
        fieldsGen: SellFields,
        CRUDService: SellService,
    });
    const auth = useAuth();

    const showWarranty = () => {
        ShowHistoryList({
            strList: Object.keys(create.data.sellWarranties||{}),
        });
    }

    const cancel = () => {
        const realCancel = () => {
            dialogHelper.showLoading();
            create.crudService.cancel(create.uid!)
            .then(newData => {
                create.setData(newData.data);
                dialogHelper.showDialog({title: 'Sucesso!', content: 'Venda cancelada com sucesso.', buttons: [dialogHelper.okButton()]});
            })
            .catch(dialogHelper.showError);
        }

        dialogHelper.showConfirmation({callback: realCancel, color: 'error'});
    }

    const printReceipt = () => SellPrint(create.uid!, create.data);

    const warranty = () => {

        const checkeds = {};

        const confirm = (r: MultiStockTransferResult) => {
            dialogHelper.showLoading();

            new SellService().generateWarranty(create.uid!, r)
            .then((newItem) => {
                create.setData(newItem.data);
                ShowHistoryList({
                    strList: Object.keys(newItem.data.sellWarranties||{}),
                });
                dialogHelper.closeDialog();
            })
            .catch(dialogHelper.showError)
        }
        
        const next = () => {
            const stocks = Object.keys(checkeds)
            .filter(k => checkeds[k])
            .map(k => create.data.items[k])
            .map(item => {
                const [fontKey] = Object.entries(item.fonts)[0];
                return {
                    id: fontKey,
                    product: item.product,
                    store: create.data.store,
                    amount: item.amount,
                    zone: create.data.zone,
                } as Partial<StockProps> & WithID;
            })

            dialogHelper.showDialog({
                title: 'GARANTIA',
                content: (
                    <MultiStockTransfer
                        stocks={stocks as any}
                        onCancel={dialogHelper.closeDialog}
                        withDescription
                        onConfirm={confirm}
                    />
                )
            })
        }

        dialogHelper.showDialog({
            title: 'GARANTIA',
            content: (
                <Fragment>
                    <Typography>
                        Marque os itens com defeito:
                    </Typography>
                    {Object.entries(create.data.items).map(([k, i]) =>(
                        <Checkbox
                            label={i.product.label}
                            key={k}
                            onChange={() => checkeds[k] = !checkeds[k]}
                        />
                    ))}
                </Fragment>
            ),
            buttons: [
                dialogHelper.okButton({content: 'FECHAR'}),
                dialogHelper.okButton({content: 'PRÓXIMO', callback: next}),
            ]
        })
    }

    if(create.initializing){
        return <ScreenLoading/>
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps} readOnly>
                <Stack spacing={1}>
                    <Typography>
                        VENDA
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <FixedTextField value={create.uid} readOnly label="Cupom"/>
                    {create.data.cancelled && <EasyFormInput field="cancelled" color="error"/>}
                    <EasyFormInput field="clientName"/>
                    <EasyFormInput field="clientPhone"/>
                    <EasyFormInput field="warranty"/>
                    <EasyFormInput field="description"/>
                    <EasyFormInput field="totalValue"/>
                    <FixedTextField value={paymentStringify(create.data.payment, create.data.totalValue!)} label="Pagamento"/>
                    <Divider><Chip label="PRODUTOS"/></Divider>
                    <List
                        autoHeight
                        rows={Object.values(create.data.items)}
                        columns={[
                            {field: 'product.label', headerName: 'Produto'},
                            {field: 'amount', headerName: 'Quantidade', type: 'number'},
                            {field: 'sellValue', headerName: 'Preço Un.', type: 'money'},
                            {field: 'total', headerName: 'Total', type: 'money', valueGetter: ({row}) => row.amount * row.sellValue},
                            {field: 'gift', headerName: 'Brinde', type: 'boolean'},
                            {field: 'stock', headerName: 'Estoque', valueGetter: ({row}) => Object.values(row.fonts).map((f: any) => `${f.label} = ${f.amountGathered == null ? f.amount : f.amountGathered}`).join('; ')}
                        ]}
                        getRowId={(r) => r.product.uid}
                    />
                    <Divider><Chip label="AÇÕES"/></Divider>
                    {!create.data.cancelled && auth.user?.perms["sellcontrol"] && 
                    <Button startIcon={<Cancel/>} color="error" onClick={cancel}>
                        CANCELAR VENDA
                    </Button>}
                    {!create.data.cancelled && 
                    <Button startIcon={<Security/>} color="warning" onClick={warranty}>
                        GERAR GARANTIA
                    </Button>}
                    {create.data.sellWarranties && 
                    <Button startIcon={<History/>} color="warning" onClick={showWarranty}>
                        HISTÓRICO GARANTIA
                    </Button>}
                    <Button onClick={printReceipt} startIcon={<ReceiptLong/>}>
                        IMPRIMIR RECIBO
                    </Button>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}