import { createContext } from "react";
import { UseFormHandleSubmit, UseFormReturn } from "react-hook-form";
import { EasyFormField, EasyFormProps } from "./types";

export interface EasyFormContextVal{
    fields: {
        [key: string]: EasyFormField,
    };
    formConfig: UseFormReturn;
    submit: ReturnType<UseFormHandleSubmit<any>>;
    easyFormProps: EasyFormProps;
}

const EasyFormContext = createContext<EasyFormContextVal>({} as any);

export default EasyFormContext;