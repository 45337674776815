import { Button, Divider, Typography } from "@mui/material";
import ScreenPaper from "../../shared/components/screen_paper";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import ProductDBRef from "../product/dbref";
import StoreDBRef from "../store/dbref";
import { useState } from "react";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../shared/components/easy_form";
import useAuth from "../../shared/hooks/use_auth";
import MinPerm from "../../shared/utils/min_perm";
import { SwapHoriz } from "@mui/icons-material";
import { CRUDItem } from "../../shared/crud/crud_service";
import { StoreProps } from "../store/model";
import { DBRef } from "../../shared/models/model";
import useIncrementState from "../../shared/hooks/use_increment_state";
import StockService from "./service";
import dialogHelper from "../../shared/components/easy_dialog";
import { FetchError } from "../../shared/utils/fetcher";
import { useNavigate } from "react-router-dom";
import { ProductProps } from "../product/model";
import { TransferProps } from "../transfer/model";
import TransferService from "../transfer/service";

type StoreRef = DBRef<StoreProps, 'name'>;


interface TransferStockFields{
    product: DBRef<ProductProps, 'name'>;
    sourceStore: DBRef<StoreProps, 'name'>;
    targetStore: DBRef<StoreProps, 'name'>;
    amount: number;
}

export default function TransferStock(){
    const auth = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [stockService] = useState(() => new StockService());
    const [transferService] = useState(() => new TransferService());
    
    const [state, setState] = useIncrementState<any>({
        sourceStore: {
            ...auth.user?.user.configs.store, 
            label: `${auth.user?.user.configs.store.label} - ${auth.user?.user.configs.zone.label}`
        }
    });
    const [fields] = useState(() => TransferFields({defaults: state}));
    
    const changeStores = (type: 'sourceStore' | 'targetStore', value: StoreRef | undefined, product = state.product) => {
        const id = type+'Amount';
        if(!value || !product){
            setState({[id]: null});
        }
        setLoading(true);
        stockService.read(`${value?.uid}_${product?.uid}`)
        .then((stock) => {
            setState({[id]: stock.data.amount});
        })
        .catch((err: FetchError) => {
            if(err.statusCode == 404){
                setState({[id]: 0});
            }
            else{
                setState({[id]: 0});
                dialogHelper.showError(err);
            }
        })
        .finally(() => setLoading(false));
    }
    const change = (e: any) => {
        switch(e.target.name){
            case "product":
                changeStores("sourceStore", state.sourceStore, e.target.value);
                changeStores("targetStore", state.targetStore, e.target.value);
            break;
            case "sourceStore":
            case "targetStore":
                changeStores(e.target.name, e.target.value);
            break;
        }
        setState({[e.target.name]: e.target.value});
    }

    const submit = async (result: TransferStockFields) => {
        try{
            if(!result.product) throw new Error('Defina um produto!');
            if(!result.sourceStore) throw new Error('Defina a loja de origem!');
            if(!result.targetStore) throw new Error('Defina a loja de destino!');
            if(!result.amount) throw new Error('Defina uma quantidade válida!');
            if(result.amount > state.sourceStoreAmount) throw new Error('A loja de origem não possui itens suficientes!');

            dialogHelper.showLoading();
            await transferService.createStockTransfer({
                amount: result.amount,
                product: result.product,
                typeCode: 'stock_transfer',
                sourceStore: result.sourceStore,
                sourceZone: result.sourceStore._ref?.data.zone! || auth.user?.user.configs.zone,
                sourceUser: {label: auth.user?.user.name!, uid: auth.user?.user.uid!},
                targetStore: result.targetStore,
                targetZone: result.targetStore._ref?.data.zone!,
            });

            dialogHelper.showDialog({
                title: 'Sucesso!', 
                content: "O pedido de transferência de estoque foi criado com sucesso!", 
                buttons: [dialogHelper.okButton()]
            });
            navigate(-1);
        }
        catch(err:any){
            dialogHelper.showError(err);
        }
    }

    const canSetOrigin = MinPerm(auth.user?.perms.stock, 'w');

    return (
        <EasyForm fields={fields} onSubmit={submit}>
            <ScreenPaper>
                <Typography>
                    Transferir Estoque
                </Typography>
                <EasyFormInput field="product" onChange={change}/>
                <EasyFormInput 
                    field="sourceStore" required={canSetOrigin} readOnly={!canSetOrigin} onChange={change}
                    helperText={state.sourceStoreAmount != null && `${state.sourceStoreAmount} itens disponíveis.`}
                />
                <EasyFormInput 
                    field="targetStore" onChange={change}
                    helperText={state.targetStoreAmount != null && `${state.targetStoreAmount} itens disponíveis.`}
                />
                <EasyFormInput field="amount"/>
                <EasyFormAction>
                    <Button color="success" startIcon={<SwapHoriz/>} disabled={loading}>
                        TRANSFERIR
                    </Button>
                </EasyFormAction>
            </ScreenPaper>
        </EasyForm>
    )
}

const TransferFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        product: {
            field: "product",
            type: "dbref",
            label: "Produto",
            component: ProductDBRef,
            required: true,
        },
        sourceStore: {
            field: "sourceStore",
            type: "dbref",
            label: "Loja Origem",
            component: StoreDBRef,
            defaultProps: {
                itemLabel: (item: CRUDItem<StoreProps>) => `${item.data.name} - ${item.data.zone.label}`
            }
        },
        targetStore: {
            field: "targetStore",
            type: "dbref",
            label: "Loja Destino",
            component: StoreDBRef,
            required: true,
            defaultProps: {
                itemLabel: (item: CRUDItem<StoreProps>) => `${item.data.name} - ${item.data.zone.label}`
            }
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade",
            required: true,
        },
    }, options)
}