import { useContext, useState, useEffect } from 'react';
import dialogHelper from '../../../shared/components/easy_dialog';
import { EasyFormFieldList } from '../../../shared/components/easy_form/types';
import usePromisedState from '../../../shared/hooks/use_promised_state';
import DeepAssign from '../../../shared/utils/deep_assign';
import deepGet from '../../../shared/utils/deep_get';
import { SellItem, SellProps } from '../../sell/model';
import StockService from '../../stock/service';
import SellWizardContext from '../sell_wizard_context';
import FontDBRef from './font_dbref';
import useEffectState from '../../../shared/hooks/use_effect_state';
import { DBRef } from '../../../shared/models/model';
import { StockProps } from '../../stock/model';
import useAuth from '../../../shared/hooks/use_auth';

type StockItem = DBRef<StockProps, 'amount', {amount: number}>;
type ProductStocks = {[productUid: string]: {userStore: StockItem, otherStores: StockItem[]}};

export default function ItemDetailsController(){
    const { sell } = useContext(SellWizardContext);
    const { user } = useAuth();
    const [, setForceUpdate] = useState(0);
    const [removed, setRemoved] = useState<string[]>([]);
    const [stockService] = useState(() => new StockService());
    const getStocks = () => stockService.DBRefList((i) => `${i.data.store.label} - ${i.data.amount} itens`).then(list => 
        list.map(({value}) => {
            value.amount = value._ref.data.amount;
            return value as StockItem;
        })
    );
    const [_stocks, , setStocks] = usePromisedState<StockItem[]>({ initialState: getStocks });
    const getProductStocks = (stocks = _stocks) => {
        if(!stocks.resolved) return {};
        const _productStocks: ProductStocks = {};
        for(let item of Object.values(sell.items)){
            _productStocks[item.product.uid] = {
                userStore: stocks.value?.find(stock => {
                    return stock._ref?.data.store.uid === user?.user.configs.store.uid && 
                    stock._ref?.data.product.uid === item.product?.uid
                }
                )! || {uid: null, label: '', amount: 0},

                otherStores: stocks.value?.filter(stock => 
                    stock.amount &&
                    stock._ref?.data.store.uid !== user?.user.configs.store?.uid && 
                    stock._ref?.data.product.uid === item.product?.uid
                ).sort((a,b) => b.amount - a.amount)!
            }
        }
        return _productStocks;
    }
    const [productStocks, setProductStocks] = useEffectState<ProductStocks>(getProductStocks, [_stocks.resolved]);

    const [loading] = useEffectState(!_stocks.resolved, [_stocks.resolved]);

    const updateAmount = (key: string, formState: any) => {
        DeepAssign(sell, formState);
        setForceUpdate(Date.now());
    }

    const fields = generateFields(sell, updateAmount);
    
    const submit = async (formData: Pick<SellProps, 'items'>) => {
        //Handle removed items.
        for(let key of removed){
            delete formData.items[key];
            delete sell.items[key];
        }
        DeepAssign(sell, formData);
        if(!Object.values(formData.items).length){
            throw new Error('Você removeu todos os itens do carrinho! Por favor, retorne a tela anterior e selecione novos itens.');
        }

        //Update stocklist
        dialogHelper.showLoading();
        const stocks = {resolved: true, value: await getStocks()};
        setStocks(stocks);
        const _productStocks = await getProductStocks(stocks);
        setProductStocks(_productStocks);


        for(let [key, item] of Object.entries(sell.items)){
            //checks value
            if(!item.gift && item.minSellValue > item.sellValue){
                throw new Error(`O valor do item ${item.product.label} é menor que o mínimo permitido.`);
            }
            //Sets value to zero if is a gift.
            if(item.gift) item.sellValue = 0;

            //Checks the font
            const productStock = _productStocks[key];
            if(productStock.userStore.amount < item.amount){
                throw new Error(`A sua loja não possui a quantidade de itens necessárias para o produto ${item.product.label}.`);
            }
            //@ts-ignore
            formData.items[key].fonts = item.fonts = {
                [productStock.userStore.uid]: {
                    active: true,
                    amount: productStock.userStore.amount - item.amount,
                    label: productStock.userStore.label,
                    _ref: productStock.userStore._ref,
                }
            }
        }
        sell.totalValue = 0;
        for(let [key, item] of Object.entries(sell.items)){
            sell.totalValue += item.sellValue * item.amount;
        }
        dialogHelper.closeDialog();
    }

    const deleteItem = (key: string) => {
        setRemoved([...removed, key]);
    }
    
    return {
        sell,
        fields,
        allFields: fields,
        loading,
        submit,
        deleteItem,
        removed,
        productStocks,
    }
}

const generateFields = (sell: SellProps, updateAmount: (key: string, formState: any) => void) => {
    const fields: EasyFormFieldList = {};
    for(let key in sell.items){
        const item = sell.items[key];
        const 
            amountKey = `items.${key}.amount`,
            sellValueKey = `items.${key}.sellValue`,
            giftKey = `items.${key}.gift`
        ;

        fields[amountKey] = {
            field: amountKey, 
            type: 'int', 
            label: 'Quantidade', 
            required: true, 
            defaultValue: item.amount, 
            onBlur: (e, c) => updateAmount(key, c?.formConfig.getValues()),
        };
        fields[sellValueKey] = {
            field: sellValueKey, 
            type: 'number', 
            label: 'Valor de Venda un.', 
            required: !item.gift, 
            defaultValue: !isNaN(item.sellValue) ? item.sellValue : null,
        };
        fields[giftKey] = {
            field: giftKey, 
            type: 'checkbox', 
            label: 'Brinde',
            defaultValue: item.gift,
        };
    }
    return fields;
}