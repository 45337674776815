import CRUDService from "../../shared/crud/crud_service";
import { GeneralConfigsProps } from "./model";

export default class GeneralConfigsService extends CRUDService<GeneralConfigsProps>{
    constructor(){
        super('general_configs');
    }
    getCached(){
        return this.list().then(l => {
            const first = Object.values(l)[0];
            return first || {data: {}, uid: ''};
        })
    }
}