import { useState, useContext } from "react";
import DeepAssign from "../../../shared/utils/deep_assign";
import { SellFields } from "../../sell/model";
import SellWizardContext from "../sell_wizard_context";

export default function SellDetailsController(){
    const {sell} = useContext(SellWizardContext);
    const [fields] = useState(() => SellFields({defaults: sell}));

    const submit = (formData: any) => {
        DeepAssign(sell, formData);
    }

    return {
        sell, fields, submit
    }
}