import { useContext, useEffect, useState } from "react";
import Gallery, { GalleryPhoto } from "../../shared/components/gallery";
import { CRUDItem } from "../../shared/crud/crud_service";
import { Photo } from "../file/model";
import { PhoneSOProps } from "./model";

export interface PhoneSOGalleryProps{
    photos: {
        [key: number]: Photo;
    };
    onChangeNewPhotos?: (blobs: File[]) => void;
    readOnly?: boolean;
    tagFilter?: string;
}

export default function PhoneSOGallery(props: PhoneSOGalleryProps){

    let dataPhotos = props.photos;
    const [photos, setPhotos] = useState<{[key: string]: Photo}>({});
    const [newPhotos, setNewPhotos] = useState<{[key: string]: GalleryPhoto}>({});

    useEffect(() => {
        setPhotos(dataPhotos);
        setNewPhotos({});
    }, [dataPhotos]);

    const changeNewPhotos = (_newPhotos: {[key: string]: GalleryPhoto}) => {
        setNewPhotos(_newPhotos);
        props.onChangeNewPhotos?.(Object.values(_newPhotos).map((i:any) => i.file));
    }

    const photosList = [
        ...Object.entries(photos||{}).filter(p => p[1] && (!props.tagFilter || p[1].tag === props.tagFilter) ? true : false), 
        ...Object.entries(newPhotos)
    ].filter(([,photo]) => photo);

    return (
        <Gallery
            readOnly={props.readOnly}
            imageList={photosList.map(([, photo]) => photo)}
            onDeletePhoto={(photo) => {
                const entry: any = photosList.find(([key, p]) => photo.url === p.url);
                if(entry){
                    if(entry[1].file){
                        delete newPhotos[entry[0]];
                        changeNewPhotos({...newPhotos});
                    }
                    else{
                        //@ts-ignore
                        photos[entry[0]] = dataPhotos[entry[0]] = null;
                        setPhotos({...photos});
                    }
                }
            }}
            onNewPhotos={(files) => {
                const dt = Date.now();
                const mapped = files.map((photo,i) => ({
                    [`${dt}_${i}`]: photo
                }));

                const _newPhotos = Object.assign({...newPhotos}, ...mapped);
                changeNewPhotos(_newPhotos);
            }}
        />
    )
}