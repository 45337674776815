import AutocompleteInput from "../autocomplete_input";
import Checkbox from "../checkbox";
import DateInput from "../date_input";
import DBRefInput from "../dbref_input";
import FixedTextField from "../fixed_text_field";
import MaskedTextField from "../masked_text_field";
import NumberInput from "../number_input";
import PasswordTextField from "../password_text_field";
import SelectInput from "../select_input";
import { EasyFormInputConfig } from "./easy_form_input";

const EasyFormInputTypes: { [key: string]: EasyFormInputConfig } = {
    "text": {
        component: FixedTextField,
    },
    "maskedtext": {
        component: MaskedTextField,
    },
    "multiline": {
        component: FixedTextField,
        componentProps: {
            multiline: true,
        }
    },
    "password": {
        component: PasswordTextField
    },
    "number": {
        component: NumberInput,
        registerProps: {}
    },
    "int": {
        component: NumberInput,
        registerProps: {},
        componentProps: {
            modeInt: true
        }
    },
    "checkbox": {
        component: Checkbox
    },
    "datetime": {
        component: DateInput,
        componentProps: {
            type: 'datetime'
        },
        registerProps: {
            valueAsDate: true,
        }
    },
    "date": {
        component: DateInput,
        componentProps: {
            type: 'date'
        },
    },
    "select": {
        component: SelectInput,
    },
    "bolselect": {
        component: SelectInput,
        componentProps: {
            options: [
                {label: "SIM", value: true},
                {label: "NÃO", value: false},
            ]
        }
    },
    "autocomplete": {
        component: AutocompleteInput,
    },
    "dbref": {
        component: DBRefInput,
    }
}

export default EasyFormInputTypes;