import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import WizardScreenProps from "../../../shared/components/wizard/wizard_screen_props";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";

export default function BasicInfo(props: WizardScreenProps){
    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
    });

    const [fields] = useState(() => ModelFieldsFilter(PhoneSOFields({
        defaults: wizard.data.data,
    }), ['clientName', 'clientPhone', 'clientId', 'clientAddress', 'deviceBrand', 'deviceModel', 'imei']))

    return (
        <Stack flex={1}>
            <EasyForm onSubmit={(data: PhoneSOProps) => {
                DeepAssign(wizard.data.data, data);
                wizard.submit();
            }} fields={fields}>
                <ScreenPaper>
                    <Typography align="center">
                        ORDEM DE SERVIÇO
                    </Typography>
                    <Typography align="center">
                        Informações do Cliente/Aparelho
                    </Typography>
                    <EasyFormInput field="clientName"/>
                    <EasyFormInput field="clientId"/>
                    <EasyFormInput field="clientAddress"/>
                    <EasyFormInput field="clientPhone" />
                    <EasyFormInput field="deviceBrand"/>
                    <EasyFormInput field="deviceModel"/>
                    <EasyFormInput field="imei"/>
                    <EasyFormAction>
                        <Button color="primary">
                            PRÓXIMO
                        </Button>
                    </EasyFormAction>
                </ScreenPaper>
            </EasyForm>
        </Stack>
    )
}