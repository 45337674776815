import { PropsWithChildren, useMemo } from "react";

export interface IfProps extends PropsWithChildren {
    cond: boolean | (() => boolean);
}

export default function If({cond, children}: IfProps){
    const show = useMemo(() => typeof cond === 'function' ? cond() : cond, [cond]);
    if(!show) return null;
    return (
        <>
            {children}
        </>
    )
}