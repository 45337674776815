import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";

export interface InstallmentProps extends DatedProps{
    totalPaid?: number;
    totalDebt?: number;
    avgParcel?: number;
    valParcel?: number;
    payInterval?: number;
    negotiatedAt?: EpochTimeStamp;
    startAt?: EpochTimeStamp;
}

export const InstallmentFields = (options?: ModelFieldsGenOptions): EasyFormFieldList => {
    return ModelFieldsGen({
        totalDebt: {
            field: 'totalDebt',
            type: 'number',
            label: 'Total Devido R$',
        },
        totalPaid: {
            field: 'totalPaid',
            type: 'number',
            label: 'Total Pago R$',
        },
        valParcel: {
            field: 'valParcel',
            type: 'number',
            label: 'Valor das Parcelas R$',
        },
        avgParcel: {
            field: 'avgParcel',
            type: 'number',
            label: 'Média Parcelas R$',
        },
        payInterval: {
            field: 'payInterval',
            label: 'Intervalo Pagamento Dias',
            type: 'int',
        },
        negotiatedAt: {
            field: 'negotiatedAt',
            label: 'Negociado em',
            type: 'date',
        },
        startAt: {
            field: 'startAt',
            label: 'Início Pagamentos',
            type: 'date',
        },
    }, options);
}