import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Checkbox from '../../shared/components/checkbox';
import List from '../../shared/components/list';
import ScreenPaper from '../../shared/components/screen_paper';
import useCRUDList from '../../shared/crud/use_crud_list';
import SellService from './service';

export default function SellList(props: any){
		const list = useCRUDList({
			CRUDService: SellService,
			props,
			columns: [
				{field: 'id', headerName: 'Cupom'},
				{field: 'seller.label', headerName: 'Vendedor', },
				{field: 'cancelled', headerName: 'Cancelado', type: 'boolean' },
				{field: 'store.label', headerName: 'Loja', },
				{field: 'totalValue', headerName: 'Valor', type: "money" },
				{field: 'crtAt', headerName: 'Criado em', type: 'dateTime'},
			]
		});

		const [showAll, setShowAll] = useState(false);
		const [filtered, setFiltered] = useState<any[]>([]);

		useEffect(() => {
			setFiltered(list.list.filter(sell => !sell.cancelled));
		}, [list.list]);

		return (
			<ScreenPaper flex1>
				<Typography>
					VENDAS
				</Typography>
				<Checkbox
					label='Mostrar canceladas'
					value={showAll}
					onChange={(e, v) => setShowAll(v)}
				/>
				<List
					{...list.listComponentProps}
					rows={showAll ? list.list : filtered}
					updateList={list.updateList}
					defaultSort={[{ field: 'crtAt', sort: 'desc' }]}						
				/>
			</ScreenPaper>
		)
}