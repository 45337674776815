import { Report } from "../model";
import ClientReport from "./client";
import FinancialReport from "./financial";
import ProductsReport from "./products";
import ProfitReport from "./profit";
import StockReport from "./stock";
import StoreReport from "./store";
import TechnicianReport from "./technician";
import UserReport from "./user";

const Reports: Report<any, any>[] = [
    ProductsReport,
    TechnicianReport,
    UserReport,
    StoreReport,
    FinancialReport,
    StockReport,
    ClientReport,
    ProfitReport,
]

export default Reports;