import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import dialogHelper from '../easy_dialog';
import WizardContext, { WizardContextValue } from "./wizard_context";

export interface UseWizardProps<DType>{
    name: string;
    nextCallback?: (context: WizardContextValue<DType>) => Promise<void>;
    previrousCallback?: (context: WizardContextValue<DType>) => Promise<void>;
}

export default function useWizard<DType>(props: UseWizardProps<DType>){
    const context = useContext<WizardContextValue<DType>>(WizardContext);
    const navigate = useNavigate();

    const submit = async () => {
        const callback = props.nextCallback || (async () => {});
        try{
            await callback(context);
            let nextRoute: string;
            const i = context.screenNames.findIndex(item => item === props.name);
            nextRoute = context.screenNames[i+1];
            navigate(`../${nextRoute}`);
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    }

    const previrous = async () => {
        if(props.previrousCallback) await props.previrousCallback(context);
        navigate(-1);
    }

    return {
        data: context.data,
        context,
        submit,
        previrous,
    }
}