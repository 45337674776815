import { EasyFormFieldList } from "../../shared/components/easy_form/types";
import { DBRef, DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import parseBRL from "../../shared/utils/parse_brl";
import { PaymentMethods, paymentStringify } from "../payment/model";
import ProductDBRef from "../product/dbref";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import UserDBRef from "../user/dbref";
import { UserProps } from "../user/model";
import ZoneDBRef from "../zone/dbref";
import { ZoneProps } from "../zone/model";

export interface TransferTypeConfig{
    code: string;
    label: string;
    makeStr: (transfer: TransferProps) => string;
    propFields: (options?: ModelFieldsGenOptions) => EasyFormFieldList;
    idGetter: (transfer: TransferProps) => string;
}

export enum TransferStatus{
    'ABERTO' = 1,
    'APROVADO' = 2,
    'REJEITADO' = 3,
    'ERRO' = 4
}

export interface CreateTransferProps{
    typeCode: string;
    sourceUser: DBRef<UserProps, 'name'>;
    sourceStore: DBRef<StoreProps, 'name'>;
    sourceZone: DBRef<ZoneProps, 'name'>;
    targetUser: DBRef<UserProps, 'name'>;
    targetStore: DBRef<StoreProps, 'name'>;
    targetZone: DBRef<ZoneProps, 'name'>;
}

export interface TransferProps extends DatedProps, CreateTransferProps{
    status: TransferStatus;
    props: { [key: string]: any };
    verifiedBy?: DBRef<UserProps, 'name'>;
    verifiedAt?: EpochTimeStamp;
}

export const TransferFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        typeCode: {
            field: 'typeCode',
            type: 'select',
            label: 'Tipo',
            options: Object.values(transferTypeConfigs).map(t => ({label: t.label, value: t.code})),
        },
        sourceUser: {
            field: 'sourceUser',
            type: 'dbref',
            label: 'Usuário Origem',
            component: UserDBRef,
        },
        sourceStore: {
            field: 'sourceStore',
            type: 'dbref',
            label: 'Loja Origem',
            component: StoreDBRef,
        },
        sourceZone: {
            field: "sourceZone",
            type: "dbref",
            label: "Zona",
            component: ZoneDBRef,
        },
        targetUser: {
            field: 'targetUser',
            type: 'dbref',
            label: 'Usuário Origem',
            component: UserDBRef,
        },
        targetStore: {
            field: 'targetStore',
            type: 'dbref',
            label: 'Loja Origem',
            component: StoreDBRef,
        },
        targetZone: {
            field: "targetZone",
            type: "dbref",
            label: "Zona",
            component: ZoneDBRef,
        },
        status: {
            field: 'status',
            type: 'select',
            label: 'Status',
            options: Object.values(TransferStatus).filter((i:any) => isNaN(i)).map(t => ({label: t as string, value: TransferStatus[t]})),
        },
        verifiedBy: {
            field: 'verifiedBy',
            type: 'dbref',
            label: 'Aprovado por',
            component: UserDBRef,
        },
        verifiedAt: {
            field: 'verifiedAt',
            type: 'datetime',
            label: 'Aprovado em',
        },
    }, options);
}

export const transferTypeConfigs: { [key: string]: TransferTypeConfig } = {
    stock_transfer: {
        code: 'stock_transfer',
        label: 'Estoque',
        propFields: (options?: ModelFieldsGenOptions) => ModelFieldsGen({
            'props.amount': {
                field: 'props.amount',
                type: 'number',
                label: 'Quantidade',
            },
            'props.product': {
                field: 'props.product',
                type: 'dbref',
                label: 'Produto',
                component: ProductDBRef,
            }
        }, options),
        makeStr: (t) => `${t.sourceUser.label} (${t.sourceStore.label}) Quer enviar (${t.props.amount}) ${t.props.product.label} para ${t.targetStore.label}.`,
        idGetter: t => `${t.props?.product?.label} (${t.props.amount})`
    },
    so_transfer: {
        code: 'so_transfer',
        label: 'O.S.',
        propFields: (options?: ModelFieldsGenOptions) => ModelFieldsGen({
            'props.phoneSo.uid': {
                field: 'props.phoneSo.uid',
                label: 'O.S.',
                type: 'text',
            },
            'props.targetStatus': {
                field: 'props.targetStatus',
                label: 'Próx. Status',
                type: 'text',
            }
        }, options),
        makeStr: (t) => `${t.sourceUser.label} (${t.sourceStore.label}) Quer enviar a O.S. de número ${t.props.phoneSo.uid} (${t.props.phoneSo.deviceName||''}) para ${t.targetUser.label} (${t.targetStore.label}).`,
        idGetter: t => t.props?.phoneSo?.uid
    },
    cash_remittance_transfer: {
        code: 'cash_remittance_transfer',
        label: 'Numerário',
        propFields: (options?: ModelFieldsGenOptions) => ModelFieldsGen({
            // 'props.crProps.values.money': {
            //     field: 'props.crProps.values.money',
            //     label: 'Valor',
            //     type: 'text',
                
            // }
        }, options),
        makeStr: (t) => {
            const mValue = t.props?.crProps?.values?.money || 0;
            let fromLabel: string, toLabel: string;
            if(mValue <= 0){
                toLabel = t.props?.crProps?.reference?.label;
                fromLabel = t.props?.crProps?.store?.label;
            }
            else{
                fromLabel = t.props?.crProps?.reference?.label;
                toLabel = t.props?.crProps?.store?.label;
            }
            return `${t.sourceUser.label} (${t.sourceStore.label}) Quer enviar uma remessa DA LOJA (${fromLabel}) para a LOJA (${toLabel}) com os seguintes valores: \n\n${paymentStringify(absValues(t.props?.crProps?.values||{}), 0, true)} `
        },
        idGetter: t => paymentStringify(absValues(t.props?.crProps?.values||{}), 0, true)
    }
}

const absValues = (p: PaymentMethods) => {
    const copy = {...p};
    for(let key in copy){
        const v = copy[key];
        if(typeof v === 'number') copy[key] = Math.abs(v);
    }
    return copy;
};