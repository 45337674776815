import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../root_store/store';
import UserInfo from "./user_info";

export interface AuthState{
    user?: UserInfo;
    logged: boolean;
    loaded: boolean;
}

const initialState: AuthState = {
    user: undefined,
    logged: false,
    loaded: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action: PayloadAction<AuthState>) => {
            Object.assign(state, action.payload);
        }
    }
});

export const { setAuthState } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;