import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { StockProps } from "./model";
import StoreDBRef from "../store/dbref";
import FixedTextField from "../../shared/components/fixed_text_field";
import NumberInput from "../../shared/components/number_input";
import useIncrementState from "../../shared/hooks/use_increment_state";
import { DBRef } from "../../shared/models/model";
import { StoreProps } from "../store/model";
import { WithID } from "../../shared/crud/use_crud_list";
import SupplierDBRef from "../supplier/dbref";
import { SupplierProps } from "../supplier/model";
import DateInput from "../../shared/components/date_input";

export interface MultiStockTransferProps{
    stocks: (StockProps & WithID)[];
    withTargetStore?: boolean;
    withDescription?: boolean;
    withTargetSupplier?: boolean;
    withDate?: boolean;
    withLabel?: boolean;
    defaultMode?: 'max' | 'min' | 'open';
    onConfirm: (result: MultiStockTransferResult) => void;
    onCancel: () => void;
}

export interface MultiStockTransferResult{
    store?: DBRef<StoreProps|SupplierProps, 'name'>;
    label?: string;
    date?: Date;
    stocks: {
        stock: StockProps, 
        amount: number, 
        description: string,
    }[];
}

export default function MultiStockTransfer(props: MultiStockTransferProps){

    const [store, setStore] = useState<DBRef<StoreProps|SupplierProps, 'name'>| null>(null);
    const [date, setDate] = useState<Date|null>(null);
    const [label, setLabel] = useState<string>();
    const [stocks, setStocks] = useState<{[stockUid: string]: {description: string, amount: number}}>(() => Object.assign(
        {}, ...props.stocks.map(s => ({[s.id]: {
            amount: props.defaultMode === 'max' ? s.amount : props.defaultMode === 'min' ? 1 : null, 
            description: ''
        }}))
    ));
    const [errors, setErrors] = useState<{[stockUid: string]: {[prop: string]: string}}>({});

    const cancel = () => {
        props.onCancel();
    }

    const submit = () => {
        const newErrors: any = {};
        const result: MultiStockTransferResult = {
            stocks: [], store: store!, date: date!, label,
        };
        let hasErrors = false;
        if((props.withTargetStore || props.withTargetSupplier) && !store){
            hasErrors = true;
            newErrors["store"] = {value: "Escolha um destino!"};
        }
        if(props.withDate && !date){
            hasErrors = true;
            newErrors["date"] = {value: "Defina uma data de envio!"};
        }
        for(let stock of props.stocks){
            const stockInfo = stocks[stock.id] || {};
            newErrors[stock.id] = {};
            if(!stockInfo.amount || stockInfo.amount > stock.amount){
                newErrors[stock.id].amount = 'Quantidade inválida.';
                hasErrors = true;
            }
            if(props.withDescription && !stockInfo.description){
                newErrors[stock.id].description = 'Defina o motivo.';
                hasErrors = true;
            }
            result.stocks.push({stock: stock, amount: stockInfo.amount, description: stockInfo.description});
        }
        setErrors(newErrors);
        if(!hasErrors){
            props.onConfirm(result);
        }
    }

    const change = (stockId: string, key: any, value: any) => {
        setStocks(c => ({
            ...c,
            [stockId]: {
                ...stocks[stockId], 
                [key]: value
            }
        }))
    }

    return (
        <Stack spacing={1}>
            {props.withTargetStore && 
            <StoreDBRef
                label="Destino"
                value={store}
                onChange={(e) => setStore(e.target.value)}
                error={Boolean(errors["store"]?.value)}
                helperText={errors["store"]?.value}
            />}
            {props.withTargetSupplier && 
            <SupplierDBRef
                label="Fornecedor"
                value={store}
                onChange={(e) => setStore(e.target.value)}
                error={Boolean(errors["store"]?.value)}
                helperText={errors["store"]?.value}
            />}
            {props.withDate && 
            <DateInput
                label="Data Envio"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                error={Boolean(errors["date"]?.value)}
                helperText={errors["date"]?.value}
            />}
            {props.withLabel && 
            <FixedTextField
                label="Descrição"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
            />}
            {props.stocks.map(s => (
                
                    <Stack gap="2px" padding="5px">
                        <Divider/>
                        <Typography color="primary">
                            {`${s.product.label} - ${s.store.label}`}
                        </Typography>
                        <NumberInput
                            label="Quantidade"
                            variant="outlined"
                            modeInt
                            value={stocks[s.id]?.amount||null}
                            onChange={(e) => change(s.id, 'amount', e.target.value)}
                            helperText={`${s.amount} disponíveis.`}
                            error={Boolean(errors[s.id]?.amount)}
                        />
                        {props.withDescription &&
                        <FixedTextField
                            label="Motivo"
                            variant="outlined"
                            multiline
                            onChange={(e) => change(s.id, 'description', e.target.value)}
                            helperText={errors[s.id]?.description}
                            error={Boolean(errors[s.id]?.description)}
                        />}
                    </Stack>
            ))}
            <Stack direction="row" gap="5px">
                <Button color="inherit" variant="text" onClick={cancel}>
                    CANCELAR
                </Button>
                <Button color="success" onClick={submit}>
                    PROSSEGUIR
                </Button>
            </Stack>
        </Stack>
    )
}