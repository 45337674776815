import Centered from "../../shared/components/centered";
import { CircularProgress, Stack } from "@mui/material";

export default function SagittariusSplash(){
    return (
        <Centered maxWidth="xs">
            <Stack spacing={2} alignItems="center">
                <CircularProgress color="primary"/>
            </Stack>
        </Centered>
    )
}