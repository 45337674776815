import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { CashRemittanceProps } from "./model";

export default class CashRemittanceService extends CRUDService<CashRemittanceProps>{
    constructor(){
        super('cash_remittance');
    }
    
    create(item: CRUDItem<CashRemittanceProps>){
        if(item.data._operationMode === 'sub'){
            for(let [k, v] of Object.entries(item.data.values||{})){
                item.data.values[k] = (-1) * v;
            }
        }
        return fetcher(this.prefix + (item.data._createMode === 'request' ? '/transfer' : ''), 'POST', item.data)
        .then(this.parseMiddlewareItem);
    }
    setApproval(item: CRUDItem<CashRemittanceProps>, approved: boolean){
        return fetcher(`${this.prefix}/setApproval/${item.uid}/${String(approved)}`, 'PATCH')
        .then(this.parseMiddlewareItem)
        .then(v => {
            item.data.approved = v.data.approved;
            return v;
        })
    }
}