import { forwardRef, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import ZoneService from "./service";


const GroupDBRef = forwardRef(function _GroupDBRef(props: Omit<DBRefInputProps, 'options'>, ref){
    const [service] = useState(() => new ZoneService());

    return (
        <DBRefInput
            {...props}
            options={() => service.DBRefList(props.itemLabel || 'name')}
            ref={ref}
        />
    )
});

export default GroupDBRef;