import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../../modules/error_boundary";
import Header from "../../modules/header";
import Logout from "../../modules/logout";
import Menu from "../../modules/menu";
import Playground from "../../modules/playground";
import * as MainModules from "../../modules/main_modules";

const dev = process.env.NODE_ENV !== "production";

export default function AuthenticatedRoute(){
    return (
        <Fragment>
            <Header/>
            <ErrorBoundary>
                <Routes>
                    {Object.entries(MainModules)
                    .filter(([, module]) => !module.routeConfig.active || module.routeConfig.active())
                    .map(([,module]) => (
                        <Route 
                            key={module.routeConfig.key}
                            path={`/${module.routeConfig.key}/*`}
                            element={<module.default/>}
                        />
                    ))}
                    {dev && <Route path="/playground/*" element={<Playground/>}/>}
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/" element={<Menu/>}/>
                </Routes>
            </ErrorBoundary>
        </Fragment>
    )
}