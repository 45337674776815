import { IMaskInput } from 'react-imask';
import { forwardRef, useState } from 'react';
import FixedTextField, { FixedTextFieldProps } from '../fixed_text_field';

export interface MaskedTextFieldProps extends FixedTextFieldProps {
    mask?: string;
    definitions?: {
        '#': RegExp;
    }
}

const TextMaskCustom = forwardRef((props: any, ref: any) => {
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      onAccept={(value:any) => props.onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const MaskedTextField = forwardRef((props: MaskedTextFieldProps, ref: any) => {
    const {mask, definitions, ...other} = props;
    const [value, setValue] = useState(props.value || props.defaultValue || null);

    return (
        <FixedTextField
            {...other}
            InputProps={{
                ...props.InputProps,
                inputComponent: TextMaskCustom,
                inputProps: {
                    mask,
                    definitions,
                },
                ref,
            }}
            value={props.value !== undefined ? props.value : value}
            onChange={(e) => {
                setValue(e.target.value);
                props.onChange?.(e);
            }}
        />
    );
});

export default MaskedTextField;