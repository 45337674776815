import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dialogHelper from "../components/easy_dialog";
import { EasyFormFieldList, EasyFormProps } from "../components/easy_form/types";
import useIncrementState from "../hooks/use_increment_state";
import { ModelFieldsGenOptions } from "../utils/model_fields_gen";
import CRUDService, { CRUDItem, CRUDServiceSignature } from "./crud_service";

export interface NoNavigationEditOptions{
    id?: string;
    new?: boolean;
    gotoIndex: () => void;
}

export interface UseCRUDEditComponentProps<T>{
    [key: string]: any;
    noNavigation?: NoNavigationEditOptions;
    onSave?: (newObj: CRUDItem<T>) => void;
    readOnly?: boolean;
}

export interface useCRUDEditProps<T, C extends CRUDService<T> = CRUDService<T>>{
    props: UseCRUDEditComponentProps<T>;
    fieldsGen: (options?: ModelFieldsGenOptions) => EasyFormFieldList;
    CRUDService: new () => C;
    onSave?: (newObj: CRUDItem<T>) => void;
    goBackOnSave?: boolean;
    beforeSave?: (oldObj: T, newObj: T) => void;
    onLoadData?: (obj: CRUDItem<T>) => void;
    id?: string;
}

export default function useCRUDEdit<T, C extends CRUDService<T> = CRUDService<T>>(props: useCRUDEditProps<T, C>){

    //## STATES
    const noNavigation = props.props.noNavigation;
    const [initializing, setInitializing] = useState(true); //defines if the crud is starting.
    const [loading, setLoading] = useState(false); //Defines if the crud is waiting for something.
    const [fields, setFields] = useState<EasyFormFieldList>(); //Defines the item's list of fields.
    const [crudService] = useState<C>(() => new props.CRUDService()); //Defines the service.
    const [data, setData] = useIncrementState<T>({} as any); //Defines the content data of the item being edited.
    const _params = useParams(); // Gets the params from URL.
    const params: any = noNavigation || _params;
    const [uid, setUid] = useState<string|undefined>(params.id || props.id); //Defines the current UID (ID of the object).
    const navigate = useNavigate(); // Uses Navigation.

    ///## VARIABLES
    const disabled = loading || initializing || props.props.disabled;
    const readOnly = props.props.readOnly;
    const deactiveActions = disabled || readOnly;

    // ## FUNCTIONS
    const gotoIndex = () => {
        noNavigation ? noNavigation.gotoIndex() : navigate('..');
    }

    const setCRUDItem = (item: CRUDItem<T>) => { // UPDATES THE CRUD ITEM BEING EDITED.
        setData(item.data);
        setUid(item.uid);
    }

    const getCRUDItem = (): CRUDItem<T> => ({uid, data});

    const loadData = async () => { //LOADS THE CRUD ITEM IF GIVEN.
        if(uid){
            const item = await crudService?.read(uid);
            if(props.onLoadData) await props.onLoadData(item);
            setCRUDItem(item);
            return item;
        }
    }

    ////## EFFECTS

    useEffect(() => { // INITIALIZES THE EDITION.
        if(!crudService) return;
        Promise.all([
            loadData()
            .then(item => {
                const f = props.fieldsGen({defaults: item?.data}); // GENERATE THE FIELDS
                setFields(f);
            }),
        ])
        .then(() => {
            setInitializing(false);
        })
        .catch(err => dialogHelper.showError(err, gotoIndex));
    }, [crudService]);

    //// #ACTIONS

    const submit = async (newData: T) => {
        if(deactiveActions) return;
        
        if(props.beforeSave){
            try{
                await props.beforeSave(data, newData);
            }
            catch(err: any){
                dialogHelper.showError(err);
                return;
            } 
        }

        setLoading(true);
        dialogHelper.showLoading('Salvando...');
        const prom = !uid ? crudService?.create({data: newData}) : crudService?.update({uid: uid, data: newData});

        prom!.then(async (newItem) => {
            setCRUDItem(newItem);
            await props.onSave?.(newItem);
            await props.props.onSave?.(newItem);
            if(props.goBackOnSave) navigate(-1);
            dialogHelper.closeDialog();
        })
        .catch(dialogHelper.showError)
        .finally(() => {
            setLoading(false);
        });
    }

    const deleteConfirmed = () => {
        setLoading(true);
        dialogHelper.showLoading();
        return crudService?.delete(uid!)
        .then(() => {
            gotoIndex();
            dialogHelper.showDialog({title: 'Sucesso!', content: 'Item removido com sucesso.', buttons: dialogHelper.okButton()})
        })
        .catch(dialogHelper.showError)
        .finally(() => setLoading(false));
    }

    const deleteAction = () => {
        if(deactiveActions) return;
        dialogHelper.showDialog({
            title: "CONFIRMAR EXCLUSÃO",
            content: "Tem certeza de que deseja excluir esse item?",
            locked: true,
            buttons: [
                dialogHelper.okButton({content: 'NÃO', buttonProps: {color: 'inherit', variant: 'text'}}),
                dialogHelper.okButton({content: 'SIM', callback: deleteConfirmed, buttonProps: {color: 'error'}}),
            ]
        });
    }

    const easyFormProps: EasyFormProps = {
        fields: fields!,
        disabled,
        onSubmit: submit,
        readOnly,
    }

    return {
        easyFormProps,
        fields,
        disabled,
        readOnly,
        loading,
        crudService,
        initializing,
        data,
        uid,
        setData,
        setLoading,
        setUid,
        gotoIndex,
        deleteAction,
        deleteConfirmed,
        getCRUDItem,
        loadData,
    }
}