import { css, Global } from "@emotion/react";
import { useTheme } from "@mui/material";

export default function Scroll(){
    const theme = useTheme();
    return <Global styles={css({
        '::-webkit-scrollbar':{
            width: '10px',
            height: '10px'
        },
        '::-webkit-scrollbar-track':{
            background: "transparent",
        },
        '::-webkit-scrollbar-thumb':{
            background: theme.palette.text.primary,
            borderRadius: "5px"
        },
        '::-webkit-scrollbar-thumb:hover':{
            background: theme.palette.text.primary
        },
    })}/>
}