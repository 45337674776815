import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import ProductDBRef from "../product/dbref";
import { ProductProps } from "../product/model";
import { StockProps } from "../stock/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import { ZoneProps } from "../zone/model";

export interface WarrantyStockProps extends StockProps{
}

export const WarrantyStockFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        store: {
            field: "store",
            type: "dbref",
            label: "Loja",
            component: StoreDBRef,
            required: true,
        },
        product: {
            field: "product",
            type: "dbref",
            label: "Produto",
            component: ProductDBRef,
            required: true,
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade",
            required: true,
        }
    }, options)
}