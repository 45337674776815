import Centered from "../../shared/components/centered";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button'
import useMenuComponents from "../../shared/hooks/use_menu_components";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";


const _Button = styled(Button)({
    height: 100,
    minWidth: 120,
    width: 170
})

export default function Menu(){
    const menuComponents = useMenuComponents();
    return (
        <Centered>
            <Grid container alignItems="center" spacing={1} justifyContent="center" margin="20px 0px">
                {menuComponents.map((item, i) => (
                    <Grid item key={item.key}>
                        <_Button key={i} {...item.buttonProps}>
                            <Stack alignItems="center">
                                <item.Icon/>
                                {item.label}
                            </Stack>
                        </_Button>
                    </Grid>
                ))}
            </Grid>
        </Centered>
    )
}