import { useEffect, useState } from "react";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { WizardRoot } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { PhoneSOProps } from "../model";
import FinishDetails from "./details";
import FinishEnd from "./end";
import FinishPayment from "./payment";

export default function FinishSo(){
    const location = useLocation();
    const [phoneSo] = useState<CRUDItem<PhoneSOProps>>(() => location.state?.phoneSo);
    

    return !phoneSo ? (
        <Navigate to={'/'}/>
    ) :
    (
        <WizardRoot
            data={phoneSo}
            screens={[
                {Component: FinishDetails, name: 'details'},
                {Component: FinishPayment, name: 'payment'},
                {Component: FinishEnd, name: 'end'},
            ]}
        />
    )
}