import { createContext } from "react";


export interface WizardContextValue<DType = any>{
    data: DType;
    screenNames: string[];
}

const WizardContext = createContext({} as WizardContextValue);

export default WizardContext;

