import { Edit } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { Fragment, useContext, useState, useEffect } from "react";
import { EasyFormInput } from "../../shared/components/easy_form";
import EasyFormContext from "../../shared/components/easy_form/easy_form_context";
import FixedTextField from "../../shared/components/fixed_text_field";
import deepGet from "../../shared/utils/deep_get";

export interface AddressFragmentProps{
    prefix?: string;
    defaultReadMode?: boolean;
    readOnly?: boolean;
    label?: string;
}

export default function AddressFragment(props: AddressFragmentProps){
    const prefix = props.prefix || 'address';
    const context = useContext(EasyFormContext);
    const [key, setKey] = useState(1);
    const [readMode, setReadMode] = useState(props.defaultReadMode);
    const [addressString, setAddressString] = useState('');
    const readOnly = props.readOnly || context.easyFormProps.readOnly || context.easyFormProps.disabled;

    const makeAddressString = () => {
        const values = context.formConfig.getValues();
        const t = (before: string, sufix: string, next: string = '') => {
            const v = deepGet(`${prefix}.${sufix}`, values);
            return v ? before + v + next : '';
        }
        return `${t('', 'street')}${t(', nº ', 'number')} - ${t('','neighborhood')}${t(', ','city')}${t(' - ','state')}${t('/','country')}${t(', ','zip')}`;
    }

    useEffect(() => {
        setAddressString(makeAddressString());
    }, []);
    useEffect(() => {
        if(context.formConfig.formState.isSubmitting && context.formConfig.formState.isSubmitSuccessful){
            setAddressString(makeAddressString());
            setReadMode(true);
        }
    }, [context.formConfig.formState.isSubmitting]);

    return readMode ? (
        <Fragment key={key}>
            <FixedTextField 
                label={props.label || 'Endereço'}
                value={addressString}
                readOnly
                InputProps={{ 
                    endAdornment: readOnly ? undefined : (
                        <InputAdornment position="end">
                            <IconButton color="primary" onClick={() => setReadMode(false)}>
                                <Edit/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Fragment>
    ) : (
        <Fragment key={key}>
            <EasyFormInput field={`${prefix}.zip`} updateAddress={() => setKey(Date.now())}/>
            <EasyFormInput field={`${prefix}.number`}/>
            <EasyFormInput field={`${prefix}.street`}/>
            <EasyFormInput field={`${prefix}.complement`}/>
            <EasyFormInput field={`${prefix}.neighborhood`}/>
            <EasyFormInput field={`${prefix}.city`}/>
            <EasyFormInput field={`${prefix}.state`}/>
            <EasyFormInput field={`${prefix}.country`}/>
        </Fragment>
    )
}