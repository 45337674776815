import { Checkbox as MuiCheckbox, FormControlLabel, FormControlLabelProps } from "@mui/material";
import { useState, ChangeEvent, SyntheticEvent } from "react";
import styled from "@emotion/styled";


export interface CheckboxProps extends Omit<FormControlLabelProps, 'value' | 'defaultValue' | 'control'>{
    align?: string;
    defaultValue?: boolean;
    value?: boolean;
    onChange?: (e: SyntheticEvent<Element, Event>, newValue: boolean) => void;
    readOnly?: boolean;
}

export default function Checkbox(props: CheckboxProps){
    const [_value, _setValue] = useState(props.defaultValue || props.value || false);
    const value = props.value != undefined ? props.value : _value;
    const setValue = (e: ChangeEvent, newValue: boolean) => {
        props.onChange?.(e, newValue);
        _setValue(newValue);
    }
    return (
        // @ts-ignore
        <_FormControlLabel
            {...props}
            control={<MuiCheckbox onChange={(e) => !props.readOnly && setValue(e, e.target.checked)} checked={value}/>}
            label={props.label}
        />
    );
}

const _FormControlLabel = styled(FormControlLabel)((p: CheckboxProps) => ({
    alignSelf: p.align,
}));