import Excel from 'exceljs';
import { saveAs } from "file-saver";
import DateToString from '../date_to_string';
import deepGet from '../deep_get';

export default class ExcelGenerator{
    /**
     * 
     * @param {string} nomeArquivo Nome do arquivo para salvamento.
     */
    constructor(nomeArquivo){
        this.workbook = new Excel.Workbook();
        this.nomeArquivo = nomeArquivo;
        this.style = Object.assign(defaultStyles, window.site_config ? window.site_config.excelStyles : undefined);
    }
    /**
     * Cria uma nova tabela dentro da planilha.
     * @param {string} nome Nome apresentado nas abas do excel.
     * @param {string} titulo Título dentro da tabela.
     * @param {string} subtitulo Subtítulo dentro da tabela.
     * @param {Array<{field: string, title: string, type: "date"|"number"|"currency"|"text"|"boolean", dtFormat?: string, valueGetter?: () => any}>} colunas Colunas da tabela.
     * @param {Array<Object>} data Informações da tabela.
     */
    async adicionarTabela(nome, titulo, subtitulo, colunas, data){
        //Cria a tabela
        let worksheet = this.workbook.addWorksheet(nome);
        let currentRow = 1;
        let defaultBorder = {
            bottom: {color: {argb: "FF000000"}, style: "thick"},
            top: {color: {argb: "FF000000"}, style: "thick"},
        }
        let imgWidth = 0;
        if(!colunas) colunas = [];
        //Adiciona título
        if(titulo){
            worksheet.mergeCells(`A${currentRow}:${ExcelGenerator.numeroParaLetra(colunas.length)}${currentRow}`);
            let cell = worksheet.getCell("A"+currentRow);
            cell.value = titulo;
            cell.alignment = {horizontal: "center", vertical: "middle"};
            cell.font = {bold: true, size: 14, color: {argb: this.style.titleColor}};
            cell.fill = {type: "pattern", pattern: "solid", fgColor: {argb: this.style.titleBgColor}};
            cell.border = defaultBorder;
            worksheet.getRow(currentRow).height = 25;
            currentRow++;
        }
        //Adiciona subtítulo
        if(subtitulo){
            worksheet.mergeCells(`A${currentRow}:${ExcelGenerator.numeroParaLetra(colunas.length)}${currentRow}`);
            let cell = worksheet.getCell("A"+currentRow);
            cell.value = subtitulo;
            cell.font = {bold: true, color: {argb: this.style.subtitleColor}};
            cell.fill = {type: "pattern", pattern: "solid", fgColor: {argb: this.style.subtitleBgColor}};
            cell.alignment = {horizontal: "center", vertical: "middle"};
            cell.border = defaultBorder;
            worksheet.getRow(currentRow).height = 17;
            currentRow++;
        }
        //Verifica se existem informações ou colunas para serem inseridas na tabela.
        if(!colunas || colunas.length <= 0||!data || data.length <= 0){
            worksheet.getCell("A"+currentRow).value = "Nenhum dado Relevante.";
            return;
        }
        //Mapeia as colunas da subtabela.
        let columns = colunas.map((c,i)=>{
            let col = worksheet.getColumn(i+1);
            col.width = c.title.length+5;
            /**@type {Excel.TableColumnProperties} */
            let coluna = {
                name: c.title,
                filterButton: true,

            }
            switch (c.type) {
                case "number":
                    col.style = {numFmt: '#,##0.00'};
                    coluna.totalsRowFunction = "sum"; 
                break;
                case "date":
                    coluna.totalsRowFunction = "count";               
                break;
                case "currency":
                    col.style = {numFmt: '"R$" #,##0.00'}
                    coluna.totalsRowFunction = "sum";
                break;
                default:
            }
            return coluna;
        })
        //Mapeia as linhas da subtabela.
        let rows = data.map((d)=>{
            return colunas.map((c,i)=>{
                let v = c.valueGetter ? c.valueGetter({id: d.id, row: d}) : deepGet(c.field, d)
                let str = String(v).length, col = worksheet.getColumn(i+1);
                if(col.width < str+5){
                    col.width = str+5;
                }
                switch(c.type){
                    case 'date':
                        if(v) v = DateToString(new Date(v), 'date');
                    break;
                    case 'boolean':
                        v = v ? 'S' : 'N';
                    break;
                    case 'number': case 'currency':
                        if(!v) v = 0;
                    break;
                    default:
                }
                return v != undefined ? v : "";
            });
        })
        //Corrige a largura total da tabela para evitar sobreposição do título à logo, ou corte no título.
        if(titulo){
            let tamanhoTotal = colunas.reduce((total, atual, i)=>{
                let cl = worksheet.getColumn(i+1);
                return cl && cl.width ? total+cl.width : total;
            },0);
            let tamanhoTitulo = titulo.length+imgWidth+20;
            if(tamanhoTitulo > tamanhoTotal){
                let larguraFaltanteCadaColuna = parseInt((tamanhoTitulo - tamanhoTotal)/colunas.length);
                colunas.forEach((coluna, i)=>{
                    let cl = worksheet.getColumn(i+1);
                    if(cl.width) cl.width+=larguraFaltanteCadaColuna;
                    else cl.width = larguraFaltanteCadaColuna;
                },0);
            }
        }
        //Adiciona a subtabela.
        worksheet.addTable({
            ref: `A${currentRow}`, 
            columns, 
            rows,
            style: {theme: this.style.theme, showRowStripes: true},
            name: nome,
        });
        worksheet.views = [
            {state: "frozen", ySplit: currentRow}
        ]
    }
    /**
     * 
     * @param {boolean} nsalvar 
     * @returns {Promise<File>}
     */
    gerar(nsalvar){
        return new Promise((resolve,reject)=>{
            if(this.workbook.worksheets.length <= 0){
                this.workbook.addWorksheet("1").getCell("A1").value = "Nenhuma tabela gerada.";
            }
            this.workbook.xlsx.writeBuffer({
                base64: true
            })
            .then(xls64 => {
                const nome = `${this.nomeArquivo}.xlsx`;
                var data = new File([xls64], nome, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                if(!nsalvar) saveAs(data, nome);
                resolve(data);
            })
            .catch(error => {
                reject(new Error("Erro ao gerar excel."));
            }); 
        });
    }
    static numeroParaLetra(numero){
        if(!numero) numero = 1;
        let resp = "";
        while (numero > 0) {
            let resto = 0;
            if(numero > 26) resto = numero - 26;
            switch (numero) {
                case 1: resp+="A"; break;
                case 2: resp+="B"; break;
                case 3: resp+="C"; break;
                case 4: resp+="D"; break;
                case 5: resp+="E"; break;
                case 6: resp+="F"; break;
                case 7: resp+="G"; break;
                case 8: resp+="H"; break;
                case 9: resp+="I"; break;
                case 10: resp+="J"; break;
                case 11: resp+="K"; break;
                case 12: resp+="L"; break;
                case 13: resp+="M"; break;
                case 14: resp+="N"; break;
                case 15: resp+="O"; break;
                case 16: resp+="P"; break;
                case 17: resp+="Q"; break;
                case 18: resp+="R"; break;
                case 19: resp+="S"; break;
                case 20: resp+="T"; break;
                case 21: resp+="U"; break;
                case 22: resp+="V"; break;
                case 23: resp+="W"; break;
                case 24: resp+="X"; break;
                case 25: resp+="Y"; break;
                case 26: resp+="Z"; break;
                default:
            }
            numero = resto;
        }
        return resp;
    }
}
const defaultStyles = {
    theme: "TableStyleMedium16",
    titleBgColor: "FF538DD5",
    titleColor: "FFFFFFFF",
    subtitleBgColor: "FFFFFFFF",
    subtitleColor: "FF000000",
}