import { useContext, cloneElement, PropsWithChildren } from 'react';
import EasyFormContext from './easy_form_context';

export interface EasyFormActionProps extends PropsWithChildren{
    action?: () => void;
}

export default function EasyFormAction(props: EasyFormActionProps){
    const context = useContext(EasyFormContext);
    const clone = cloneElement(props.children as any, {onClick: props.action || context.submit});
    return clone;
}