import { Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import easyDialog, { DialogOkButtonOptions, DialogOptions } from "./index";


export default function Dialog(){
    const [options, setOptions] = useState<DialogOptions>({});
    const [open, setOpen] = useState(false);
    const closeDialog = () => setOpen(false);

    useEffect(()=>{
        easyDialog.setDialog({
            showDialog: (_options: DialogOptions) => {
                setOptions(_options);
                setOpen(true);
            },
            closeDialog,
            okButton: (_options?: DialogOkButtonOptions) => (
                <Button 
                    onClick={_options?.callback || closeDialog} 
                    variant={_options?.variant} color={_options?.color}
                    {..._options?.buttonProps} 
                >
                    {_options?.content || "OK"}
                </Button>
            )
        });
        return () => easyDialog.unsetDialog();
    }, []);

    

    const stringContent = typeof options?.content === "string";

    return (
        <MuiDialog
            open={open}
            onClose={options?.locked ? undefined : closeDialog}
        >
            {options?.title &&
            <DialogTitle>
                {options.title}
            </DialogTitle>}
            <DialogContent>
                <Stack spacing={1} paddingTop={'5px'}>
                    {stringContent 
                    ? <Typography>{options?.content}</Typography>
                    : options?.content}
                </Stack>
            </DialogContent>
            {options?.buttons && 
            <DialogActions>
                {options?.buttons}
            </DialogActions>}
        </MuiDialog>
    );
}