import { CachedSelect } from "../models/pag_select";
import fetcher from "../utils/fetcher";
import { DBRefEasyFormSelectValues } from "../components/dbref_input";
import { getAuthUid } from "../utils/fetcher/token_manager";
import forage from "../stores/cache";

export interface CRUDItem<T>{
    uid?: string;
    data: T;
}

export type DBRefLabel<T> = string | ((item: CRUDItem<T>) => string);

export interface CRUDItemList<T>{
    [uid: string]: CRUDItem<T>;
}

export type CRUDServiceSignature<T> = {
    new (): CRUDService<T>;
}

export default abstract class CRUDService<T>{
    public readonly prefix: string;
    public readonly cachePrefix: string;

    constructor(prefix: string){
        this.prefix = prefix;
        this.cachePrefix = `${getAuthUid()||'guest'}_${prefix}`;
    }

    parseCRUDItem(item: any): CRUDItem<T>{
        return {
            data: item.data,
            uid: item.uid || item.path?.split("/").pop(),
        }
    }

    parseCRUDList(list: {list: {[key: string]: any}, last: EpochTimeStamp}){
        const okList: CRUDItemList<T> = {};
        for(let [key, data] of Object.entries(list.list)){
            okList[key] = {
                data: data,
                uid: key,
            }
        }

        return okList;
    }

    protected async _parseMiddlewareItem(resp: Response){
        return this.parseCRUDItem(await resp.json());
    }

    protected parseMiddlewareItem = (resp: Response) => this._parseMiddlewareItem(resp);

    create(item: CRUDItem<T>): Promise<CRUDItem<T>>{
        return fetcher(this.prefix, 'POST', item.data)
        .then(this.parseMiddlewareItem);
    }
    
    async list(): Promise<CRUDItemList<T>> {
        const LAST_KEY = `${this.cachePrefix}_last`;
        const CACHE_KEY = `${this.cachePrefix}_cache`;

        let last = await forage.getItem(LAST_KEY) || 0;
        let list: {[uid: string]: CRUDItem<T>} = await forage.getItem(CACHE_KEY) || {};
        try{
            const newList: CachedSelect = await fetcher(`${this.prefix}?last=${last}`)
            .then(resp => resp.json());
            last = newList.last;
            if(newList.list){
                Object.entries(newList.list)
                .forEach(([uid, item]) => {
                    if(!item){
                        delete list[uid];
                    }
                    else{
                        list[uid] = {
                            uid,
                            data: item,
                        }
                    }
                });
            }
        }
        catch(err){
            console.log(err);
            throw err;
        }

        await Promise.all([
            forage.setItem(LAST_KEY, last),
            forage.setItem(CACHE_KEY, list),
        ]);

        return list;
    }
    
    read(uid: string): Promise<CRUDItem<T>> {
        return fetcher(`${this.prefix}/${uid}`)
        .then(this.parseMiddlewareItem);
    }
    
    async update(item: CRUDItem<Partial<T>>): Promise<CRUDItem<T>> {
        return fetcher(`${this.prefix}/${item.uid}`, 'PATCH', item.data)
        .then(this.parseMiddlewareItem);
    }

    async delete(uid: string): Promise<void> { 
        await fetcher(`${this.prefix}/${uid}`, 'DELETE');
    }

    crudItemToDBRefSelect(labelProp: DBRefLabel<T>, item: CRUDItem<T>){
        const label = typeof labelProp === 'string' ? item.data[labelProp] : labelProp(item);
        return {
            label,
            value: {
                uid: item.uid,
                label,
                _ref: item,
            }
        }
    }
    
    DBRefList(labelProp: DBRefLabel<T>): Promise<DBRefEasyFormSelectValues>{
        return this.list().then(list => Object.values(list).map(item => this.crudItemToDBRefSelect(labelProp, item)));
    }
}