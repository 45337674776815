import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { MultiStockTransferResult } from "../stock/multi_stock_transfer";
import { StockHistoryProps } from "../stock_history/model";
import { SupplierStockProps } from "./model";

export default class SupplierStockService extends CRUDService<SupplierStockProps>{
    constructor(){
        super('supplier_stock');
    }

    protected async _parseMiddlewareItem(resp: Response): Promise<CRUDItem<SupplierStockProps>> {
        const parsed = await super._parseMiddlewareItem(resp);
        parsed.data._originalAmount = parsed.data.amount;
        return parsed;
    }

    update(item: CRUDItem<Partial<SupplierStockProps>>): Promise<CRUDItem<SupplierStockProps>> {
        item.data.lastOpAmountDiff = item.data.amount! - item.data._originalAmount!;
        if(!item.data.lastOpAmountDiff && item.data.lastOpAmountDiff !== 0){
            return Promise.reject(new Error('Quantidade inválida.'));
        }
        return super.update(item);
    }

    stockFromSupplier(config: MultiStockTransferResult): Promise<CRUDItem<StockHistoryProps>[]>{
        const data = config.stocks.map(s => ({
            productUid: s.stock.product.uid,
            amount: s.amount,
            storeUid: s.stock.store.uid,
            //@ts-ignore
            date: s.stock.date,
        }))

        return fetcher(this.prefix + '/stockFromSupplier', 'PATCH', {storeUid: config.store?.uid, items: data})
        .then(r => r.json());
    }
}