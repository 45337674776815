import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Delete, Done, DoNotDisturb, Key } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import AddressFragment from "../address/address_fragment";
import ControlInfo from "../../shared/components/control_info";
import { UserFields, UserProps } from "./model";
import UserService from "./service";
import dialogHelper from "../../shared/components/easy_dialog";
import PasswordTextField from "../../shared/components/password_text_field";

const askPassword = (): Promise<string | null> => new Promise((resolve, reject) => {
    let v: string = '';
    dialogHelper.showDialog({
        title: 'Defina a nova senha',
        content: (
            <PasswordTextField onChange={(e) => v = e.target.value} label="Nova senha"/>
        ),
        buttons: [
            dialogHelper.okButton({content: 'CANCELAR', callback: () => {
                dialogHelper.closeDialog();
                resolve(null);
            }}),
            dialogHelper.okButton({content: 'CONTINUAR', callback: () => {
                dialogHelper.closeDialog();
                resolve(v);
            }}),
        ]
    })
});

export default function UserEdit(props: any){
    const create = useCRUDEdit<UserProps, UserService>({
        props,
        fieldsGen: UserFields,
        CRUDService: UserService,
    });

    if(create.initializing){
        return <ScreenLoading/>
    }

    const changePassword = () => {
        askPassword()
        .then(newPassword => {
            if(newPassword !== null){
                dialogHelper.showLoading();
                create.crudService.changePassword(create.uid!, newPassword)
                .then(() => dialogHelper.showDialog({
                    content: "Senha alterada com sucesso!", 
                    buttons: dialogHelper.okButton()
                }))
                .catch(dialogHelper.showError)
            }
        })
    }
    
    const submit = async (data: any) => {
        if(create.uid){
            create.easyFormProps.onSubmit(data);
        }
        else{
            const password = await askPassword();
            if(password !== null){
                data.password = password;
                create.easyFormProps.onSubmit(data);
            }
        }
    }

    const disableSwitch = () => {
        dialogHelper.showLoading();
        create.crudService.disableSwitchUser(create.uid!)
        .then(newData => {
            create.setData(newData.data);
            dialogHelper.closeDialog();
        })
        .catch(dialogHelper.showError);
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps} onSubmit={submit}>
                <Stack spacing={1}>
                    <Typography>
                        {!create.uid && 'CRIAR'} USUÁRIO
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <EasyFormInput field="name"/>
                    <EasyFormInput field="login"/>
                    <EasyFormInput field="cpf"/>
                    <EasyFormInput field="phone"/>
                    <EasyFormInput field="email"/>
                    <EasyFormInput field="group"/>
                    <EasyFormInput 
                        field="configs.store" 
                        onChange={(e, f) => {
                            const zoneRef = e?.target.value?._ref?.data?.zone || null;
                            f?.formConfig.setValue('configs.zone', zoneRef);
                        }}
                    />
                    <EasyFormInput field="configs.zone"/>
                    <Divider><Chip label="ENDEREÇO"/></Divider>
                    <AddressFragment defaultReadMode={Boolean(create.uid)}/>
                    <EasyFormWriteOnly>
                        <Divider><Chip label="AÇÕES"/></Divider>
                        <EasyFormAction>
                            <Button fullWidth color="success">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                        {create.uid ? 
                        <Button 
                            startIcon={create.data.disabled ? <Done/> : <DoNotDisturb/>} 
                            color={create.data.disabled ? 'success' : 'error'} 
                            onClick={disableSwitch}
                        >
                            {create.data.disabled ? 'HABILITAR' : 'DESABILITAR'} USUÁRIO
                        </Button>
                        : undefined}
                        {create.uid ? 
                        <Button startIcon={<Key/>} onClick={changePassword}>
                            ALTERAR SENHA
                        </Button> : undefined}
                    </EasyFormWriteOnly>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}