import { Stack, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Centered from "../../shared/components/centered";
import useAuthService from "../../shared/services/auth_service";

export default function Logout(){
    const service = useAuthService();
    const navigate = useNavigate();
    useEffect(() => {
        service.logout()
        .then(() => navigate('/', {replace: true}))
    }, []);

    return (
        <Centered>
            <Stack alignItems="center">
                <CircularProgress />
            </Stack>
        </Centered>
    )
}