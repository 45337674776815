import { createContext, useContext } from "react";
import { SellProps } from "../sell/model";
import { CRUDItem } from "../../shared/crud/crud_service";
import { InstallmentProps } from "../installment/model";


export interface SellWizardContextValue{
    sell: SellProps;
    installment?: CRUDItem<InstallmentProps>;
    steps: {name: string}[];
}

const SellWizardContext = createContext({} as SellWizardContextValue);

export const useSellWizardContext = () => useContext(SellWizardContext);

export default SellWizardContext;

