import CRUDService from "../../shared/crud/crud_service";
import fetcher from "../../shared/utils/fetcher";
import { StoreProps } from "./model";

export default class StoreService extends CRUDService<StoreProps>{
    constructor(){
        super('store');
    }

    getDescription(storeUid: string){
        return fetcher(this.prefix + '/getDescription/' + storeUid)
        .then(this.parseMiddlewareItem);
    }

    hasMainStore(storeUid: string){
        return fetcher(`${this.prefix}/hasMainStore/${storeUid}`)
        .then(this.parseMiddlewareItem)
        .catch(() => null);
    }

    async delete(uid: string, options?: {deleteStock: boolean}): Promise<void> { 
        await fetcher(`${this.prefix}/${uid}`, 'DELETE', options||{});
    }
}