import { StandardTextFieldProps, TextField } from "@mui/material";
import { forwardRef } from "react";

export interface FixedTextFieldProps extends Omit<StandardTextFieldProps, 'variant'>{
    readOnly?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    enableComplete?: boolean;
}

const FixedTextField = forwardRef(function FixedTextField(props: FixedTextFieldProps, ref: any){
    return (
        <TextField
            {...props}
            inputProps={{ 
                autoCapitalize: 'off',
                autoCorrect: props.enableComplete ? 'on' : 'off',
                autoComplete: props.enableComplete ? 'on' : 'off',
                ...props.inputProps,
            }}
            InputProps={{ 
                ...props.InputProps,
                readOnly: props.readOnly,
            }}
            ref={ref}
        />
    )
});

export default FixedTextField;