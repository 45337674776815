import dialogHelper from "../../../shared/components/easy_dialog";
import { DBRef } from "../../../shared/models/model";
import { store } from "../../../shared/stores/root_store/store";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import { AddressProps, makeAddressString } from "../../address/model";
import StockService from "../../stock/service";
import { StoreProps } from "../../store/model";
import StoreService from "../../store/service";

function compare(item1: any, item2: any){
    const a = String(item1.data.product.label||'').toLowerCase();
    const b = String(item2.data.product.label||'').toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
}

export default function StockReportPrint(storedb: DBRef<StoreProps, 'name'>){
    dialogHelper.showLoading();
    const project = store.getState().project;
    Promise.all([
        new StoreService().getDescription(storedb.uid!),
        new StockService().list()
    ]).then(([store, stocks]) => {
        DocumentTemplatePrinter('stock_report.html', {
            logoURL: project.project?.ui.logo_url!,
            storeDescription: store.data.description!,
            storeAddress: makeAddressString(store.data.address||{} as AddressProps),
            storeSocial: store.data.social!,
            storePhone: store.data.phone!,
            storeName: store.data.name,
            currentDate: DateToString(new Date(), 'datetime'),
            stock_items: Object.values(stocks)
                        .filter(s => s.data.store.uid === storedb.uid)
                        .sort(compare)
                        .map(s => `<tr><td>${s.data.product.label}</td><td>${s.data.amount}</td><td></td></tr>`).join('')
        });
        dialogHelper.closeDialog();
    })
    .catch(dialogHelper.showError);
}