import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";

export default function CheckoutDetails(props: any){

    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
        nextCallback: async () => undefined,
    }); 

    const [fields] = useState(() => ModelFieldsFilter(PhoneSOFields({
        defaults: wizard.data.data,
    }), ['serviceCompleted', 'problemsFound', 'testsMade', 'resolution']));


    return (
        <Stack flex={1}>
            <EasyForm onSubmit={(data: Partial<PhoneSOProps>) => {
                DeepAssign(wizard.data.data, data);
                wizard.submit();
            }} fields={fields} flex1>
                <ScreenPaper flex1>
                    <Typography align="center">
                        ORDEM DE SERVIÇO
                    </Typography>
                    <Typography align="center">
                        Observações
                    </Typography>
                    <EasyFormInput field="serviceCompleted"/>
                    <EasyFormInput field="problemsFound" required/>
                    <EasyFormInput field="testsMade" required/>
                    <EasyFormInput field="resolution" required/>
                </ScreenPaper>
                <WizardNav wizard={wizard} useFormSubmit/>
            </EasyForm>
        </Stack>
    )
}