import { DependencyList, useMemo } from "react";
import { useProject } from "../../stores/project_slice";
import deepGet from "../../utils/deep_get";

export default function useDomain<T = boolean>(domain: string, subpath: string = 'enabled', deps: DependencyList = []){
    const project = useProject();
    const config = useMemo<T>(() => {
        const path = subpath ? `${domain}.${subpath}` : domain;
        return deepGet(path, project.project?.domains);
    }, [project, ...deps]);
    
    return config;
}