import Centered from "../../shared/components/centered";
import { CircularProgress, Stack } from "@mui/material";
import { useProject } from "../../shared/stores/project_slice";

export default function Splash(){
    const project = useProject();
    const url = project.project?.ui.logo_url;
    return (
        <Centered maxWidth="xs">
            <Stack spacing={2} alignItems="center">
                {url ? <img src={url} style={{width: '100%'}} alt="logo"/> : undefined}
                <CircularProgress color="primary"/>
            </Stack>
        </Centered>
    )
}