import { Button, Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Checkbox from "../../../shared/components/checkbox";
import dialogHelper from "../../../shared/components/easy_dialog";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import WizardScreenProps from "../../../shared/components/wizard/wizard_screen_props";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { checkListItems, PhoneSOFields, PhoneSOProps } from "../model";
import PhoneSOService from "../service";

export default function Summary(props: WizardScreenProps){
    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
    });

    const [fields] = useState(() => PhoneSOFields({
        defaults: wizard.data.data,
    }));

    const submit = () => {
        wizard.data.data.dateIncome = new Date();
        wizard.data.data.status = 'ABERTO';
        const phoneSoService = new PhoneSOService();
        dialogHelper.showLoading();
        phoneSoService.create(wizard.data)
        .then((newData) => {
            Object.assign(wizard.data, newData);
            dialogHelper.closeDialog();
            wizard.submit();
        })
        .catch(dialogHelper.showError);
    }

    return (
        <Stack flex={1}>
            <EasyForm onSubmit={() => {}} readOnly fields={fields}>
                <ScreenPaper>
                    <Typography align="center">
                        ORDEM DE SERVIÇO
                    </Typography>
                    <Typography align="center">
                        Resumo
                    </Typography>
                    <EasyFormInput field="clientName"/>
                    <EasyFormInput field="clientId"/>
                    <EasyFormInput field="clientPhone"/>
                    <EasyFormInput field="deviceBrand"/>
                    <EasyFormInput field="deviceModel"/>
                    <EasyFormInput field="imei"/>
                    <EasyFormInput field="sellerObs"/>
                    <EasyFormInput field="suggestedValue"/>
                    <Divider>
                        DEFEITOS
                    </Divider>
                    <Box display="flex" flexWrap='wrap'>
                        {checkListItems.map(key => wizard.data.data.checkList?.[key] && (
                            <Checkbox
                                label={key}
                                key={key}
                                readOnly
                                value={true}
                            />
                        ))}
                    </Box>
                    <Button color="primary" onClick={submit}>
                        CRIAR OS
                    </Button>
                </ScreenPaper>
            </EasyForm>
        </Stack>
    )
}