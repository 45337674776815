import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { EasyForm, EasyFormInput } from "../../../shared/components/easy_form";
import ScreenPaper from "../../../shared/components/screen_paper";
import { useWizard, WizardNav } from "../../../shared/components/wizard";
import { CRUDItem } from "../../../shared/crud/crud_service";
import DeepAssign from "../../../shared/utils/deep_assign";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { PhoneSOFields, PhoneSOProps } from "../model";

export default function FinishDetails(props: any){

    const wizard = useWizard<CRUDItem<PhoneSOProps>>({
        name: props.name,
        nextCallback: async () => undefined,
    }); 

    const [fields] = useState(() => ModelFieldsFilter(PhoneSOFields({
        defaults: {
            suggestedValue: wizard.data.data.suggestedValue,
            totalValue: wizard.data.data.totalValue,
        },
    }), ['suggestedValue', 'totalValue', 'costValue', 'warranty']));

    return (
        <Stack flex={1}>
            <EasyForm onSubmit={(data: Partial<PhoneSOProps>) => {
                DeepAssign(wizard.data.data, data);
                wizard.submit();
            }} fields={fields} flex1>
                <ScreenPaper flex1>
                    <Typography align="center">
                        Saída de OS
                    </Typography>
                    <Typography align="center">
                        Informe o valor final e garantia do serviço.
                    </Typography>
                    <EasyFormInput field="suggestedValue" readOnly/>
                    <EasyFormInput field="costValue" required/>
                    <EasyFormInput field="totalValue" required/>
                    <EasyFormInput field="warranty" required/>
                </ScreenPaper>
                <WizardNav wizard={wizard} useFormSubmit/>
            </EasyForm>
        </Stack>
    )
}