import dialogHelper from "../../../shared/components/easy_dialog";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { store } from "../../../shared/stores/root_store/store";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import parseBRL from "../../../shared/utils/parse_brl";
import { AddressProps, makeAddressString } from "../../address/model";
import { DailySummaryProps, DailySummaryValues } from "../../billing2/models";
import { CashRemittanceOpReason, CashRemittanceProps } from "../../cash_remittance/model";
import { paymentStringify } from "../../payment/model";
import { StockHistoryProps } from "../../stock_history/model";
import StoreService from "../../store/service";
const keys = ['invoice', 'cost', 'profit', 'tax', 'money', 'pix', 'debt', 'credit', 'totalSells', 'totalSO', 'amountSO', 'amountSells', 'amountStockHistories', 'amountCashRemittances'];
function treatValues(v: Partial<DailySummaryValues>){
    const treated: any = {};
    for(let key of keys){
        const value = v[key] || 0;
        treated[key] = key.match('amount') ? value : parseBRL(value);
    }
    return treated;
}

export default function DailySummaryConclusion(item: CRUDItem<DailySummaryProps>, crList: CRUDItem<CashRemittanceProps>[], options?: any){
    dialogHelper.showLoading();
    const project = store.getState().project;
    new StoreService().getDescription(item.data.store.uid)
    .then(store => {
        DocumentTemplatePrinter('daily_summary_conclusion.html', {
            logoURL: project.project?.ui.logo_url!,
            storeDescription: store.data.description,
            storeAddress: makeAddressString(store.data.address||{} as AddressProps),
            storeSocial: store.data.social,
            storePhone: store.data.phone,
            storeName: store.data.name,
            currentDate: DateToString(item.data.currentDate, 'date'),
            openByName: item.data.openBy?.label!,
            closedByName: item.data.closedBy?.label!,
            openIn: DateToString(item.data.openIn!, 'datetime'),
            closedIn: DateToString(item.data.closedIn!, 'datetime'),
            ...treatValues(item.data.closedValues || {}),
            crList: crList.map(cr => (
                `<tr>
                    <td>${CashRemittanceOpReason[cr.data.lastOpReason]}</td>
                    <td>${DateToString(cr.data.crtAt, 'time')}</td>
                    <td>${paymentStringify(cr.data.values, 0, true)}</td>
                    <td>${cr.data.lastOpObs||''}</td>
                </tr>`
            ))
        });
        dialogHelper.closeDialog();
    })
    .catch(dialogHelper.showError);
}