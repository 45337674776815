import { useContext, Fragment, PropsWithChildren } from 'react';
import EasyFormContext from './easy_form_context';

export interface EasyFormWriteOnlyProps extends PropsWithChildren{
    action?: () => void;
    disabled?: boolean;
    readOnly?: boolean;
}

export default function EasyFormWriteOnly(props: EasyFormWriteOnlyProps){
    const context = useContext(EasyFormContext);
    if(props.disabled || props.readOnly || context.easyFormProps.disabled || context.easyFormProps.readOnly){
        return null;
    }

    return <Fragment>{props.children}</Fragment>
}