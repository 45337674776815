import CRUDService, { CRUDItem } from "../../shared/crud/crud_service";
import { DBRef } from "../../shared/models/model";
import fetcher from "../../shared/utils/fetcher";
import { PhoneSOProps } from "../phone_so/model";
import { ProductProps } from "../product/model";
import { CreateTransferProps, TransferProps } from "./model";


export default class TransferService extends CRUDService<TransferProps>{
    constructor(){
        super('transfer');
    }

    approve(transfer: CRUDItem<TransferProps>){
        return fetcher(`${this.prefix}/approve/${transfer.uid}`, 'PATCH')
        .then(this.parseMiddlewareItem);
    }

    reject(transfer: CRUDItem<TransferProps>){
        return fetcher(`${this.prefix}/reject/${transfer.uid}`, 'PATCH')
        .then(this.parseMiddlewareItem);
    }

    createStockTransfer(props: Omit<CreateTransferProps, 'targetUser'> & {amount: number, product: DBRef<ProductProps, 'name'>}){
        const transfer: CRUDItem<Partial<TransferProps>> = {
            data: {
                props: {
                    amount: props.amount,
                    product: props.product,
                },
                typeCode: 'stock_transfer',
                sourceStore: props.sourceStore,
                sourceZone: props.sourceZone,
                sourceUser: props.sourceUser,
                targetStore: props.targetStore,
                targetZone: props.targetZone,
            }
        }

        return this.create(transfer as CRUDItem<TransferProps>);
    }

    createSoTransfer(props: Omit<CreateTransferProps, 'typeCode'> & {targetStatus: string, phoneSo: DBRef<PhoneSOProps, 'crtAt', {deviceName?: string}>}){
        const transfer: CRUDItem<Partial<TransferProps>> = {
            data: {
                props: {
                    phoneSo: props.phoneSo,
                    targetStatus: props.targetStatus,
                },
                typeCode: 'so_transfer',
                sourceStore: props.sourceStore,
                sourceZone: props.sourceZone,
                sourceUser: props.sourceUser,
                targetStore: props.targetStore,
                targetZone: props.targetZone,
                targetUser: props.targetUser,
            }
        }

        return this.create(transfer as CRUDItem<TransferProps>);
    }
}