import { Fragment, useEffect, useMemo, useState } from "react"
import Reports from "./reports"
import useAuth from "../../shared/hooks/use_auth"
import SelectInput from "../../shared/components/select_input";
import { Report, ReportResult } from "./model";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../shared/components/easy_form";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import dialogHelper from "../../shared/components/easy_dialog";

export interface NewReportProps{
    onReport: (r: ReportResult) => void;
}

export default function NewReport(props: NewReportProps){
    const [open, setOpen] = useState(false);
    const auth = useAuth();
    const [reports, setReports] = useState<typeof Reports>([]);
    const [lastOpts, setLastOpts] = useState();
    useEffect(() => {
        if(!auth.user) return;
        Promise.all(Reports.map(r => r.checkPerm(auth.user!)))
        //@ts-ignore
        .then(r => setReports(r.map((ok, i) => ok && Reports[i]).filter(ok => ok)));
    }, [auth.user]);

    const [selected, setSelected] = useState<Report<any, any>>();

    const changeSelected = (e: any) => {
        setSelected(e.target.value as any);
        setLastOpts(undefined);
    }

    const close = () => {
        setOpen(false);
        //setSelected(undefined);
    }

    const submit = async (options: any) => {
        if(!selected) return;
        try{
            dialogHelper.showLoading();
            const validatedOptions = await selected?.validateOptions(options);
            const values = await selected.process(validatedOptions);
            props.onReport?.({
                reportType: selected,
                values: values,
                options,
            });
            setLastOpts(options);
            dialogHelper.closeDialog();
            close();
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    }

    return (
        <Fragment>
            <Dialog open={open}>
                <DialogTitle>
                    Novo relatório
                </DialogTitle>
                <EasyForm fields={selected?.options||{}} onSubmit={submit} key={selected?.name} defaultValues={lastOpts}>
                    <DialogContent>
                        <Stack style={{minWidth: '200px'}} spacing={1}>
                            <SelectInput
                                options={reports?.map(r => ({label: r.name, value: r}))}
                                onChange={changeSelected}
                                label='Tipo de Relatório' fullWidth
                                value={selected}
                            />
                                {selected && Object.keys(selected.options).map(o => (
                                    <EasyFormInput field={o} key={o}/> 
                                ))}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" color="inherit" onClick={close}>
                            FECHAR
                        </Button>
                        <EasyFormAction>
                            <Button color="success" type="submit">
                                OK
                            </Button>
                        </EasyFormAction>
                    </DialogActions>
                </EasyForm>
            </Dialog>
            <Button fullWidth onClick={() => setOpen(true)}>
                GERAR NOVO RELATÓRIO
            </Button>
        </Fragment>
    )
}