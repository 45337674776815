import { CleaningServices, Done } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import Sleep from "../../utils/sleep";
import dialogHelper from "../easy_dialog";
import forage from "../../stores/cache";

const CurrentStateProps: {[key: string]: any} = {
    'idle': {
        children: 'LIMPAR CACHE',
        startIcon: <CleaningServices/>
    },
    'loading': {
        children: 'LIMPANDO',
        startIcon: <CircularProgress color="inherit" size={20}/>,
        onClick: () => {},
        variant: 'outlined',
        disableRipple: true,
    },
    'success': {
        children: 'SUCESSO!',
        startIcon: <Done/>,
        onClick: () => {},
        color: 'success',
        disableRipple: true,
    }
}

export default function CleanCache(){
    const [currentState, setCurrentState] = useState('idle');

    const run = () => {
        setCurrentState('loading');
        Promise.all([
            forage.clear(),
            Sleep(2000),
        ])
        .then(() => {
            setCurrentState('success');
            Sleep(2000).then(() => setCurrentState('idle'));
        })
        .catch((err) => {
            dialogHelper.showError(err);
            setCurrentState('idle');
        })
    }

    const props = CurrentStateProps[currentState];

    return (
        <Button onClick={run} {...props} variant="text"/>
    )
}