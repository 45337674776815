import React, { ComponentType, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import WizardContext, { WizardContextValue } from "./wizard_context";
import WizardScreenProps from "./wizard_screen_props";

export interface WizardRootProps<DType>{
    data: DType;
    screens: {name: string, Component: ComponentType<WizardScreenProps>}[];

}

export default function WizardRoot<DType = any>(props: WizardRootProps<DType>){
    const navigate = useNavigate();
    const [value, setValue] = useState<WizardContextValue<DType>>(() => ({
        data: props.data,
        screenNames: props.screens.map(s => s.name),
    }));
    
    useEffect(() => {
        navigate(value.screenNames[0]||'.', {replace: true});
    },[]);

    return (
        <WizardContext.Provider value={value}>
            <Routes>
                {props.screens.map((item) => <Route element={<item.Component name={item.name}/>} path={item.name}/>)}
            </Routes>
        </WizardContext.Provider>
    )
}