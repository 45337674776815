import { Container, Stack, Paper } from "@mui/material";
import { PropsWithChildren } from "react";

export interface ScreenPaperProps extends PropsWithChildren{
    padding?: number;
    spacing?: number;
    flex1?: boolean;
}

export default function ScreenPaper(props: ScreenPaperProps){
    const flex = props.flex1 ? '1' : undefined;
    return (
        <Container component={Stack} padding={1} sx={containerSX} flex={flex}>
            <Paper component={Stack} padding={props.padding || 1} spacing={props.spacing || 1} flex={flex}>
                {props.children}
            </Paper>
        </Container>
    )
}

const containerSX = { display: 'flex' };