import { DatedProps, DBRef } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import { Photo } from "../file/model";
import { PaymentMethods, PaymentValues } from "../payment/model";
import StoreDBRef from "../store/dbref";
import { StoreProps } from "../store/model";
import UserDBRef from "../user/dbref";
import { UserProps } from "../user/model";
import ZoneDBRef from "../zone/dbref";
import { ZoneProps } from "../zone/model";
import PhoneSoFilteredUserDBRef from "./phone_so_filtered_user_dbref";


export interface PhoneSOProps extends DatedProps{
    status: string;
    clientName: string;
    clientPhone?: string;
    clientId: string;
    clientAddress: string;
    imei: string;
    deviceModel: string;
    deviceBrand: string;
    dateIncome: Date;
    dateOutcome?: Date;
    dateCancel?: EpochTimeStamp;
    cancelAfterPay?: boolean;
    warranty?: string;
    totalValue?: number;
    suggestedValue: number;
    costValue?: number;
    payment: PaymentMethods;
    checkList: {[key: string]: any};
    checkListTech?: {[key: string]: any};
    serviceObs?: string;
    sellerObs?: string;
    techObs?: string;
    otherObs?: string;
    assignedTech?: DBRef<UserProps, 'name'>;
    assignedReceiver?: DBRef<UserProps, 'name'>;
    whoAssignedTech?: DBRef<UserProps, 'name'>;
    whoAssignedReceiver?: DBRef<UserProps, 'name'>;
    problemsFound?: string;
    testsMade?: string;
    resolution?: string;
    serviceCompleted?: boolean;
    photos?: {[key: number]: Photo};
    cancelReason?: string;
    cancelled?: boolean;
    reverted?: boolean;
    seller: DBRef<UserProps, 'name'>;
    store: DBRef<StoreProps, 'name'>;
    zone: DBRef<ZoneProps, 'name'>;
    tax?: PaymentValues;
}

export const PhoneSOFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        status: {
            field: "status",
            type: "select",
            label: "Status",
            required: true,
            options: Object.keys(status_list).map(key => ({value: key, label: key})),
        },
        "clientName": {
            "field": "clientName",
            "type": "text",
            "label": "Cliente",
            required: true,
        },
        clientPhone: {
            field: "clientPhone",
            type: "maskedtext",
            label: "Telefone do Cliente",
            mask: '(00) 9 0000-0000',
        },
        "clientId": {
            "field": "clientId",
            "type": "maskedtext",
            "mask": '000.000.000-00',
            "label": "CPF",
            required: true,
        },
        "clientAddress": {
            "field": "clientAddress",
            "type": "text",
            "label": "Endereço",
            required: true,
        },
        "imei": {
            "field": "imei",
            "type": "text",
            "label": "IMEI",
            required: true,
        },
        "deviceBrand": {
            "field": "deviceBrand",
            "type": "text",
            "label": "Marca",
            required: true,
        },
        "deviceModel": {
            "field": "deviceModel",
            "type": "text",
            "label": "Modelo",
            required: true,
        },
        "dateIncome": {
            "field": "dateIncome",
            "type": "datetime",
            "label": "Data de entrada",
            required: true,
        },
        "dateOutcome": {
            "field": "dateOutcome",
            "type": "datetime",
            "label": "Data de saída",
        },
        "warranty": {
            "field": "warranty",
            "type": "text",
            "label": "Garantia",
        },
        "totalValue": {
            "field": "totalValue",
            "type": "number",
            "label": "Valor total",
        },
        "suggestedValue": {
            "field": "suggestedValue",
            "type": "number",
            "label": "Valor Sugerido",
        },
        "costValue": {
            "field": "costValue",
            "type": "number",
            "label": "Valor de Custo",
        },
        "serviceObs": {
            "field": "serviceObs",
            "type": "text",
            "label": "Serviço",
        },
        "sellerObs": {
            "field": "sellerObs",
            "type": "multiline",
            "label": "Observações",
        },
        "techObs": {
            "field": "techObs",
            "type": "multiline",
            "label": "OBS. Tecnico",
        },
        "otherObs": {
            "field": "otherObs",
            "type": "multiline",
            "label": "OBS. Outros",
        },
        "assignedTech": {
            "field": "assignedTech",
            "type": "dbref",
            "label": "Técnico",
            component: UserDBRef,
        },
        "assignedReceiver": {
            field: 'assignedReceiver',
            type: "dbref",
            label: 'Responsável por Checkout',
            component: UserDBRef,
        },
        "problemsFound": {
            "field": "problemsFound",
            "type": "multiline",
            "label": "Problemas encontrados",
        },
        "testsMade": {
            "field": "testsMade",
            "type": "multiline",
            "label": "Testes Feitos",
        },
        "resolution": {
            "field": "resolution",
            "type": "multiline",
            "label": "Resolução",
        },
        "serviceCompleted": {
            "field": "serviceCompleted",
            "type": "bolselect",
            "label": "Serviço Concluído",
        },
        "cancelReason": {
            "field": "cancelReason",
            "type": "multiline",
            "label": "Motivo Cancelamento",
        },
        "cancelled": {
            "field": "cancelled",
            "type": "checkbox",
            "label": "Cancelado",
        },
        "reverted": {
            "field": "reverted",
            "type": "checkbox",
            "label": "Revertido",
        },

        seller: {
            field: 'seller',
            type: 'dbref',
            label: 'Vendedor',
            component: UserDBRef,
        },
        store: {
            field: 'store',
            type: 'dbref',
            label: 'Loja',
            component: StoreDBRef,
        },
        zone: {
            field: 'zone',
            type: 'dbref',
            label: 'Zona',
            component: ZoneDBRef,
        },
    }, options)
}

export const checkListItems = [
    "Aparelho não liga",
    "LCD",
    "Touch Screen",
    "Sinal Operadora",
    "Leitor Biométrico",
    "Face ID",
    "Home Button",
    "Flash",
    "Câmera Frontal",
    "Câmera Traseira",
    "Wi-Fi",
    "Bluetooth",
    "Vidro Frontal",
    "Botão Volume",
    "Botão Silenciar",
    "Microfone",
    "Auricular",
    "Auto Falante",
    "Vibracall",
    "Vidro Traseiro",
    "3D Touch",
    "Sensor Prox",
    "Conec Carga",
    "Bateria",
    "Botão Power",
    "Consumo",
    "SMB",
    "Indução",
    "Carcaça",
    "Reiniciando",
    "Inativo",
    "Tampa",
    "Cartão de Memória",
    "Chip",
    "Amassado",
    "Gaveta Chip",
    "Arranhão",
    "Lente da Câmera"
];

export interface SOStatusType{
    code: string;
    next: {[key: string]: true};
}

export const status_list = {
    'ABERTO': {
        code: 'ABERTO',
        next: {
            'MANUTENÇÃO': true,
            'CANCELADO': true,
        }
    },
    'MANUTENÇÃO': {
        code: 'MANUTENÇÃO',
        next: {
            'CHECKOUT': true,
        },
    },
    'CHECKOUT': {
        code: 'CHECKOUT',
        next: {
            'RETIRADA': true,
        },
    },
    'RETIRADA': {
        code: 'RETIRADA',
        next: {
            'CONCLUÍDO': true,
            'CANCELADO': true,
        }
    },
    'CONCLUÍDO': {
        code: 'CONCLUÍDO',
        next: {},
    },
    'CANCELADO': {
        code: 'CANCELADO',
        next: {},
    },
}