import { Routes } from "react-router-dom";
import MakeCRUDRoutes from "../../shared/crud/make_crud_routes";
import { Settings } from "@mui/icons-material";
import GeneralConfigsEdit from "./edit";

export default function ProductModule(){
    const crudRoutes = MakeCRUDRoutes({
        perm: 'general_configs',
        routes: {
            list: {Component: GeneralConfigsEdit},
        }
    });

    return (
        <Routes>
            {crudRoutes}
        </Routes>
    )
}

export const routeConfig: RouteConfig = {
    key: 'general_configs',
    label: 'Configurações',
    Icon: Settings,
}