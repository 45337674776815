import dialogHelper from "../../../shared/components/easy_dialog";
import { store as rootStore } from "../../../shared/stores/root_store/store";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import parseBRL from "../../../shared/utils/parse_brl";
import { AddressProps, makeAddressString } from "../../address/model";
import GeneralConfigsService from "../../general_configs/service";
import { paymentStringify } from "../../payment/model";
import { SellProps } from "../../sell/model";
import StoreService from "../../store/service";

export default async function SellPrint(sellUid: string, sell: SellProps, options?: any){

    try{
        dialogHelper.showLoading();
        const project = rootStore.getState().project;
        const store = await new StoreService().getDescription(sell.store.uid);
        const configs = await new GeneralConfigsService().getCached();
        DocumentTemplatePrinter('sell.html', {
            logoURL: project.project?.ui.logo_url!,
            UID: sellUid,
            clientName: sell.clientName,
            description: sell.description!,
            storeSocial: store.data.social!,
            storePhone: store.data.phone!,
            storeDescription: store.data.description,
            storeAddress: makeAddressString(store.data.address||{} as AddressProps),
            warranty: sell.warranty,
            crtAt: DateToString(sell.crtAt, 'date'),
            totalValue: parseBRL(sell.totalValue),
            paymentString: paymentStringify(sell.payment, sell.totalValue),
            seller: sell.seller.label,
            terms: configs.data?.texts?.terms?.sell || '',
            items: Object.values(sell.items)
                    .map(item => [item.amount, item.product.label, parseBRL(item.sellValue), parseBRL(item.sellValue * item.amount)]
                                    .map(prop => `<td>${prop}</td>`).join('')
                    )
                    .map(row => `<tr>${row}</tr>`)
                    .join(''),
        });
        dialogHelper.closeDialog();
    }
    catch(err: any){
        dialogHelper.showError(err);
    }
}