import { Assessment, Receipt } from "@mui/icons-material";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { ReportResult, ReportSummaries } from "./model";
import ScreenPaper from "../../shared/components/screen_paper";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import NewReport from "./new_report";
import List from "../../shared/components/list";
import dialogHelper from "../../shared/components/easy_dialog";
import DefaultReportPrint from "../print/default_report_print";
import InfoItem from "../../shared/components/info_item";

export const routeConfig: RouteConfig = {
    key: 'report',
    label: 'Relatórios',
    Icon: Assessment,
}

export default function Report(){
    
    const [report, setReport] = useState<ReportResult>();
    
    const [summaries, setSummaries] = useState<ReportSummaries>([]);
    const [filtered, setFR] = useState<any[]>([]);

    useEffect(() => {
        report?.reportType.summaries(report.options, filtered).then(s => setSummaries(s));
    }, [report, filtered]);

    const print = async () => {
        try{
            const rp = await report?.reportType.print(report.options, filtered)!;
            DefaultReportPrint(rp);
        }
        catch(err:any){
            dialogHelper.showError(err);
        }
    }

    return (
        <ScreenPaper flex1>
            <Typography>
                RELATÓRIOS
            </Typography>
            <NewReport onReport={r => setReport(r)}/>
            <Divider/>
            {report && 
            <Fragment>
                <Stack spacing={1}>
                    <Button onClick={print} startIcon={<Receipt/>}>
                        IMPRIMIR
                    </Button>
                </Stack>
                {summaries.map((l,i) => (
                    <Stack spacing={1} direction='row' key={i} justifyContent="space-evenly">
                        {l.map(([k,v]) => (
                            <InfoItem
                                key={k}
                                label={k}
                                value={v}
                            />
                        ))}
                    </Stack>
                ))}
                <List
                    columns={report?.reportType.columns}
                    rows={report?.values}
                    onChangeFilter={l => setFR(l)}
                    defaultSort={report.reportType.defaultSort}
                    autoHeight
                />
            </Fragment>}
        </ScreenPaper>
    )
}