import { DBRef, DatedProps } from "../../shared/models/model";
import { StoreProps } from "../store/model";
import { UserProps } from "../user/model";

export enum DailySummaryStatus{
    FECHADO,
    ABERTO,
    ATRASO,
    CONCLUIDO,
}

export interface DailySummaryValues{
    money: number;
    credit: number;
    debt: number;
    pix: number;

    cost: number;
    tax: number;
    invoice: number;
    profit: number;

    totalSO: number;
    totalSells: number;
    totalChange: number;
    
    amountSO: number;
    amountSells: number;
    amountStockHistories: number;
    amountApprovedStockHistories: number;
    amountCashRemittances: number;
    amountApprovedCashRemittances: number;
}

export interface DailySummaryProps{
    status: DailySummaryStatus;
    currentDate: EpochTimeStamp;
    store: DBRef<StoreProps, 'name'>;
    openValues: Partial<DailySummaryValues>;
    currentValues: Partial<DailySummaryValues>;
    closedValues?: Partial<DailySummaryValues>;
    mustBeRemoved?: boolean;
    closedIn?: EpochTimeStamp;
    closedBy?: DBRef<UserProps, 'name'>;
    openIn?: EpochTimeStamp;
    openBy?: DBRef<UserProps, 'name'>;
}

export interface DailySummaryHistoryProps extends DailySummaryProps, DatedProps{
}

export enum DailySummaryStatusColor{
    //@ts-ignore
    FECHADO = undefined,
    ABERTO = '#00a1f2',
    ATRASO = '#f01e07',
    CONCLUIDO = '#0fd415',
}