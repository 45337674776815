import { Autocomplete, AutocompleteProps, createFilterOptions, TextField } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { EasyFormSelectValues } from "../easy_form/types";
import { DBRefLabel } from "../../crud/crud_service";
import { DBRef } from "../../models/model";
import AlphabeticalSort from "../../utils/alphabetical_sort";

type AutocompleteRealProps = Omit<AutocompleteProps<any, any, any, any, any>, "renderInput" | "onChange" | "options">;

export interface DBRefEasyFormSelectValues extends EasyFormSelectValues{
    value?: { uid: string, label: any, _ref: any, [other: string]: any };
}

export interface DBRefInputProps extends AutocompleteRealProps{
    label?: string;
    options: () => Promise<DBRefEasyFormSelectValues>;
    name?: string;
    error?: boolean;
    helperText?: string;
    itemLabel?: DBRefLabel<any>;
    onChange?: (event: {target: {value: any, name: any}}) => void;
    add?: (label?: string) => Promise<{label: any, uid: string} | null>;
}

export type DBRefExtendedInputProps = Omit<DBRefInputProps, 'options'>;

const filter = createFilterOptions();

export function DBRefsToSelectValues(dbrefs: DBRef<any, any>[]){
    const values: EasyFormSelectValues = [];
    dbrefs.forEach(dbref => {
        values.push({
            label: dbref.label,
            value: dbref,
        })
    })
    return values;
}

const DBRefInput = forwardRef((props: DBRefInputProps, ref: any) => {
    
    const [loadError, setLoadError] = useState<string>();
    const [value, setValue] = useState(props.value || props.defaultValue || null);
    const [open, setOpen] = useState(false);
    const [optionList, setOptionList] = useState<EasyFormSelectValues>();
    const [loading, setLoading] = useState(false);

    const {
        label, onChange, helperText, error, options, add, ...other
    } = props;

    const inputProps = {
        label, 
        error: error || loadError, 
        helperText: helperText || loadError,
    };
    
    const realValue = props.value !== undefined ? props.value : value;
    
    useEffect(() => {
        if(open && !optionList){
            setLoading(true);
            options()
            .then(options => setOptionList((options||[]).sort((a, b) => AlphabeticalSort(String(a.label), String(b.label)))))
            .catch(err => {
                setLoadError(err.message);
                setOptionList([]);
            })
            .finally(() => setLoading(false));
        }
    }, [open]);

    const callChange = (val: any) => {
        onChange?.({
            target: {
                name: props.name!,
                value: val,
            }
        });
        setValue(val);
    }

    const addItem = (label: string) => {
        add?.(label)
        .then(newOption => {
            if(newOption){
                setOptionList(optionList?.concat({
                    label: newOption.label,
                    value: newOption,
                }));
                callChange(newOption);
            }
        });
    }

    return (
        <Autocomplete
            renderInput={(p:any) => <TextField {...inputProps} {...p} />}
            selectOnFocus
            clearOnBlur
            {...other}
            renderOption={(p, option) => {
                return <li {...p} key={p.id}>{option.label}</li>
            }}
            options={optionList||[]}
            loading={loading}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            ref={ref}
            onChange={(e, newValue) => {
                const val = newValue?.value || null;
                if(!val || val.uid !== "new"){
                    callChange(val);
                }
                else if(val.uid == "new"){
                    addItem(val.label);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if(params.inputValue.length > 0 && props.add && !loading){
                    filtered.push({
                        value: { label: params.inputValue, uid: 'new' },
                        label: `Adicionar "${params.inputValue}"`,
                    });
                }
                return filtered;
            }}      
            value={realValue}
            isOptionEqualToValue={(option, value) => option.value.uid == value?.uid}
        />
    )
});

export default DBRefInput;