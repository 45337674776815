import { Business, Home, Logout, Sell, Store } from "@mui/icons-material";
import useAuth from "../use_auth";
import { FunctionComponent } from "react";
import * as MainModules from "../../../modules/main_modules";

export interface MenuItem extends RouteConfig {
    buttonProps: {
        href?: string;
        onClick?: () => void;
    }
}

interface useMenuComponentsOptions{
    withLogout?: boolean;
    withHome?: boolean;
}

export default function useMenuComponents(options?: useMenuComponentsOptions){
    const auth = useAuth();
    let list: MenuItem[] = [];
    const _Modules = MainModules as Record<string, {routeConfig: RouteConfig}>;
    const screens = auth.user?.screens;
    if(screens){
        list = screens.map((key: any) => _Modules[key]?.routeConfig).filter(item => item && (!item.active || item.active())) as any;
        if(options?.withHome) list.unshift(MenuList.home);
        if(options?.withLogout) list.push(MenuList.logout);
        list.forEach(item => item.buttonProps = {href: '/' + item.key, ...item.buttonProps});
    }
    return list;
}

const MenuList: { [key: string]: MenuItem } = {
    home: {
        label: "Início",
        Icon: Home,
        key: 'home',
        buttonProps: {
            href: '/'
        }
    },
    logout: {
        label: "Sair",
        key: 'logout',
        Icon: Logout,
        buttonProps: {
            href: '/logout'
        }
    }
}