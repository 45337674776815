import { PlayCircleFilled } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const goFieldColumn = {field: 'go', headerName: '', minWidth: 50, width: 50, renderCell: GoField, type: 'actions'};

export default function GoField(props: {id?: any, prefix?: string, target?: string}){
    return (
        <IconButton href={`${props.prefix ? props.prefix + '/' : ''}edit/${props.id}`} disabled={!props.id} color="primary" target={props.target}>
            <PlayCircleFilled/>
        </IconButton>
    )
}