import DateToString from "../../../shared/utils/date_to_string";
import MinPerm from "../../../shared/utils/min_perm";
import parseBRL from "../../../shared/utils/parse_brl";
import { hashDate } from "../../billing2/controller";
import { DailySummaryHistoryService, DailySummaryService } from "../../billing2/services";
import StoreDBRef from "../../store/dbref";
import { Report } from "../model";

export interface ProfitReportValue{
    id: string;
    store: string;
    invoice: number;
    cost: number;
    profit: number;
}

const ProfitReport: Report = {
    name: 'Lucro Real',
    checkPerm: async u => MinPerm(u.perms.report, 'w'),
    options: {
        startDate: {
            field: 'startDate',
            type: 'date',
            required: true,
            label: 'Data Início',
        },
        endDate: {
            field: 'endDate',
            type: 'date',
            required: true,
            label: 'Data Fim',
        },
        store: {
            field: 'store',
            type: 'dbref',
            component: StoreDBRef,
            label: 'Loja',
        },
        'values.rent': {
            field: 'values.rent',
            type: 'number',
            label: 'Aluguel',
            required: true,
        },
        'values.empPayments': {
            field: 'values.empPayments',
            type: 'number',
            label: 'Pagamentos',
            required: true,
        },
        'values.internet': {
            field: 'values.internet',
            type: 'number',
            label: 'Internet',
            required: true,
        },
        'values.maintance': {
            field: 'values.maintance',
            type: 'number',
            label: 'Manutenção',
            required: true,
        },
        'values.uniforms': {
            field: 'values.uniforms',
            type: 'number',
            label: 'Uniformes',
            required: true,
        },
        'values.other': {
            field: 'values.other',
            type: 'number',
            label: 'Outros',
            required: true,
        },
    },
    validateOptions: async o => {
        if(o.startDate > o.endDate) throw new Error('A data de início não pode ser maior que a data fim.');
        o.startDate.setHours(0,0,0);
        o.endDate.setHours(23,59,0);
        return o;
    },
    columns: [
        {field: 'store', headerName: 'Loja'},
        {field: 'invoice', headerName: 'Faturamento', type: 'money'},
        {field: 'cost', headerName: 'Custo', type: 'money'},
        {field: 'profit', headerName: 'Lucro', type: 'money'},
    ],
    defaultSort: [{field: 'profit', sort: 'desc'}],
    process: async o => {
        const hashedStart = hashDate(o.startDate), hashedEnd = hashDate(o.endDate);
        const list: ProfitReportValue[] = [];
        const dsh = Object.values(await new DailySummaryHistoryService().list());
        if(hashedEnd === hashDate(new Date())){
            const ds = await new DailySummaryService().list();
            for(let d of Object.values(ds)){
                const index = dsh.findIndex(i => i.data.currentDate === d.data.currentDate);
                if(index >= 0){
                    dsh[index] = d as any;
                }
                else{
                    dsh.push(d as any);
                }
            }
        }
        for(let d of dsh){
            if(o.store && o.store.uid !== d.data.store.uid) continue;
            const dHash = hashDate(d.data.currentDate);
            if(dHash < hashedStart || dHash > hashedEnd) continue;
            const values = d.data.closedValues || d.data.currentValues || {};
            let item = list.find(i => i.id === d.data.store.uid);
            if(!item){
                item = {
                    id: d.data.store.uid,
                    store: d.data.store.label,
                    cost: 0,
                    invoice: 0,
                    profit: 0,
                }
                list.push(item);
            }
            item.cost += values.cost || 0;
            item.invoice += values.invoice || 0;
            item.profit += values.profit || 0;
        }
        return list;
    },
    summaries: async (options: any, values: ProfitReportValue[]) => {
        let cost = 0, invoice = 0, profit = 0, otherCosts = 0;
        values.forEach(p => {
            cost += p.cost;
            invoice += p.invoice;
            profit += p.profit;
        })
        Object.values(options.values||{}).forEach((v:any) => otherCosts += v||0);
        return [
            [['Início', DateToString(options.startDate, 'date')], ['Fim', DateToString(options.endDate, 'date')]],
            [['Faturamento', parseBRL(invoice)], ['Custo', parseBRL(cost)], ['Lucro', parseBRL(profit)]],
            [['Total Despesas', parseBRL(otherCosts)], ['Custo Real', parseBRL(otherCosts + cost)], ['Lucro Real', parseBRL(profit - otherCosts)]]
        ]
    },
    print: async (options: any, values: ProfitReportValue[]) => {
        return {
            name: 'Lucro Real',
            options: {},
            summaries: await ProfitReport.summaries(options, values),
            columns: [
                ...ProfitReport.columns as any,
            ],
            values,
        }
    }
}

export default ProfitReport;