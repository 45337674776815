import { Button, Stack } from "@mui/material";
import { CRUDItem } from "../../../shared/crud/crud_service";
import { InstallmentFields, InstallmentProps } from "../model";
import { ClientProps } from "../../client/model";
import { useMemo, useState } from "react";
import parseBRL from "../../../shared/utils/parse_brl";
import DateToString from "../../../shared/utils/date_to_string";
import InfoItem from "../../../shared/components/info_item";
import deepGet from "../../../shared/utils/deep_get";
import NumberInput from "../../../shared/components/number_input";
import { ModelFieldsFilter } from "../../../shared/utils/model_fields_gen";
import { EasyForm, EasyFormAction, EasyFormInput } from "../../../shared/components/easy_form";
import InstallmentService from "../service";
import dialogHelper from "../../../shared/components/easy_dialog";

export interface RenegotiationProps{
    client: CRUDItem<ClientProps>;
    installment: CRUDItem<InstallmentProps>;
    newValue: number;
    onCancel: () => void;
    onSuccess: (newInstallment: CRUDItem<InstallmentProps>) => void;
}
const dateFormatter = (v: any) => DateToString(v, 'date') || '-';
export default function Renegotiation(props: RenegotiationProps){
    const client = props.client.data;
    const installment = props.installment.data;
    const {newValue} = props;
    const data = {client, installment, newValue};
    const newTotal = useMemo(() => newValue + (installment.totalDebt || 0), []);
    
    const readOnlyInfo = useMemo(() => ([
        {label: 'Cliente', field: 'client.name'},
        {label: 'Devido atual', field: 'installment.totalDebt', formatter: parseBRL},
        {label: 'Pago atual', field: 'installment.totalPaid', formatter: parseBRL},
        {label: 'Negociado em', field: 'installment.negotiatedAt', formatter: dateFormatter},
        {label: 'Iniciado em', field: 'installment.startAt', formatter: dateFormatter},
        {label: 'Intervalo atual', field: 'installment.payInterval', formatter: (v: any) => v === undefined ? '-' : v},
        {label: 'Valor a acrescentar', field: 'newValue', formatter: parseBRL},
    ].map(r => (
        {
            ...r,
            value: r.formatter ? r.formatter(deepGet(r.field, data)) : deepGet(r.field, data) 
        }
    ))
    ), []);
    
    const fields = useMemo(() => ModelFieldsFilter(InstallmentFields(), ['valParcel', 'payInterval', 'startAt']), []);

    const submit = async (data: Partial<InstallmentProps>) => {
        try{
            const newInstallment: CRUDItem<InstallmentProps> = {
                uid: props.installment.uid, 
                data: {
                    ...installment,
                    ...data,
                    totalDebt: newTotal,
                    negotiatedAt: Date.now(),
                }
            };
            props.onSuccess(newInstallment);
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    }

    return (
        <Stack spacing={1}>
            {readOnlyInfo.map((r, i) => (
                <InfoItem
                    key={i}
                    label={r.label}
                    value={r.value}
                    align="flex-start"
                />
            ))}
            <NumberInput
                readOnly
                variant="filled"
                label="Novo Total"
                value={newTotal}
            />
            <EasyForm fields={fields} onSubmit={submit}>
                <Stack spacing={1}>
                    {Object.values(fields).map(f => (
                        <EasyFormInput field={f.field} required/>            
                    ))}
                    <Stack direction="row" spacing={1} justifyContent="right">
                        <Button variant="text" color="inherit" onClick={props.onCancel}>
                            CANCELAR
                        </Button>
                        <EasyFormAction>
                            <Button color="success">
                                OK
                            </Button>
                        </EasyFormAction>
                    </Stack>
                </Stack>
            </EasyForm>
        </Stack>
    )
}