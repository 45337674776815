import { CameraAlt } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import ScreenPaper from "../../../shared/components/screen_paper";
import controller from "../select_items/controller";
import SellBottomNavigation, { SellBottomButtonPrevirous, SellBottomButtonNext } from "../sell_bottom_navigation";
import List from "../../../shared/components/list";
import usePromisedState from "../../../shared/hooks/use_promised_state";
import ClientService from "../../client/service";
import { useNavigate } from "react-router-dom";
import dialogHelper from "../../../shared/components/easy_dialog";
import InstallmentService from "../../installment/service";
import MiniClientInfo from "./mini_client_info";
import useNextAction from "../use_next_action";
import { useSellWizardContext } from "../sell_wizard_context";

export default function StrictSelectClient(props: {name: string}){
    const navigate = useNavigate();
    const [clients] = usePromisedState({
        initialState: () => new ClientService().list().then(list => Object.values(list))
    })
    const ctx = useSellWizardContext();
    const goNext = useNextAction({name: props.name});

    const select = async ({id}: {id: any}) => {
        try{
            dialogHelper.showLoading();
            const client = clients.value!.find(({uid}) => id === uid)!;
            const installment = await new InstallmentService().read(client.uid!);
            client.data._installment = installment.data;

            const callback = () => {
                ctx.sell.client = {
                    uid: client.uid!,
                    label: client.data.name,
                    _ref: client,
                }
                ctx.sell.clientName = client.data.name;
                ctx.sell.clientPhone = client.data.phone;
                dialogHelper.closeDialog();
                goNext();
            }

            dialogHelper.showDialog({
                title: 'Cliente',
                content: <MiniClientInfo client={client} installment={installment}/>,
                buttons: [
                    dialogHelper.okButton({content: 'CANCELAR', variant: 'text', color: 'inherit'}),
                    dialogHelper.okButton({color: 'success', callback, content: 'CONTINUAR' }),
                ]
            })
        }
        catch(err: any){
            dialogHelper.showError(err);
        }
    }

    return (
        <Stack flex={1}>
            <ScreenPaper flex1>
                <Typography align='center'>
                    SELECIONE O CLIENTE
                </Typography>
                <List
                    columns={[
                        {field: 'data.name', headerName: 'Nome' },
                        {field: 'data.identifier', headerName: 'CPF/CNPJ'},
                        {field: 'data.crtAt', headerName: 'Criado em', type: 'dateTime'},
                        {field: 'data.updAt', headerName: 'Atualizado em', type: 'dateTime'},
                    ]}
                    onRowClick={select}
                    getRowId={(i) => i.uid}
                    loading={!clients.resolved}
                    rows={clients.value || []}
                    defaultSort={[{ field: 'name', sort: 'asc' }]}
                    addProps={{onClick: () => navigate('/client/create')}}
                />
            </ScreenPaper>
        </Stack>
    )
}