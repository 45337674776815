import dialogHelper from "../../../shared/components/easy_dialog";
import { store as rootStore } from "../../../shared/stores/root_store/store";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import parseBRL from "../../../shared/utils/parse_brl";
import { AddressProps, makeAddressString } from "../../address/model";
import GeneralConfigsService from "../../general_configs/service";
import { PhoneSOProps } from "../../phone_so/model";
import StoreService from "../../store/service";

export default async function SoCheckinPrint(sellUid: string, phoneSo: PhoneSOProps, options?: any){
    try{
        dialogHelper.showLoading();
        const project = rootStore.getState().project;
        const store = await new StoreService().getDescription(phoneSo.store.uid);
        const configs = await new GeneralConfigsService().getCached();
        DocumentTemplatePrinter('so_checkin.html', {
            UID: sellUid,
            logoURL: project.project?.ui.logo_url!,
            storeDescription: store.data.description,
            storeAddress: makeAddressString(store.data.address||{} as AddressProps),
            storeSocial: store.data.social!,
            storePhone: store.data.phone!,
            clientName: phoneSo.clientName,
            crtAt: DateToString(phoneSo.crtAt, 'date'),
            clientId: phoneSo.clientId,
            clientPhone: phoneSo.clientPhone!,
            deviceBrand: phoneSo.deviceBrand,
            deviceModel: phoneSo.deviceModel,
            imei: phoneSo.imei,
            sellerObs: phoneSo.sellerObs!,
            seller: phoneSo.seller.label,
            value: parseBRL(phoneSo.suggestedValue || 0),
            serviceObs: phoneSo.serviceObs!,
            CHECKLIST: Object.entries(phoneSo.checkList)
            .map(([key, checked]) => !checked ? '' : (
                `<div><input type="checkbox" ${checked ? 'checked' : ''}><label>${key}</label></div>`
            ))
            .join(''),
            terms: configs.data?.texts?.terms?.so_checkin || '',
        });
        dialogHelper.closeDialog();
    }
    catch(err: any){
        dialogHelper.showError(err);
    }
}