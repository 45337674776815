import { Typography } from "@mui/material";
import version from "../../../configs/version.json";

export interface VersionComponentProps{
    align?: "center" | "right" | "left" | "inherit" | "justify";
}

export default function VersionComponent(props: VersionComponentProps){
    return (
        <Typography align={props.align || 'center'} variant="caption">
            Versão {version.n}
        </Typography>
    )
}