import styled from "@emotion/styled";
import { AddPhotoAlternate, Collections, Delete, PhotoCamera } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, ImageList, ImageListItem, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Fragment } from "react";
import FileService from "../../services/file_service";
import dialogHelper from "../easy_dialog";

export interface GalleryPhoto{
    url: string;
    isNew?: boolean;
    file?: File;
    loading?: boolean;
    timestamp: number;
}

export interface GalleryProps{
    imageList: GalleryPhoto[];
    readOnly?: boolean;
    onNewPhotos?: (photos: GalleryPhoto[]) => void;
    onDeletePhoto?: (photo: GalleryPhoto, index: number) => void;
}

const PhotoContainer = styled.div(() => ({
    backgroundColor: 'rgba(255,255,255,0.1)',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    height: '100%',
}))

const DeleteIconContainer = styled.div(() => ({
    position: 'absolute',
    top: '5px',
    right: '5px',
}))

export default function Gallery(props: GalleryProps){

    const addPhotos = () => {
        const fileService = new FileService();
        let timestamp = Date.now();
        fileService.captureFiles('photo')
        .then(files => Promise.all(files.map(file => fileService.zipImage(file))))
        .then(files => props.onNewPhotos?.(files.map(file => ({
            file: file as File, 
            isNew: true, 
            url: URL.createObjectURL(file),
            timestamp,
        }))))
        .catch(dialogHelper.showError);
    }

    const theme = useTheme();
    
    const lg = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Stack>
            {!props.readOnly &&
            <Button onClick={addPhotos} startIcon={<AddPhotoAlternate/>}>
                ADICIONAR FOTOS
            </Button>}
            <ImageList cols={lg ? 6 : 2} rowHeight={170}>
                {props.imageList.map((item, i) => (
                    <ImageListItem key={item.url+i}>
                        {!props.readOnly && 
                        <DeleteIconContainer>
                            <IconButton color="error" size="small" onClick={() => props.onDeletePhoto?.(item, i)}>
                                <Delete/>
                            </IconButton>
                        </DeleteIconContainer>}
                        {item.loading ?
                        <PhotoContainer>
                            <CircularProgress/>
                        </PhotoContainer> :
                        <img
                            src={item.url}
                            alt={'img'+i}
                            loading='lazy'
                            style={{height: 170}}
                            onClick={() => window.open(item.url)}
                        />}
                    </ImageListItem>
                ))}
            </ImageList>
        </Stack>
    )
}