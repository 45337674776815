import { EasyFormFieldList } from "../../components/easy_form/types";
import deepGet from "../deep_get";

export interface ModelFieldsGenOptions{
    defaults?: any;
    prefix?: string;
    optional?: boolean;
}

export default function ModelFieldsGen(fields: Partial<EasyFormFieldList>, options?: ModelFieldsGenOptions){
    const defaults = options?.defaults || {};
    const entryList = Object.entries(fields);
    const newFields: EasyFormFieldList = {};

    for(let [key, item] of entryList){
        if(item === undefined) continue;
        if(options?.prefix) key = `${options?.prefix}.${key}`;
        item.defaultValue = deepGet(key, defaults);
        newFields[key] = item;
    }

    return newFields;
}

export function ModelFieldsFilter(fields: EasyFormFieldList, items: string[]){
    const newFields: EasyFormFieldList = {};
    for(let item of items){
        if(item in fields){
            newFields[item] = fields[item];
        }
    }

    return newFields;
}