import { forwardRef, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import ProductService from "./service";

const ProductDBRef = forwardRef(function _ProductDBRef(props: Omit<DBRefInputProps, 'options'>, ref){
    const [service] = useState(() => new ProductService());

    return (
        <DBRefInput
            {...props}
            options={() => service.DBRefList(props.itemLabel || 'name')}
            ref={ref}
        />
    )
});

export default ProductDBRef;