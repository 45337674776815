import { Close, Delete, Security } from "@mui/icons-material";
import { AppBar, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Toolbar, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo } from "react";
import dialogHelper from "../../shared/components/easy_dialog";
import { EasyForm, EasyFormAction, EasyFormInput, EasyFormWriteOnly } from "../../shared/components/easy_form";
import useCRUDEdit, { UseCRUDEditComponentProps } from "../../shared/crud/use_crud_edit";
import { StockFields, StockProps } from "./model";
import StockService from "./service";
import Stack from '@mui/material/Stack';
import useAuth from "../../shared/hooks/use_auth";
import MultiStockTransfer, { MultiStockTransferResult } from "./multi_stock_transfer";
import WarrantyStockService from "../warranty_stock/service";
import ShowHistoryList from "../stock_history/show_history_list";

export default function StockMicroEdit(props: UseCRUDEditComponentProps<StockProps> & {updateList: () => Promise<void>}){
    const create = useCRUDEdit({
        props: props,
        fieldsGen: StockFields,
        CRUDService: StockService,
        goBackOnSave: true,
        beforeSave: (old, newobj) => newobj._originalAmount = old._originalAmount,
    });
    const auth = useAuth();
    const warrantyService = useMemo(() => new WarrantyStockService(), []);

    const exists = Boolean(create.uid);

    const sendWarranty = () => {
        const confirm = (r: MultiStockTransferResult) => {
            dialogHelper.showLoading();
            warrantyService.warrantyFromStock(r)
            .then((l) => {
                ShowHistoryList({historyList: l});
                props.updateList();
                create.gotoIndex();
            })
            .catch(dialogHelper.showError)
        }
        const withId = [{...create.data, id: create.uid}];
        dialogHelper.showDialog({
            content: (
                <MultiStockTransfer
                    onCancel={dialogHelper.closeDialog}
                    stocks={withId as any}
                    onConfirm={confirm}
                    withDescription
                />
            )
        })
    }

    if(create.initializing){
        return (
            <Dialog open>
                <DialogContent>
                    <CircularProgress color="primary"/>
                </DialogContent>
            </Dialog>
        )
    }
    return (
        <Dialog open onClose={create.gotoIndex} fullScreen>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={create.gotoIndex}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Estoque
                    </Typography>
                </Toolbar>
            </AppBar>
            <EasyForm {...create.easyFormProps}>
                <DialogContent>
                    <Stack spacing={1}>
                        <EasyFormInput field="store" readOnly={exists} onChange={(e, f) => {
                            const zoneRef = e?.target.value?._ref?.data?.zone || null;
                            f?.formConfig.setValue('zone', zoneRef);
                        }}/>
                        <EasyFormInput field="product" readOnly={exists}/>
                        <EasyFormInput field="amount"/>
                        {exists && (
                            <Fragment>
                                <EasyFormInput field="lastOpReason" required defaultValue={null}/>
                                <EasyFormInput field="lastOpObs" defaultValue={""}/>
                            </Fragment>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {auth.user?.perms.stock === 's' && exists &&
                    <Button color="error" onClick={create.deleteAction} fullWidth>
                        REMOVER
                    </Button>}
                    {auth.user?.perms.warranty_stock && exists &&
                    <Button color="warning" onClick={sendWarranty} fullWidth startIcon={<Security/>}>
                        GERAR GARANTIA
                    </Button>}
                    <EasyFormWriteOnly>
                        <EasyFormAction>
                            <Button fullWidth color="success" type="submit">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                    </EasyFormWriteOnly>
                </DialogActions>
            </EasyForm>
        </Dialog>
    )
}