import { Container } from '@mui/material';
import styled from "@emotion/styled";

const Centered = styled(Container)({
    flex: 1,
    flexDirection: "column",
    
    justifyContent: "center",
    display: "flex",
});
export default Centered;