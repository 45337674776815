import dialogHelper from "../../../shared/components/easy_dialog";
import { DBRef } from "../../../shared/models/model";
import { store } from "../../../shared/stores/root_store/store";
import ColumnToString from "../../../shared/utils/column_to_string";
import DateToString from "../../../shared/utils/date_to_string";
import DocumentTemplatePrinter from "../../../shared/utils/document_template_printer";
import { AddressProps, makeAddressString } from "../../address/model";
import { ReportPrint } from "../../report/model";
import StockService from "../../stock/service";
import { StoreProps } from "../../store/model";
import StoreService from "../../store/service";

export default function DefaultReportPrint(reportPrint: ReportPrint){
    const project = store.getState().project;
    DocumentTemplatePrinter('default_report.html', {
        logoURL: project.project?.ui.logo_url!,
        date: DateToString(new Date(), 'datetime'),
        reportName: reportPrint.name,
        summaries_items: reportPrint.summaries.map(l => (
            `<tr>${l.map(([lbl, vl, props]) => `<td ${props}>${lbl}: ${vl}</td>`).join('')}</tr>`
        )).join(''),
        columns: `
        <tr>
            ${reportPrint.columns.map(c => `<th>${c.headerName}</th>`).join('')}
        </tr>`,
        values: reportPrint.values.map(v => (
            `<tr>${reportPrint.columns.map(c => `<td>${ColumnToString(v, c)}</td>`).join('')}</tr>`
        )).join(''),
    });
}