import { forwardRef, useState } from "react";
import DBRefInput, { DBRefInputProps } from "../../shared/components/dbref_input";
import SupplierService from "./service";


const SupplierDBRef = forwardRef(function _SupplierDBRef(props: Omit<DBRefInputProps, 'options'>, ref){
    const [service] = useState(() => new SupplierService());

    return (
        <DBRefInput
            {...props}
            options={() => service.DBRefList(props.itemLabel || 'name')}
            ref={ref}
        />
    )
});

export default SupplierDBRef;