import { EasyForm, EasyFormInput, EasyFormAction, EasyFormWriteOnly } from "../../shared/components/easy_form";
import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import useCRUDEdit from "../../shared/crud/use_crud_edit";
import { Delete } from "@mui/icons-material";
import ScreenLoading from "../../shared/components/screen_loading";
import ScreenPaper from "../../shared/components/screen_paper";
import { StoreFields, StoreProps } from "./model";
import StoreService from "./service";
import AddressFragment from "../address/address_fragment";
import ControlInfo from "../../shared/components/control_info";
import dialogHelper from "../../shared/components/easy_dialog";
import Checkbox from "../../shared/components/checkbox";
import MinPerm from "../../shared/utils/min_perm";
import useAuth from "../../shared/hooks/use_auth";

export default function StoreEdit(props: any){
    const create = useCRUDEdit<StoreProps, StoreService>({
        props,
        fieldsGen: StoreFields,
        CRUDService: StoreService,
        beforeSave: async (_,d) => {
            if(d.mainStore){
                const exists = await create.crudService.hasMainStore(create.uid!);
                if(exists && exists.uid !== create.uid){
                    throw new Error('Só pode haver uma loja principal por zona!');
                }
            }
        }
    });
    const auth = useAuth();

    const deleteConfirmed = (opts: any) => {
        dialogHelper.showLoading();
        return create.crudService?.delete(create.uid!, opts)
        .then(() => {
            create.gotoIndex();
            dialogHelper.showDialog({title: 'Sucesso!', content: 'Item removido com sucesso.', buttons: dialogHelper.okButton()})
        })
        .catch(dialogHelper.showError);
    }

    const deleteAction = () => {
        const opts = {deleteStock: false};
        dialogHelper.showDialog({
            title: "CONFIRMAR EXCLUSÃO",
            content: (
                <Stack>
                    <Typography>Tem certeza de que deseja excluir esse item?</Typography>
                    {MinPerm(auth.user?.perms.mass_mod, 'w') &&
                    <Checkbox label="Também deletar todo o estoque da loja" onChange={(e, v) => opts.deleteStock = v}/>}
                </Stack>
            ),
            locked: true,
            buttons: [
                dialogHelper.okButton({content: 'NÃO', buttonProps: {color: 'inherit', variant: 'text'}}),
                dialogHelper.okButton({content: 'SIM', callback: () => deleteConfirmed(opts), buttonProps: {color: 'error'}}),
            ]
        });
    }

    if(create.initializing){
        return <ScreenLoading/>
    }

    return (
        <ScreenPaper>
            <EasyForm {...create.easyFormProps}>
                <Stack spacing={1}>
                    <Typography>
                        {!create.uid && 'CRIAR'} LOJA
                    </Typography>
                    {create.uid ? <ControlInfo {...create}/> : undefined}
                    <EasyFormInput field="name"/>
                    <EasyFormInput field="description"/>
                    <EasyFormInput field="phone"/>
                    <EasyFormInput field="social"/>
                    <EasyFormInput field="zone"/>
                    {create.uid && <EasyFormInput field="mainStore"/>}
                    <Divider><Chip label="ENDEREÇO"/></Divider>
                    <AddressFragment defaultReadMode={Boolean(create.uid)}/>
                    <EasyFormWriteOnly>
                        <Divider><Chip label="AÇÕES"/></Divider>
                        <EasyFormAction>
                            <Button fullWidth color="success">
                                SALVAR
                            </Button>
                        </EasyFormAction>
                        {create.uid ? 
                        <Button startIcon={<Delete/>} color="error" onClick={deleteAction}>
                            DELETAR
                        </Button>
                        : undefined}
                    </EasyFormWriteOnly>
                </Stack>
            </EasyForm>
        </ScreenPaper>
    )
}