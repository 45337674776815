import { useEffect, useMemo, useState } from "react";
import { useSummariesController } from "./controller";
import usePromisedState from "../../shared/hooks/use_promised_state";
import CashRemittanceList from "../cash_remittance/list";
import CashRemittanceService from "../cash_remittance/service";
import { Stack } from "@mui/material";
import Checkbox from "../../shared/components/checkbox";

export default function SummariesCashRemittanceList(){
    const controller = useSummariesController();
    
    const service = useMemo(() => new CashRemittanceService(), []);
    const getSells = () => service.list().then(s => Object.values(s));
    const [list] = usePromisedState({initialState: getSells})
    const [filtred, setFiltred] = useState<typeof list['value']>([]);
    const [onlyBlood, setOnlyBlood] = useState(false);

    useEffect(() => {
        if(list.resolved){
            let _filt = list.value?.filter(i => controller.filterItem({date: i.data.crtAt, storeUid: i.data.store.uid}));
            if(onlyBlood) _filt = _filt?.filter(i => i.data.manual);
            setFiltred(_filt);
        }
    }, [...controller.listsDeps(list.resolved), onlyBlood]);
    return (
        <Stack>
            <Checkbox
                label="Somente Movimentações"
                onChange={() => setOnlyBlood(!onlyBlood)}
                value={onlyBlood}
            />
            <CashRemittanceList
                rows={filtred!}
                readOnlyCheck={(!controller.currentDS.canDoActions || !controller.currentDS.isSingle)}
                onApprove={(sh) => controller.updateDSByUid(sh.data.store.uid)}
                loading={!list.resolved}
            />
        </Stack>
    )
}