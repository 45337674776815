import { StandardTextFieldProps  } from "@mui/material";
import { useContext, ComponentType, useEffect, useMemo } from "react";
import { Controller, RegisterOptions } from "react-hook-form";
import { FixedTextFieldProps } from "../fixed_text_field";
import EasyFormContext, { EasyFormContextVal } from "./easy_form_context";
import EasyFormInputTypes from "./easy_form_input_types";

interface ChangeEvent{
    target: {
        value: any;
        name: string;
    }
}

export interface EasyFormInputProps extends Omit<FixedTextFieldProps, 'onChange'> {
    field: string;
    onChange?: (e?: ChangeEvent, form?: EasyFormContextVal) => void;
    [otherProps: string]: any;
}

export interface EasyFormInputConfig{
    component: ComponentType<any>;
    componentProps?: StandardTextFieldProps & {onChange: (e: ChangeEvent, context?: EasyFormContextVal) => void, onBlur: (e: Event, context?: EasyFormContextVal) => void} & any;
    registerProps?: RegisterOptions<any>;
}

export default function EasyFormInput(props: EasyFormInputProps){
    const context = useContext(EasyFormContext);
    const { field, ...otherProps } = props;

    const [fieldConfig, fieldType, Component, propsRules] = useMemo(() => {
        const _fieldConfig = context.fields[field];
    
        if(!_fieldConfig){
            
            return [];
        }
    
        const _fieldType = EasyFormInputTypes[_fieldConfig.type];
    
        const _Component = _fieldConfig.component || _fieldType.component;
    
        const _propsRules: any = {};
        if(props.required) _propsRules.required = true;
        return [
            _fieldConfig, _fieldType, _Component, _propsRules
        ]
    }, []);
    
    useEffect(() => {
        if(props.defaultValue !== undefined){
            context.formConfig.setValue(field, props.defaultValue);
        }
    }, []);

    if(!fieldConfig|| !fieldType|| !Component || !propsRules) return null;
    else return (
        <Controller
            name={field}
            control={context.formConfig.control}
            rules={{ 
                ...fieldConfig,
                ...fieldType.registerProps,
                ...propsRules,
            }}
            render={(register) => {
                return (
                    <Component 
                        mask={fieldConfig.mask}
                        label={fieldConfig.label}
                        options={fieldConfig.options}
                        disabled={context.easyFormProps.disabled}
                        readOnly={context.easyFormProps.readOnly}
                        error={Boolean(register.fieldState.error)}
                        helperText={register.fieldState.error?.message}
                        {...fieldConfig.defaultProps}
                        {...fieldType.componentProps}
                        {...register.field}
                        {...otherProps}
                        onBlur={(e: any) => {
                            register.field.onBlur();
                            fieldConfig.onBlur?.(e, context);
                            fieldType.componentProps?.onBlur?.(e, context);
                            //@ts-ignore
                            props.onBlur?.(e, context);
                        }}
                        onChange={(e: ChangeEvent) => {
                            register.field.onChange(e);
                            fieldConfig.onChange?.(e, context);
                            fieldType.componentProps?.onChange?.(e, context);
                            props.onChange?.(e, context);
                        }}
                    />
                )
            }}
        />
    )
}
