import { DatedProps } from "../../shared/models/model";
import ModelFieldsGen, { ModelFieldsGenOptions } from "../../shared/utils/model_fields_gen";
import Round from "../../shared/utils/round";
import { Photo, PhotoList } from "../file/model";


export interface ProductProps extends DatedProps{
    name: string;
    description: string;
    modePercent?: boolean;
    cost_value: number;
    min_sell_value?: number;
    sell_value: number;
    amount?: number;
    photos?: PhotoList;
}

export interface PricesProps{
    cost_value: number;
    min_sell_value: number;
    sell_value: number;
}

export function getAbsolutePrices(product: ProductProps): PricesProps{
    let { cost_value, sell_value, modePercent, min_sell_value } = product;

    if(modePercent){
        sell_value = Round(cost_value * ((sell_value + 100) / 100));
        min_sell_value = min_sell_value ? Round(cost_value * ((min_sell_value + 100) / 100)) : cost_value;
    }
    else{
        if(!min_sell_value) min_sell_value = cost_value;
    }

    return {
        cost_value, sell_value, min_sell_value
    }
}

export const ProductFields = (options?: ModelFieldsGenOptions) => {
    return ModelFieldsGen({
        name: {
            field: "name",
            type: "text",
            label: "Nome",
            required: true,
        },
        description: {
            field: "description",
            type: "text",
            label: 'Descrição'
        },
        cost_value: {
            field: "cost_value",
            type: "number",
            label: "Custo Real Un. R$",
            required: true
        },
        modePercent: {
            field: "modePercent",
            type: 'checkbox',
            label: 'Usar Percentuais',
        },
        min_sell_value: {
            field: "min_sell_value",
            type: "number",
            label: "Mínimo Venda Un. R$",
        },
        sell_value: {
            field: "sell_value",
            type: "number",
            label: "Sugerido Venda Un. R$",
            required: true,
        },
        amount: {
            field: "amount",
            type: "int",
            label: "Quantidade"
        }
    }, options)
}